import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MomentLoader } from '../../../../../../Common/Loader/MomentLoader'
import CampaignTypeEnum from '../../../../../../../enums/CampaignTypeEnum'
import CreativeFormatEnum from '../../../../../../../enums/CreativeFormatEnum'
import CreativeAssetPropertyEnum from '../../../../../../../enums/CreativeAssetPropertyEnum'
import {
  addCmpAssetLibraryImageSelectedItems,
  getCmpAssetLibraryImageEditorData,
  setCmpAssetLibraryImageSelectedItems,
} from '../../../../../../../modules/cmp/mCmpAssetLibraryImage'
import { cancelRequest } from '../../../../../../../utils/cancellation/cancellation'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { CMP_ASSET_LIBRARY_CANCEL_KEY_EDITOR } from '../../cmpAssetLibraryUtils'
import CmpAssetLibraryImageEditorDataFilter from './CmpAssetLibraryImageEditorDataFilter'
import CmpAssetLibraryImageEditorItemView from './CmpAssetLibraryImageEditorItemView'
import CmpEditorOpenButton from '../../../CmpEditor/CmpEditorOpenButton'
import { showErrorMessage } from '../../../../../../../utils/alertUtils'
import { take } from 'lodash'
import CmpAssetPropertyEnum from '../../../../../../../enums/CmpAssetPropertyEnum'
import Pagination from '../../../../../../Common/Pagenation/Pagenation'

const selector = ({
  cmpAssetLibrary: {
    image: {
      editor: { searchOptions, editorData },
      selectedItems,
      viewState: { isFetchingEditor },
    },
  },
}) => {
  return {
    searchOptions,
    editorData,
    selectedItems,
    isFetchingEditor,
  }
}

const CmpAssetLibraryImageEditorData = ({
  adAccountId,
  campaignType,
  creativeFormat,
  creativeAssetPropertyType,
  cropAspects,
  assetConstraint,
  multiSelect,
  handleCmpImageEditorOpenForModify,
  validateImageSize,
  editorRatioValueFilter = ratioValue => true,
  //
  renderGuideView,
  cmpAssetPropertyType,
  specificRatio,
}) => {
  const dispatch = useDispatch()

  const { editorData, selectedItems, isFetchingEditor } = useSelector(
    selector,
    shallowEqual
  )

  const {
    query,
    content: items,
    number: pageIndex,
    size: pageSize,
    totalElements,
  } = editorData || {}

  const fetchEditorData = React.useCallback(
    ({ page = 0, size = 50 } = {}) => {
      dispatch(
        getCmpAssetLibraryImageEditorData({
          adAccountId,
          campaignType,
          creativeFormat,
          creativeAssetPropertyType,
          specificRatio,
          page,
          size,
        })
      )
    },
    [
      adAccountId,
      campaignType,
      creativeAssetPropertyType,
      specificRatio,
      creativeFormat,
      dispatch,
    ]
  )

  React.useEffect(() => {
    // empty 일 떄만 로드하도록 한다(탭 변경 시 유지되어야 한다).
    if (items.isEmpty()) {
      fetchEditorData()
    }

    return () => {
      cancelRequest(CMP_ASSET_LIBRARY_CANCEL_KEY_EDITOR)
    }
  }, [adAccountId, creativeFormat, dispatch, fetchEditorData, items])

  const handlePageIndexChange = currentIndex => {
    if (currentIndex !== pageIndex) {
      fetchEditorData({ page: currentIndex, size: pageSize })
    }
  }

  const handlePageSizeChange = nextPageSize => {
    if (pageSize !== nextPageSize) {
      fetchEditorData({ page: 0, size: nextPageSize })
    }
  }

  const isBizBoardBanner =
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT

  return (
    <div className="wrap_material">
      <div className="group_info">
        {typeof renderGuideView === 'function'
          ? renderGuideView()
          : renderGuideView}
        <div className="group_option">
          <CmpAssetLibraryImageEditorDataFilter
            adAccountId={adAccountId}
            campaignType={campaignType}
            creativeFormat={creativeFormat}
            creativeAssetPropertyType={creativeAssetPropertyType}
            assetConstraint={assetConstraint}
            specificRatio={specificRatio}
          />
          <div className="f_left">
            <CmpEditorOpenButton
              adAccountId={adAccountId}
              creativeFormat={creativeFormat}
              cmpAssetPropertyType={cmpAssetPropertyType}
              ratioValueFilter={editorRatioValueFilter}
              onCreate={({ editorItems }) => {
                if (!editorItems || editorItems.length === 0) {
                  showErrorMessage('생성된 에디터 아이템이 유효하지 않습니다.')
                  return
                }

                // 리스트 갱신
                fetchEditorData()

                // 다중 선택일 경우 add, 단일 선택일 경우 replace
                if (multiSelect) {
                  dispatch(
                    addCmpAssetLibraryImageSelectedItems({
                      items: editorItems,
                    })
                  )
                } else {
                  dispatch(
                    setCmpAssetLibraryImageSelectedItems({
                      items: take(editorItems),
                    })
                  )
                }
              }}
              ratio={specificRatio}
            />
          </div>
        </div>
      </div>
      <div className="group_material">
        {isFetchingEditor ? (
          <div className="reform_box_nodata">
            <div className="txt_nodata">
              <MomentLoader />
            </div>
          </div>
        ) : totalElements > 0 ? (
          <div className="inner_material">
            <ul className="list_material">
              {items.map((item, index) => {
                const { imageUUID } = item
                const selectedOrder =
                  selectedItems.findIndex(
                    ({ imageUUID: selectedItemImageUUID }) =>
                      selectedItemImageUUID === imageUUID
                  ) + 1

                return (
                  <CmpAssetLibraryImageEditorItemView
                    key={imageUUID}
                    index={index}
                    selectedOrder={selectedOrder}
                    item={item}
                    cropAspects={cropAspects}
                    query={query}
                    isVisibleAnim={false}
                    multiSelect={multiSelect}
                    isBizBoardBanner={isBizBoardBanner}
                    handleCmpImageEditorOpenForModify={
                      handleCmpImageEditorOpenForModify
                    }
                    validateImageSize={validateImageSize}
                  />
                )
              })}
            </ul>
          </div>
        ) : (
          <div className="reform_box_nodata">
            <div className="txt_nodata">
              이미지 에디터에 최근 저장한 이미지가 없습니다.
            </div>
          </div>
        )}
      </div>
      <Pagination
        currentIndex={pageIndex}
        totalElements={totalElements}
        paginationVisible={!isFetchingEditor && totalElements > 0}
        onChangePage={handlePageIndexChange}
        pageSize={pageSize}
        isPageOptionEnable={true}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  )
}

CmpAssetLibraryImageEditorData.propTypes = {
  adAccountId: PropTypes.any.isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyType: PropTypes.oneOf(CreativeAssetPropertyEnum.values())
    .isRequired,
  cropAspects: PropTypes.array.isRequired,
  assetConstraint: ImmutablePropTypes.map.isRequired,
  multiSelect: PropTypes.bool,
  handleCmpImageEditorOpenForModify: PropTypes.func.isRequired,
  validateImageSize: PropTypes.func.isRequired,
  editorRatioValueFilter: PropTypes.func,
  //
  renderGuideView: PropTypes.any,
  cmpAssetPropertyType: PropTypes.oneOf(CmpAssetPropertyEnum.values()),
  specificRatio: PropTypes.number,
}

export default CmpAssetLibraryImageEditorData
