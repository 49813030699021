import moment from 'moment'
import assign from 'object-assign'
import { checkEmpty, checkNotEmpty, isNoneUndefinedOrNull } from './regexUtils'
import { Iterable, Map } from 'immutable'
import { coerceToArray } from './stringUtils'
import memoizeOne from 'memoize-one'
import qs from 'qs'
import Big from 'big.js'
import { NumberUtils } from './numberUtils'

/***
 * 동적으로 스크립트 로딩하는 함수
 * @param {string} src - library path
 * @param {function} callback - onload callback function
 */
export function loadScript(src, callback) {
  let s
  let r
  let t
  r = false
  s = document.createElement('script')
  s.type = 'text/javascript'
  s.src = src
  s.onload = s.onreadystatechange = function () {
    if (!r && (!this.readyState || this.readyState === 'complete')) {
      r = true
      callback()
    }
  }
  t = document.getElementsByTagName('script')[0]
  t.parentNode.insertBefore(s, t)
}

/**
 * @deprecated use "NumberUtils.withCommas()" instead
 * @param number {number || string}
 * @return {string}
 */
export function convertToCommaSeparatedString(number) {
  return NumberUtils.withCommas(number)
}

export const DATE_PRESET_KEY = keyMirror({
  RECENT_ONE_MONTH: null,
  RECENT_THREE_MONTH: null,
  RECENT_SIX_MONTH: null,
  RECENT_TWELVE_MONTH: null,
})

export const DATE_PRESET_ITEMS = {
  [DATE_PRESET_KEY.RECENT_ONE_MONTH]: { label: '최근 1개월', value: 1 },
  [DATE_PRESET_KEY.RECENT_THREE_MONTH]: { label: '최근 3개월', value: 3 },
  [DATE_PRESET_KEY.RECENT_SIX_MONTH]: { label: '최근 6개월', value: 6 },
  [DATE_PRESET_KEY.RECENT_TWELVE_MONTH]: { label: '최근 12개월', value: 12 },
}

/**
 * 기간 선택상자 : 이번달, 최근 한달, 최근 3개월, 최근 6개월, 최근 12개월 등 기간 프리셋에 해당하는 값을 리턴.
 * @param id
 * @param format {string}
 * @returns {{from: string, to: string}}
 */
export function selectedDatePickerValue(id, format = 'YYYY-MM-DD') {
  const to = moment().format(format)
  const period = DATE_PRESET_ITEMS[id].value

  return {
    from: moment().subtract(period, 'month').format(format),
    to,
  }
}

/**
 * 웹브라우저 다운로드 기능. export시킬 데이터와 파일명을 넘겨주면 다운로드된다.
 * @param data
 * @param filename
 * @param mime
 */
export function fileDownloadBlob(data, filename, mime) {
  const encodedData = encodeUTF16(data)
  const blob = new Blob([encodedData], {
    type: mime || 'application/octet-stream',
  })

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE
    window.navigator.msSaveBlob(blob, filename)
  } else {
    // Chrome
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)
    tempLink.setAttribute('target', '_blank')
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}

/**
 * string 데이터를 utf-16의 유니코드로 변환후, 배열에 담아 리턴.
 * @param str
 * @returns {Uint16Array}
 */
function encodeUTF16(str) {
  const buf = new ArrayBuffer(str.length * 2)
  const bufArray = new Uint16Array(buf)
  for (let i = 0, len = str.length; i < len; i++) {
    bufArray[i] = str.charCodeAt(i)
  }
  return bufArray
}

/**
 * 숫자 > 특문 > 한글 > 영어
 * @param a
 * @param b
 * @param locale
 * @return {number} 1: a > b, 0: a == b, -1: a < b
 */
export function naturalComparator(a, b, locale = 'ko') {
  const ax = []
  const bx = []

  if (typeof a === 'string' || typeof a === 'number') {
    String(a).replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      ax.push([$1 || Infinity, $2 || ''])
    })
  }
  if (typeof b === 'string' || typeof b === 'number') {
    String(b).replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      bx.push([$1 || Infinity, $2 || ''])
    })
  }

  while (ax.length && bx.length) {
    const an = ax.shift()
    const bn = bx.shift()
    if (an?.length > 1 && bn?.length > 1) {
      const nn = an[0] - bn[0] || an[1].localeCompare(bn[1], locale)
      if (nn) return nn
    }
  }

  return ax.length - bx.length
}

/**
 * sort Immutable.Iterable or javascript array object.
 *    ex) Immutable.Iterable
 *    const list =
 *        List([
 *            Map({ id: 1, name: 'campaign1', adGroups: Map({ name: 'adGroup1',.. })}),
 *            Map({ id: 2, name: 'campaign2', adGroups: Map({ name: 'adGroup2',.. })}),...
 *        ])
 *    ex) javascript Array
 *    const list =
 *        [
 *          { id: 1, name: 'campaign1', adGroups: { name: 'adGroup1',... },
 *          { id: 2, name: 'campaign2', adGroups: { name: 'adGroup2',... },...
 *        ]
 *
 *        -> list.sort((v1, v2) => keyedComparator(v1, v2, 'adGroups.name'))
 *        or
 *        -> list.sort((v1, v2) => keyedComparator(v1, v2, '['adGroups', 'name']))
 *
 * @param o1 {Iterable | Object | Array}
 * @param o2 {Iterable | Object | Array}
 * @param keyPath {string | Array}
 * @param locale {string}
 * @return {number} a > b ? 1 : a === b ? 0 : -1
 * @constructor
 */
export function keyedComparator(o1, o2, keyPath = null, locale = 'ko') {
  if (!o1 || !o2 || !keyPath) return naturalComparator(o1, o2, locale)

  const keyArray = coerceToArray(keyPath)

  if (Iterable.isIterable(o1) && Iterable.isIterable(o2)) {
    return naturalComparator(o1.getIn(keyArray), o2.getIn(keyArray), locale)
  }

  if (typeof o1 === 'object' && typeof o2 === 'object') {
    return naturalComparator(
      getObjectValueByKeyArray(o1, keyArray),
      getObjectValueByKeyArray(o2, keyArray),
      locale
    )
  }

  return 0
}

/**
 * @param object {object}
 * @param keyArray {Array}
 * @return {*}
 * ex)
 * object:
 * {
 *    first: {
 *        number: 1,
 *        text: 'Ya.',
 *    },
 *    second: {
 *        number: 10,
 *        text: 'Da.',
 *    },
 * }
 * keyArray: ['second', 'number']
 * return: 10
 */
export function getObjectValueByKeyArray(object, keyArray) {
  if (
    !Array.isArray(keyArray) ||
    keyArray.length === 0 ||
    typeof object !== 'object'
  )
    return undefined

  return keyArray.reduce(
    (prev, curr) => (typeof prev === 'object' ? prev[curr] : undefined),
    object
  )
}

export function shouldPullDownOpen(parent, target, isOpen = true) {
  // 만약 parent 와 target 이 같다면 즉시 비교.
  if (parent === target) return !isOpen

  let ancestor = target.parentNode

  if (isOpen && parent === ancestor) return false

  while (ancestor !== null) {
    if (parent === ancestor) return true

    ancestor = ancestor.parentNode
  }

  return false
}

/**
 * @deprecated use `uuid()`
 */
export function makeRandomId() {
  let text = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < 13; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text
}

/***
 * keymirror
 * @param obj
 * @param extraKey
 * @return {{}}
 */
export function keyMirror(obj, extraKey = null) {
  let ret = {}
  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.')
  }
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      ret[key] = extraKey ? `${extraKey}_${key}` : key
    }
  }
  return ret
}

export function dateTimeToString(obj) {
  return moment(obj).format('YYYY-MM-DD HH:mm')
}

export function localDateTimeToString(obj) {
  return moment(obj).format(moment.HTML5_FMT.DATETIME_LOCAL)
}

export function localDateTimeSecondToString(obj) {
  return moment(obj).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
}

export function dateToString(obj) {
  return moment(obj).format('YYYY-MM-DD')
}

export function dateToDefaultString(obj) {
  return moment(obj).format('YYYYMMDD')
}

export function dateToKoreanString(obj) {
  return moment(obj).format('YYYY년 MM월 DD일')
}

export function isValidDate(obj, format = moment.HTML5_FMT.DATE) {
  return checkNotEmpty(obj) && moment(obj, format).isValid()
}

export function getValidDateToString(obj) {
  return obj && moment(obj).isValid() ? dateToString(obj) : null
}

export function getValidLocalDateTimeToString(obj) {
  return obj && moment(obj).isValid() ? localDateTimeToString(obj) : null
}

export function coerceAtLeast(value, minimum) {
  return value < minimum ? minimum : value
}

export function coerceAtMost(value, maximum) {
  return value > maximum ? maximum : value
}

export function coerceIn(value, minimum, maximum) {
  return value < minimum ? minimum : value > maximum ? maximum : value
}

export function distinct(iterable, grouper = v => v) {
  return iterable
    ?.groupBy(grouper)
    ?.map(v => v.first())
    ?.toList()
}

/**
 * 현재 focus 를 가진 element 를 강제로 해제.
 * enter 연타 등을 통한 request 방지.
 */
export const removeElementFocus = () => {
  const focused = document.activeElement
  if (focused) focused.blur()
}

/**
 * url 파라미터 파싱하여 object 로 리턴
 * @param search : url parameter
 * @returns {*}
 */
export const parseUrlParameter = search => {
  if (checkEmpty(search) || !search.includes('?')) return {}

  const params = decodeURIComponent(search)
    .slice(search.indexOf('?') + 1)
    .split('&')
  return params.reduce((prev, v) => {
    const [key, val] = v.split('=')
    return assign(prev, { [key]: val })
  }, {})
}

export const checkCallbackEnabled = callback => {
  return typeof callback === 'function'
}

/**
 * 동영상 재생시간 표기
 * @param second
 * @returns {string}
 */
export const getRemainingTimeSeparatedString = second => {
  const hh = Math.floor(second / (60 * 60))
  const mm = Math.floor((second % (60 * 60)) / 60)
  const ss = Math.floor(second % 60)

  const arr = []
  const hasHour = hh > 0

  /**
   * 1min + 5sec = 1:05
   * 1hour + 1min + 5sec = 1:01:05
   */
  if (hasHour) {
    arr.push(hh)
    arr.push(String(mm).padStart(2, '0'))
  } else {
    arr.push(mm)
  }

  arr.push(String(ss).padStart(2, '0'))

  return arr.join(':')
}

/**
 * unique 한 string 값 생성
 * https://gist.github.com/gordonbrander/2230317
 * @returns {string}
 */
export const getUniqueStringId = () => {
  return `_${Math.random().toString(36).substr(2, 9)}`
}

/**
 * url params 를 파싱하여 object 로 리턴
 * @param location {Immutable.Map | object} : location(react router dom), window.location
 */
export const queryParams = memoizeOne(location => {
  return qs.parse(
    Map.isMap(location) ? location.get('search') : location.search,
    {
      ignoreQueryPrefix: true,
    }
  )
})

export const getVat = price => {
  if (!price) return 0
  return Big(1.1).times(Big(price)).toString()
}

export const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
  return false
}

export const queryString = paramsObject => {
  const options = {
    encode: false,
    indices: false,
    arrayFormat: 'repeat',
    skipNulls: true,
    filter: (prefix, value) => {
      if (typeof value === 'string' && !isNaN(Number(value))) {
        value = value.trim()
      }
      if (typeof value === 'string' && value.trim() === '') {
        return value
      }
      if (value === undefined || value === null) {
        return value
      }
      if (Array.isArray(value) && value.length === 0) {
        return value
      }
      if (Array.isArray(value) && value.length !== 0 && prefix !== 'sort') {
        return value.join(',')
      }
      if (value instanceof Set) {
        return Array.from(value).join(`&${prefix}=`)
      }
      return value
    },
  }

  return qs.stringify(paramsObject, options)
}

export const getTimeLabel = ({ beginTime, endTime }) => {
  if (isNoneUndefinedOrNull(beginTime, endTime)) {
    const finishTime = checkNotEmpty(endTime.substring(0, 2))
      ? endTime
      : '24:00:00'
    const DateLabel = `${beginTime.substring(0, 5)} ~ ${finishTime.substring(
      0,
      5
    )}`

    const beginHour = Number(beginTime.substring(0, 2))
    const endHour = Number(finishTime.substring(0, 2))

    const periodLabel = `${endHour - beginHour + 1}시간`

    return `${DateLabel} (${periodLabel})`
  }
  return '시간 선택'
}
