import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'

const KAKAO_BUSINESS_GUIDE_URL = 'https://kakaobusiness.gitbook.io/main'

export const KAKAO_BUSINESS_GUIDE_MOMENT = `${KAKAO_BUSINESS_GUIDE_URL}/ad/moment`

export const KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD = `${KAKAO_BUSINESS_GUIDE_MOMENT}/start`

export const KAKAO_BUSINESS_GUIDE_MOMENT_PERSONAL_MESSAGE = `${KAKAO_BUSINESS_GUIDE_URL}/ad/moment/start`

export const KAKAO_BUSINESS_GUIDE_TRACKING_URL = `${KAKAO_BUSINESS_GUIDE_URL}/ad/moment/start/catalog/trackingurl-guide`

export const KAKAO_BUSINESS_GUIDE_BID_STRATEGY_TARGET = `${KAKAO_BUSINESS_GUIDE_URL}/ad/moment#id-3-1`

export const KAKAO_PIXEL_AND_SDK_GUIDE = `${KAKAO_BUSINESS_GUIDE_URL}/tool/pixel-sdk#2.-and-sdk `

export const WEB_KAKAO_PIXEL_AND_SDK_GUIDE = `${KAKAO_BUSINESS_GUIDE_URL}/tool/pixel-sdk/install`

export const KAKAO_BUSINESS_GUIDE = {
  CREATE_AD: {
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/talkboard`,
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD_RESERVED]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/cpt`,
    [CampaignTypeEnum.Type
      .DISPLAY]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/displayad`,
    [CampaignTypeEnum.Type
      .TALK_CHANNEL]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/messagead`,
    [CampaignTypeEnum.Type
      .DAUM_SHOPPING]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/daumshop`,
    [CampaignTypeEnum.Type
      .VIDEO]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/videoad`,
    [CampaignTypeEnum.Type
      .PRODUCT_CATALOG]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/catalog`,
    [CampaignTypeEnum.Type
      .PERSONAL_MESSAGE]: `${KAKAO_BUSINESS_GUIDE_MOMENT_PERSONAL_MESSAGE}/personalizedmessage`,
    [CampaignTypeEnum.Type
      .PC_TALK_RICH_POP]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/richpop`,
    [CampaignTypeEnum.Type
      .PC_TALK_BOTTOM]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/focusboard`,
    [CampaignTypeEnum.Type
      .FOCUS_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/focusfullview`,
    [CampaignTypeEnum.Type
      .PROFILE_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/profilefullview`,
  },

  CAMPAIGN: {
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/talkboard/campaign`,
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD_RESERVED]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/cpt/run/campaign`,
    [CampaignTypeEnum.Type
      .DISPLAY]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/displayad/campaign`,
    [CampaignTypeEnum.Type
      .TALK_CHANNEL]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/messagead/campaign`,
    [CampaignTypeEnum.Type
      .DAUM_SHOPPING]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/daumshop/campaign`,
    [CampaignTypeEnum.Type
      .VIDEO]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/videoad/campaign`,
    [CampaignTypeEnum.Type
      .PRODUCT_CATALOG]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/catalog/campaign`,
    [CampaignTypeEnum.Type
      .PERSONAL_MESSAGE]: `${KAKAO_BUSINESS_GUIDE_MOMENT_PERSONAL_MESSAGE}/personalizedmessage/campaign`,
    [CampaignTypeEnum.Type
      .PC_TALK_RICH_POP]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/richpop/start#id-1`,
    [CampaignTypeEnum.Type
      .PC_TALK_BOTTOM]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/focusboard/start#id-1`,
    [CampaignTypeEnum.Type
      .FOCUS_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/focusfullview/start#id-1`,
    [CampaignTypeEnum.Type
      .PROFILE_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/profilefullview/start#id-1`,
  },

  AD_GROUP: {
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/talkboard/group`,
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD_RESERVED]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/cpt/run/group`,
    [CampaignTypeEnum.Type
      .DISPLAY]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/displayad/group`,
    [CampaignTypeEnum.Type
      .TALK_CHANNEL]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/messagead/group`,
    [CampaignTypeEnum.Type
      .DAUM_SHOPPING]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/daumshop/group`,
    [CampaignTypeEnum.Type
      .VIDEO]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/videoad/group`,
    [CampaignTypeEnum.Type
      .PRODUCT_CATALOG]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/catalog/group`,
    [CampaignTypeEnum.Type
      .PERSONAL_MESSAGE]: `${KAKAO_BUSINESS_GUIDE_MOMENT_PERSONAL_MESSAGE}/personalizedmessage/group`,
    [CampaignTypeEnum.Type
      .PC_TALK_RICH_POP]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/richpop/start#id-2`,
    [CampaignTypeEnum.Type
      .PC_TALK_BOTTOM]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/focusboard/start#id-2`,
    [CampaignTypeEnum.Type
      .FOCUS_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/focusfullview/start#id-2`,
    [CampaignTypeEnum.Type
      .PROFILE_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/profilefullview/start#id-2`,
  },

  CREATIVE: {
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/talkboard/content`,
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD_RESERVED]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/cpt/run/content`,
    [CampaignTypeEnum.Type
      .DISPLAY]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/displayad/content`,
    [CampaignTypeEnum.Type
      .TALK_CHANNEL]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/messagead/content`,
    [CampaignTypeEnum.Type
      .DAUM_SHOPPING]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/daumshop/content`,
    [CampaignTypeEnum.Type
      .VIDEO]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/videoad/content`,
    [CampaignTypeEnum.Type
      .PRODUCT_CATALOG]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/catalog/content`,
    [CampaignTypeEnum.Type
      .PERSONAL_MESSAGE]: `${KAKAO_BUSINESS_GUIDE_MOMENT_PERSONAL_MESSAGE}/personalizedmessage/content`,
    [CampaignTypeEnum.Type
      .PC_TALK_BOTTOM]: `${KAKAO_BUSINESS_GUIDE_MOMENT_PERSONAL_MESSAGE}/brandingdisplay/focusboard/start#id-3`,
    [CampaignTypeEnum.Type
      .PC_TALK_RICH_POP]: `${KAKAO_BUSINESS_GUIDE_MOMENT_PERSONAL_MESSAGE}/brandingdisplay/richpop/start#id-3`,
    [CampaignTypeEnum.Type
      .FOCUS_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/focusfullview/start#id-3`,
    [CampaignTypeEnum.Type
      .PROFILE_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/profilefullview/start#id-3`,
  },

  DASHBOARD: `${KAKAO_BUSINESS_GUIDE_MOMENT}/efficiency/dashboard`,

  CANCELLATION: {
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD_RESERVED]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/cpt/run#cancellation-fee`,
    [CampaignTypeEnum.Type
      .TALK_BIZ_BOARD_CHAT_TAB_CPT]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/cpt/run#cancellation-fee`,
    [CampaignTypeEnum.Type
      .PC_TALK_BOTTOM]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/process#cancellation-fee`,
    [CampaignTypeEnum.Type
      .PC_TALK_RICH_POP]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/brandingdisplay/process#cancellation-fee`,
    [CampaignTypeEnum.Type
      .FOCUS_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/focusfullview/process#cancellation-fee`,
    [CampaignTypeEnum.Type
      .PROFILE_FULL_VIEW]: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/profilefullview/process#cancellation-fee`,
  },

  MY_INFO: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/account#id-3`,
  MY_AD_ACCOUNT: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/account#2.`,
  CREATE_AD_ACCOUNT: `${KAKAO_BUSINESS_GUIDE_MOMENT}/quick-start#1.`,
  REPORT: `${KAKAO_BUSINESS_GUIDE_MOMENT}/efficiency/report`,

  ASSET_MANAGEMENT: {
    MESSAGE: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/property#id-4`,
    MESSAGE_CREATE: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/property#id-4-2`,
    MESSAGE_MODIFY: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/property#id-4-4`,
    AD_VIEW: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/property#id-3`,
    BIZ_FORM: `${KAKAO_BUSINESS_GUIDE_URL}/tool/bizform`,
    PIXEL_AND_SDK: `${KAKAO_BUSINESS_GUIDE_URL}/tool/pixel-sdk/linkage`,
  },

  TARGET_MANAGEMENT: {
    AUDIENCE: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/target#id-1`,
    COHORT: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/target#id-2`,
    CUSTOMER_FILE: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/target#id-3`,
    FRIEND_GROUP: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/target#id-4`,
  },

  SETTING: {
    AD_ACCOUNT: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/account#id-1-1`,
    MEMBER: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/account#id-1-2`,
    BIZ_RIGHT: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/account#id-1-3`,
    INVOICE: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/account#id-1-4`,
    REVIEW_DOCUMENT: `${KAKAO_BUSINESS_GUIDE_MOMENT}/ad-audit`,
    CASH: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/payment`,
    PAYMENT_CARD: `${KAKAO_BUSINESS_GUIDE_MOMENT}/run/payment#id-2-3`,
    BIZ_BOARD_CPT_CANCELLATION: `${KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}/cpt/run#id-4-2`,
  },
}
