import { createReducer } from 'redux-immutablejs'
import { fromJS, Map } from 'immutable'
import { hideLoading, showLoading } from '../common/mLoading'
import { scrollByValidationKeys, Validation } from '../../validators/validation'
import { Trim } from '../../utils/formTrim'
import {
  dateToString,
  keyMirror,
  localDateTimeToString,
} from '../../utils/utils'
import { showErrorMessage } from '../../utils/alertUtils'
import { handleCreativeFormExceptionV2 } from './creativeActions/aCreativeCommonV2'
import { coerceToArray } from '../../utils/stringUtils'
import LandingSchemeEnum from '../../enums/LandingSchemeEnum'
import { CreativeHelper } from '../../utils/helper/helper-creative'
import {
  getCreativeValidationKeyByFormat,
  getCreativeValidationKeyPathByFormat,
  getCreativeValidatorByFormat,
} from '../../validators/advertise/creativeV2/creativeFormValidatorV2'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import moment from 'moment'
import { checkEmpty, isUndefinedOrNull } from '../../utils/regexUtils'
import { CREATIVE_FORM_VALIDATION_KEY } from '../../validators/advertise/creativeV2/creativeFormValidationKey'
import {
  CREATIVE_TRIM_KEY_PATH,
  disableDefaultProfileImageCheck,
  initCreativeValidation,
  setCreativeMessageSpamValidation,
  setIsValidCreativeByKey,
  uploadCreativeImageWithRatioByURL,
} from './mCreativeCommonV2'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import { replaceCurrentTimeToBaseTime } from '../../components/Common/HourMinuteSelector/HourMinuteSelectorHOC'
import VideoSkippableTypeEnum from '../../enums/VideoSkippableTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import { TalkChannelMessageHelper } from '../../utils/helper/helper-talkChannelMessage'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import { showSpamFilterPopUp } from '../message/mMessageCommon'
import { MESSAGE_FORM_VALIDATION_KEY } from '../../validators/message/messageFormValidationKey'
import ExpandableEnum from '../../enums/ExpandableEnum'
import { setDiscountedPricePercentage } from '../../utils/priceUtils'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'
import { isVideoCampaignTypeCreative } from '../../utils/advertise/adCreativeV2'

const CreativeModify = keyMirror(
  {
    // form
    SET_MODIFY_TARGET: null,
    CHANGE_FORM_BY_KEY_PATH: null,
    INIT_FORM: null,

    // viewable
    SET_IMAGE_BY_KEY: null,
    DELETE_IMAGE_BY_KEY: null,

    // shopping
    SET_SHOPPING_EXECUTIVE_TIME: null,
    CHANGE_SHOPPING_EXECUTIVE_TIME: null,

    CLEAR: null,
  },
  'CREATIVE_MODIFY'
)

const initialState = fromJS({
  creativeForm: {},
  shopping: {
    executiveTime: {
      beginDate: undefined,
      beginHour: undefined,
      beginMinute: undefined,
      endDate: undefined,
      endHour: undefined,
      endMinute: undefined,
    },
  },
})

export default createReducer(initialState, {
  [CreativeModify.SET_MODIFY_TARGET]: (state, { data }) =>
    state.set('creativeForm', fromJS(data)),

  [CreativeModify.CHANGE_FORM_BY_KEY_PATH]: (state, { key, value }) =>
    state.setIn(['creativeForm', ...coerceToArray(key)], fromJS(value)),

  [CreativeModify.INIT_FORM]: state =>
    state.set('creativeForm', initialState.get('creativeForm')),

  [CreativeModify.SET_IMAGE_BY_KEY]: (state, { key, image, expressMetaInfo }) =>
    state.update('creativeForm', v =>
      v.withMutations(s => {
        const { backgroundColor, creativeFormat } = s

        s.set(key, fromJS(image))
          .set(
            'backgroundColor',
            creativeFormat === CreativeFormatEnum.Type.IMAGE_BANNER
              ? backgroundColor
              : image.backgroundColor
          )
          /**
           * 비즈보드 편집 데이터
           * - 배너 생성 시 redux state 의 uploadedMultiImages 항목 내에 위치
           * - 배너 수정 시에는 creativeForm 에 위치
           */
          .set(
            'expressMetaInfos',
            expressMetaInfo ? fromJS([expressMetaInfo]) : undefined
          )
      })
    ),

  [CreativeModify.DELETE_IMAGE_BY_KEY]: (state, { key }) =>
    state.update('creativeForm', v =>
      v.withMutations(s => {
        const { backgroundColor, creativeFormat } = s

        return s
          .set(key, Map())
          .set(
            'backgroundColor',
            creativeFormat === CreativeFormatEnum.Type.IMAGE_BANNER
              ? backgroundColor
              : null
          )
          .delete('expressMetaInfos')
          .delete('bizBoardNativeImage')
      })
    ),

  [CreativeModify.CLEAR]: state => state.merge(initialState),

  [CreativeModify.CHANGE_SHOPPING_EXECUTIVE_TIME]: (state, { key, value }) => {
    return state.setIn(
      ['shopping', 'executiveTime', ...coerceToArray(key)],
      fromJS(value)
    )
  },

  [CreativeModify.SET_SHOPPING_EXECUTIVE_TIME]: (
    state,
    { adGroupSchedule }
  ) => {
    const { beginDate, endDate } = adGroupSchedule || {}

    const isAdGroupOperating = moment().isBetween(
      moment(beginDate),
      moment(endDate),
      null,
      '[]'
    )

    const {
      creativeForm: { beginDateTime, endDateTime },
    } = state

    const initialBeginDate = beginDateTime
      ? moment(beginDateTime)
      : isAdGroupOperating
      ? replaceCurrentTimeToBaseTime(5)
      : moment(beginDate).startOf('d')

    const initialEndDate = endDateTime
      ? moment(endDateTime)
      : moment(endDate).endOf('d')

    return state.withMutations(s =>
      s
        .setIn(
          ['shopping', 'executiveTime', 'beginDate'],
          dateToString(initialBeginDate)
        )
        .setIn(
          ['shopping', 'executiveTime', 'beginHour'],
          initialBeginDate?.hour()
        )
        .setIn(
          ['shopping', 'executiveTime', 'beginMinute'],
          initialBeginDate?.minute()
        )
        .setIn(
          ['shopping', 'executiveTime', 'endDate'],
          dateToString(initialEndDate)
        )
        .setIn(['shopping', 'executiveTime', 'endHour'], initialEndDate?.hour())
        .setIn(
          ['shopping', 'executiveTime', 'endMinute'],
          initialEndDate?.minute()
        )
    )
  },
})

export function setCreativeModifyTargetInfo(data) {
  return {
    type: CreativeModify.SET_MODIFY_TARGET,
    data,
  }
}

export function changeCreativeModifyFormByKey(key, value) {
  return {
    type: CreativeModify.CHANGE_FORM_BY_KEY_PATH,
    key,
    value,
  }
}

export function initAllCreativeModifyForm() {
  return {
    type: CreativeModify.INIT_FORM,
  }
}

/**
 * 업로드 한 image file 객체의 metadata 를 set.
 */
export function setCreativeModifyImageByKey(key, image, expressMetaInfo) {
  return {
    type: CreativeModify.SET_IMAGE_BY_KEY,
    key,
    image,
    expressMetaInfo,
  }
}

/**
 * 2탭 배너 소재 수정에서 base64 로 업로드
 * @param adAccountId {string | number}
 * @param expressMetaInfo {object}
 * @param base64ImageObject {object}
 * @param requiredWidth {number}
 * @param requiredHeight {number}
 * @param responseHandler {object}
 * @param bizBoardNativeImage {object}
 */
export function uploadBizBoardBannerModifyBase64Image(
  adAccountId,
  expressMetaInfo,
  base64ImageObject,
  requiredWidth,
  requiredHeight,
  responseHandler,
  bizBoardNativeImage
) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    const { onSuccess, onFail } = responseHandler || {}

    const formData = new FormData()

    formData.append('imageBase64', JSON.stringify(base64ImageObject))
    formData.append('png64', String(true))

    try {
      const response = await api.adCreative.uploadCreativeImageBase64(
        adAccountId,
        formData
      )

      const { width, height } = response.data || {}
      if (width === requiredWidth && height === requiredHeight) {
        dispatch(
          setCreativeModifyImageByKey('image', response.data, expressMetaInfo)
        )

        dispatch(
          changeCreativeModifyFormByKey(
            'bizBoardNativeImage',
            bizBoardNativeImage
          )
        )

        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      } else {
        showErrorMessage(
          '등록 가능한 이미지 사이즈를 확인하신 후 다시 시도하세요.'
        )

        if (typeof onFail === 'function') {
          onFail()
        }
      }
    } catch (e) {
      if (typeof onFail === 'function') {
        onFail()
      }
      const { errorCode, message, detail } = e?.response?.data || {}
      dispatch(handleCreativeFormExceptionV2({ errorCode, message, detail }))
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function uploadCreativeModifyProfileImageByPlusFriendURL(
  adAccountId,
  url,
  id,
  minWidth,
  requiredRatio
) {
  const onSuccess = (dispatch, response) => {
    dispatch(setCreativeModifyImageByKey(id, response.data))
    dispatch(changeCreativeModifyFormByKey('useDefaultProfileImage', true))
    dispatch(disableDefaultProfileImageCheck(false))
  }

  const onFail = dispatch => {
    dispatch(changeCreativeModifyFormByKey('useDefaultProfileImage', false))
    dispatch(disableDefaultProfileImageCheck(true))
    showErrorMessage(
      '기존 등록되어 있는 카카오톡 채널의 프로필 이미지가 광고 소재로 사용하기 적절하지 않습니다.'
    )
  }

  return uploadCreativeImageWithRatioByURL(
    adAccountId,
    url,
    minWidth,
    requiredRatio,
    onSuccess,
    onFail
  )
}

/**
 * FIXME
 * 업로드 한 이미지 데이터를 제거한다.
 */
export function deleteCreativeModifyImageByKey(key) {
  return {
    type: CreativeModify.DELETE_IMAGE_BY_KEY,
    key,
  }
}

export function submitCreativeModifyForm(
  adAccountId,
  campaignId,
  adGroupId,
  creativeId
) {
  return async (dispatch, getState, api) => {
    const {
      creativeV2: {
        modify: {
          creativeForm,
          creativeForm: {
            name,
            creativeFormat,
            opinionProof: prevOpinionProof,
            expandableType,
            image,
          },
          shopping: { executiveTime },
        },
        common: {
          campaign: {
            campaignTypeGoal: { campaignType, goal },
          },
          creativeViewState: { isSameUrlChecked, isEachChecked },
        },
        bizBoardExpandable: { expandableAssetGroups },
        opinionProof,
        promotionalVideo: {
          video,
          video: { duration },
          autoThumbnail,
          uploadThumbnail,
          videoSkippableType,
        },
        catalog: { assetGroups },
        creativeMessage,
        dynamicCatalog: {
          productSet: { isSegment, originalCatalogId },
        },
      },
    } = getState()

    const isBizBoard =
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
    const isShoppingBox = campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING
    const isRequiredLandingInfo = [
      CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT,
      CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
      CampaignTypeEnum.Type.TALK_BIZ_BOARD,
      CampaignTypeEnum.Type.PC_TALK_BOTTOM,
      CampaignTypeEnum.Type.PC_TALK_RICH_POP,
      CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
      CampaignTypeEnum.Type.PROFILE_FULL_VIEW,
    ].includes(campaignType)

    /**
     * pre-processing
     * 여기서 소재 id 를 url param 에 기인하여 세팅해야 한다. (소재 parent - children 구조 이슈) by jack. (170612)
     * !!validation 을 위하여 반드시 모든 내용은 Immutable 형태여야 함에 주의!!
     */
    const formData = fromJS(
      creativeForm.withMutations(s =>
        s
          .set('id', creativeId)
          .update(creativeForm =>
            // video
            isVideoCampaignTypeCreative({ campaignType, creativeFormat }) ||
            campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM ||
            campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW
              ? creativeForm.withMutations(s =>
                  s
                    .set('video', fromJS(CreativeHelper.Video.toAPI(video)))
                    .set(
                      'image',
                      campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW
                        ? image
                        : fromJS(
                            CreativeHelper.Image.toAPI(
                              uploadThumbnail?.count() > 0
                                ? uploadThumbnail
                                : autoThumbnail
                            )
                          )
                    )
                    .set(
                      'image1',
                      fromJS(CreativeHelper.Image.toAPI(autoThumbnail))
                    )
                    .set(
                      'image2',
                      fromJS(CreativeHelper.Image.toAPI(uploadThumbnail))
                    )
                    .set(
                      'videoSkippableType',
                      creativeFormat === CreativeFormatEnum.Type.VIDEO_INSTREAM
                        ? goal === GoalEnum.Type.VISITING ||
                          (goal === GoalEnum.Type.REACH && duration <= 5) // 방문 or 도달 + 5초 이하 소재 수정 시 SECONDS_5 로 강제화
                          ? VideoSkippableTypeEnum.Type.SECONDS_5
                          : videoSkippableType
                        : undefined
                    )
                )
              : creativeForm
          )
          // catalog
          .set(
            'assetGroups',
            creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL
              ? assetGroups
                  .map(assetGroup =>
                    assetGroup
                      .update('title', title => String(title).trim())
                      .update('description', description =>
                        checkEmpty(description)
                          ? description
                          : String(description).trim() === ''
                          ? null
                          : String(description).trim()
                      )
                      .update('rspvLandingUrl', rspvLandingUrl => {
                        const defaultUrl =
                          assetGroup.get('mobileLandingUrl') ||
                          assetGroup.get('pcLandingUrl') ||
                          rspvLandingUrl

                        const isAssetGroupSameUrlChecked =
                          assetGroup.get('isSameUrlChecked')
                        const isAssetEachChecked =
                          assetGroup.get('isEachChecked')

                        if (isAssetGroupSameUrlChecked) {
                          return defaultUrl
                        } else if (
                          checkEmpty(rspvLandingUrl) ||
                          isAssetEachChecked
                        ) {
                          return null
                        } else {
                          return rspvLandingUrl
                        }
                      })
                      .update('pcLandingUrl', pcLandingUrl =>
                        checkEmpty(pcLandingUrl) ||
                        assetGroup.get('isSameUrlChecked')
                          ? null
                          : pcLandingUrl
                      )
                      .update('mobileLandingUrl', mobileLandingUrl =>
                        checkEmpty(mobileLandingUrl) ||
                        assetGroup.get('isSameUrlChecked')
                          ? null
                          : mobileLandingUrl
                      )
                  )
                  .map(assetGroup => setDiscountedPricePercentage(assetGroup))
              : undefined
          )
          .set('expandableType', isBizBoard ? expandableType : undefined)
          .set(
            'expandableAssetGroups',
            isBizBoard
              ? expandableAssetGroups
                  .map(expandableAssetGroup =>
                    expandableAssetGroup
                      .update('title', title =>
                        expandableType === ExpandableEnum.Type.MULTI
                          ? String(title).trim()
                          : undefined
                      )
                      .update('mobileLandingUrl', mobileLandingUrl =>
                        expandableType === ExpandableEnum.Type.MULTI
                          ? String(mobileLandingUrl).trim()
                          : undefined
                      )
                  )
                  .map(expandableAssetGroup => {
                    return setDiscountedPricePercentage(expandableAssetGroup)
                  })
              : undefined
          )
          .update('profileImage', v => (!v || v?.isEmpty() ? null : v))
          /**
           * 상위 url 과 동일 체크박스 체크 시,
           * pcLandingUrl, mobileLandingUrl 을 반응형으로 저장하고 pc와 모바일 값은 각각 삭제
           */
          .update('rspvLandingUrl', v => {
            const defaultUrl =
              s.get('mobileLandingUrl') || s.get('pcLandingUrl')

            if (isSameUrlChecked) {
              return defaultUrl?.trim()
            } else if (checkEmpty(v) || isEachChecked) {
              return null
            } else {
              return v?.trim()
            }
          })
          .update('pcLandingUrl', v =>
            checkEmpty(v) || isSameUrlChecked ? null : v?.trim()
          )
          .update('mobileLandingUrl', v =>
            checkEmpty(v) || isSameUrlChecked ? null : v?.trim()
          )
          /**
           * 비즈보드 유형이 아니거나 보장형 소재가 아닌 경우 landingInfo 를 제거한다.
           * 애드뷰 타입이 아닐 경우 adViewItem object 를 제거 한다.
           */
          .update('landingInfo', v => {
            if (!isRequiredLandingInfo || isUndefinedOrNull(v)) {
              return undefined
            }
            const { landingType, url } = v
            if (landingType !== LandingSchemeEnum.Type.AD_VIEW) {
              if (landingType === LandingSchemeEnum.Type.URL) {
                return v.set('url', url?.trim())
              }
              return v.remove()
            }
            return v
          })
          // shopping box
          .update('beginDateTime', () => {
            if (!isShoppingBox) {
              return undefined
            }
            const { beginDate, beginHour, beginMinute } = executiveTime
            const { noSchedule } = creativeForm
            return noSchedule ||
              isUndefinedOrNull(beginDate) ||
              isUndefinedOrNull(beginHour) ||
              isUndefinedOrNull(beginMinute)
              ? null
              : localDateTimeToString(
                  moment(beginDate).set({
                    hour: beginHour,
                    minute: beginMinute,
                  })
                )
          })
          .update('endDateTime', () => {
            if (!isShoppingBox) {
              return undefined
            }
            const { endDate, endHour, endMinute } = executiveTime
            const { noSchedule } = creativeForm
            return noSchedule ||
              isUndefinedOrNull(endDate) ||
              isUndefinedOrNull(endHour) ||
              isUndefinedOrNull(endMinute)
              ? null
              : localDateTimeToString(
                  moment(endDate).set({
                    hour: endHour,
                    minute: endMinute,
                  })
                )
          })
          .set(
            'opinionProof',
            fromJS(
              CreativeHelper.OpinionProof.toAPI(
                opinionProof,
                true,
                prevOpinionProof
              )
            )
          )
          .update('description', prev =>
            CreativeFormatEnum.Type.IMAGE_BOX ? prev : null
          )
          .set(
            'messageElement',
            CreativeFormatEnum.isMessage(creativeFormat)
              ? TalkChannelMessageHelper.toTrimForm(
                  creativeMessage.set('name', name)
                )
              : undefined
          )
          .update(creativeForm => Trim(creativeForm, CREATIVE_TRIM_KEY_PATH))
          .set('trackingUrl', s.get('trackingUrl')?.trim())
          .set('eventTrackerUrl', s.get('eventTrackerUrl')?.trim())
          .set('clickTrackerUrl', s.get('clickTrackerUrl')?.trim())
          .set('catalogSegment', isSegment || false)
          .set('parentCatalogId', isSegment ? originalCatalogId : null)
          .set('actionButtonText', s.get('actionButtonText')?.trim() || null)
      )
    )

    dispatch(initCreativeValidation()) // validation 전에 기존의 validation 결과 초기화

    // validation
    const validationResult = Validation(
      formData,
      getCreativeValidationKeyByFormat({ creativeFormat }),
      getCreativeValidationKeyPathByFormat({ creativeFormat, campaignType }),
      getCreativeValidatorByFormat({ creativeFormat, campaignType }),
      getState,
      setIsValidCreativeByKey,
      dispatch
    )

    if (!validationResult) {
      const {
        creativeV2: {
          common: {
            validationErrorKeys,
            validationExtra: {
              itemAssetGroupsValidationResults,
              assetGroupValidationResults,
              bizBoardBundleValidationResults,
            },
          },
        },
      } = getState()

      const getExtra = validationKey => {
        const invalidItemAssetIndex =
          validationKey === MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS
            ? itemAssetGroupsValidationResults?.findIndex(
                ({ isValid }) => !isValid
              )
            : validationKey ===
              CREATIVE_FORM_VALIDATION_KEY.CATALOG_ASSET_GROUPS
            ? assetGroupValidationResults?.findIndex(({ isValid }) => !isValid)
            : validationKey ===
              CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE
            ? bizBoardBundleValidationResults?.findIndex(
                ({ isValid }) => !isValid
              )
            : undefined

        return invalidItemAssetIndex >= 0 ? invalidItemAssetIndex : undefined
      }

      scrollByValidationKeys(
        validationErrorKeys,
        getCreativeValidationKeyByFormat({ creativeFormat }),
        getExtra
      )

      return
    }

    const { campaignId, adGroupId } = formData

    dispatch(showLoading())

    try {
      if (CreativeFormatEnum.isMessage(creativeFormat)) {
        // 메시지의 경우 스팸필터 적용
        const { messageElement } = formData
        const response = await api.message.validateSpamMessage(
          adAccountId,
          TalkChannelMessageHelper.toAPI(messageElement)
        )

        const { isFiltered = false } = response?.data || {}

        if (isFiltered) {
          const spamValidationResult =
            TalkChannelMessageHelper.Spam.convertSpamResult({
              spamResult: response.data,
              creativeFormat,
              messageForm: creativeMessage,
            })
          dispatch(setCreativeMessageSpamValidation(spamValidationResult))
          dispatch(showSpamFilterPopUp())
          dispatch(hideLoading())
          return
        }
      }

      await api.adCreative.modifyAdCreative(
        adAccountId,
        creativeId,
        CreativeHelper.toModifyRequestForm({ creative: formData, campaignType })
      )

      dispatch(RouterV2.push(DashboardRouter.Path.Prev(adAccountId)))
    } catch (e) {
      const { errorCode, message, detail } = e?.response?.data || {}

      dispatch(
        handleCreativeFormExceptionV2({
          errorCode,
          message,
          detail,
          adAccountId,
          campaignId,
          adGroupId,
        })
      )
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function clearCreativeModify() {
  return {
    type: CreativeModify.CLEAR,
  }
}

export function changeCreativeModifyShoppingExecutiveTimeByKey(key, value) {
  return {
    type: CreativeModify.CHANGE_SHOPPING_EXECUTIVE_TIME,
    key,
    value,
  }
}

export function setCreativeModifyShoppingExecutiveTime(adGroupSchedule) {
  return {
    type: CreativeModify.SET_SHOPPING_EXECUTIVE_TIME,
    adGroupSchedule,
  }
}
