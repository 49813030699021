import {
  hideLoading,
  LOADING_KEY,
  showLoading,
} from '../modules/common/mLoading'
import {
  changeAdGroupForm,
  changeAdGroupFormAtIndexByKey,
  initAdGroups,
  initAdGroupTargetingForm,
  initAdGroupValidationErrorKeys,
  initAllAdGroupForm,
  initialMessageSendingInfo,
  receiveAdGroupInfoV2,
  setModifyAdGroupForm,
  setOpenAdGroupIndex,
  updateAdGroupPopulation,
  updateAdGroupViewState,
  updateAdGroupViewStateAtIndexByKey,
  updateAdGroupViewStateV2,
  updatePlacementNetworkView,
} from '../modules/advertise/mAdGroupV2'
import { DemoLocationHelper } from '../utils/helper/helper-demoGraphic'
import {
  CATEGORY_TARGET_KEY,
  hasMyDataTargeting,
  LOCATION_TYPE,
} from '../utils/advertise/targeting'
import { preProcessingModifyAdGroupData } from '../utils/advertise/adGroup'
import { fromJS, List, Map, Range } from 'immutable'
import {
  getCategoryTargetingData,
  TARGETING_POPULATION_CATEGORY_TYPE,
} from '../modules/targeting/mTargeting'
import {
  clearAdGroupRecommendedBidAmount,
  getAdGroupConstraints,
} from '../modules/advertise/mAdConstraints'
import AdGroupScheduleEnum from '../enums/AdGroupScheduleEnum'
import {
  isTalkChannelReach,
  memoAdGroupBidAmountConstraint,
  memoAdGroupBidStrategyTypes,
  memoAdGroupPacingTypes,
  memoAdGroupPlacementsBySelectedDeviceTypes,
  memoAdGroupPricingTypes,
  updateAdGroupScheduleByCampaignType,
} from '../utils/advertise/campaignAdGroup'
import AdGroupTargetEnum from '../enums/AdGroupTargetEnum'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
  isUndefinedOrNull,
} from '../utils/regexUtils'
import axios from 'axios'
import CampaignTypeEnum from '../enums/CampaignTypeEnum'
import GoalEnum from '../enums/GoalEnum'
import {
  clearAllPopulation,
  getMultipleCustomTargetPopulationV2,
} from '../modules/targeting/mPopulation'
import ObjectiveTypeEnum from '../enums/ObjectiveTypeEnum'
import {
  handleAdGroupFormExceptionV2,
  showAdGroupPixelNotAvailableWarning,
  updateAdGroupTrackStatus,
} from '../modules/advertise/adGroupActions/aAdGroupCommonV2'
import moment from 'moment'
import { isKakaoOfficialChannelProfileId } from '../utils/app/services/kakaoPlusFriend'
import { showErrorMessage, showSuccessMessage } from '../utils/alertUtils'
import AdConstraintsHelper from '../utils/helper/helper-adConstraints'
import DeviceTypeEnum from '../enums/DeviceTypeEnum'
import AdGroupConstraints from '../utils/constraints/constraints-adGroup'
import ObjectiveDetailTypeEnum from '../enums/ObjectiveDetailTypeEnum'
import PixelAndSDKEventCodeEnum from '../enums/PixelAndSDKEventCodeEnum'
import TargetingInclusionEnum from '../enums/TargetingInclusionEnum'
import { updateCategorySelectedItems } from '../modules/targeting/targetingActions/aTargeting'
import DashboardRouter from '../components/DashboardV3/dashboardRouter'
import UserConfigEnum from '../enums/UserConfigEnum'
import bconApiV2 from '../modules-api/pixelAndSDK/bconApiV2'
import { AUDIENCE_TARGET } from '../components/Targeting/Audience/Common/audience'
import AudienceTypeEnum from '../enums/AudienceTypeEnum'
import DeviceOsTypeEnum from '../enums/DeviceOsTypeEnum'
import {
  closeAllPopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../modules/common/mPopup'
import { releasePlusFriendGroupTargetDialog } from '../components/AdvertiseV2/CampaignAndGroupV2/AdGroup/Common/AdGroupDialog'
import { getVat } from '../utils/utils'
import { clearDynamicCatalog } from '../modules/advertise/mDynamicCatalog'
import ContractApi from '../modules-api/advertise/contractApi'
import {
  setContractAvailableDays,
  setContractName,
  setContractProductInfo,
  setContractViewStateByKey,
} from '../modules/advertise/mContract'
import {
  changeCampaignViewStateByKeyV2,
  fetchEventFreeCash,
} from '../modules/advertise/mCampaignV2'
import BizBoardSubTypeEnum from '../enums/BizBoardSubTypeEnum'
import { RouterV2 } from '../stores/middleware/routerMiddleware'
import { SkanApi } from '../modules-api/skan/skanApi'
import BidStrategyTargetEnum from '../enums/BidStrategyTargetEnum'
import PlacementEnum from '../enums/PlacementEnum'

const DELETED_AD_GROUP_ERROR_CODE = 32026

function updatePopulation(adGroupIndex = -1) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, modifyAdGroup },
    } = getState()

    const { adGroupForm } =
      adGroupIndex > -1 ? creatingAdGroups.get(adGroupIndex) : modifyAdGroup

    dispatch(updateAdGroupPopulation(adGroupForm))
  }
}

function batchAdGroupOnMountV2(
  adAccountId,
  campaignId,
  adGroupIndex = undefined,
  adGroupId = undefined
) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.SETUP_AD_GROUP))

    const {
      adAccount: {
        adAccountInfo: { adAccountType, extras },
      },
      campaignV2: {
        campaignForm: {
          trackId,
          objective,
          campaignTypeGoal,
          campaignTypeGoal: { id: campaignTypeGoalId, campaignType, goal },
        },
        campaignViewState,
      },
      pixelV2: {
        common: { trackListByAdAccount },
      },
      contract: {
        viewState: { isNewContract },
      },
    } = getState()

    const {
      type: objectiveType,
      value: objectiveValue,
      detailType: objectiveDetailType,
    } = objective || {}

    dispatch(fetchEventFreeCash(adAccountId))

    const isSkanType = AdGroupConstraints.isSkanType({
      campaignType,
      goal,
      objectiveType,
      objectiveDetailType,
      extras,
    })
    dispatch(updateAdGroupViewStateV2('isSkanType', isSkanType))

    /**
     * GET AD GROUP CONSTRAINTS
     */
    try {
      await dispatch(
        getAdGroupConstraints(
          adAccountId,
          campaignTypeGoalId,
          objectiveType,
          objectiveDetailType
        )
      )
    } catch (e) {
      return
    }

    const {
      adConstraints: { adGroupConstraints },
    } = getState()

    const {
      devicePlacementConstraints,
      pricingTypeConstraints,
      adGroupDailyBudgetConstraint,
      retargetingFilterConstraints,
      bidConstraints,
      targetingTypes,
      sectionCategories,
      audienceType,
    } = adGroupConstraints || {}

    const isNew = !adGroupId || adGroupId === -1

    if (isNew) {
      const {
        adGroupV2: {
          initialAdGroup: { adGroupForm },
        },
      } = getState()

      try {
        const { bidStrategy, schedule, targeting } = adGroupForm

        await dispatch(getCategoryTargetingData(targeting, adAccountId))

        /**
         *  Initial custom target and demographic.
         *  targetingTypes is nullable
         */
        AdGroupTargetEnum.values().forEach(k => {
          const isAvailableTypes = targetingTypes?.includes(k) || false

          dispatch(
            updateAdGroupViewStateAtIndexByKey(
              adGroupIndex,
              ['availableTargetingTypes', k],
              isAvailableTypes
            )
          )
        })

        /**
         * Initial demographic location type
         */
        const isElectionType =
          CampaignTypeEnum.isElectionCampaignType(campaignType)
        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'selectedLocationType',
            isElectionType ? LOCATION_TYPE.DISTRICT : LOCATION_TYPE.ALL
          )
        )

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'audienceType',
            audienceType
          )
        )

        const isOnlyNationalLocation =
          AdGroupConstraints.Demographic.isNationalOnlyLocationRequired({
            campaignType,
          })

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            ['targeting', 'locations'],
            isOnlyNationalLocation
              ? DemoLocationHelper().createNational()
              : DemoLocationHelper().createGlobal()
          )
        )

        /**
         *  Initial device type
         */
        const allAvailableDeviceType =
          AdGroupConstraints.Device.isAllAvailableDeviceTypes({
            campaignType,
            goal,
            objectiveType,
            objectiveDetailType,
          })

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'allAvailableDeviceType',
            allAvailableDeviceType
          )
        )

        const availableDeviceTypes =
          AdGroupConstraints.Device.availableDeviceTypes({
            devicePlacementConstraints,
          })

        const initialDeviceTypes = allAvailableDeviceType
          ? []
          : availableDeviceTypes

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'deviceTypes',
            CampaignTypeEnum.isElectionCampaignType(campaignType)
              ? [DeviceOsTypeEnum.Type.PC]
              : initialDeviceTypes
          )
        )

        /**
         * Initial placement
         */
        const availablePlacements =
          AdConstraintsHelper.AdGroup.getPlacementsByDeviceType(
            adGroupConstraints,
            allAvailableDeviceType
              ? undefined
              : DeviceTypeEnum.getValueByDeviceOsTypes(availableDeviceTypes)
          )

        const isAllAvailablePlacement =
          AdGroupConstraints.Placement.isAllAvailablePlacement({
            campaignType,
            goal,
          })

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'placements',
            availablePlacements
          )
        )

        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'availablePlacements',
            availablePlacements
          )
        )

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'allAvailablePlacement',
            isAllAvailablePlacement
          )
        )

        const isChatTabOnly = AdGroupConstraints.Placement.isChatTabOnly({
          sectionCategories,
          devicePlacementConstraints,
        })

        if (isChatTabOnly) {
          dispatch(
            changeAdGroupFormAtIndexByKey(
              adGroupIndex,
              'sectionCategories',
              sectionCategories.map(sectionCategory =>
                sectionCategory.get('code')
              )
            )
          )
          dispatch(
            updateAdGroupViewStateAtIndexByKey(
              adGroupIndex,
              'isExpandedChatTabOnlyPlacement',
              true
            )
          )
        }

        /**
         * Init talkBizBoardReserved totalBudget
         */
        if (
          CampaignTypeEnum.isContractCampaignType(campaignType) &&
          campaignType !== CampaignTypeEnum.Type.ELECTION_2024_04
        ) {
          if (!isNewContract) {
            const { contractInfo } = campaignViewState
            const { contractProductItems } = contractInfo
            const [item] = contractProductItems
            const { id, totalAmount, subType } = item

            dispatch(
              changeAdGroupFormAtIndexByKey(
                adGroupIndex,
                'totalBudget',
                totalAmount
              )
            )

            dispatch(
              updateAdGroupViewStateAtIndexByKey(
                adGroupIndex,
                'totalBudgetVAT',
                Number(getVat(totalAmount))
              )
            )

            dispatch(
              changeAdGroupFormAtIndexByKey(
                adGroupIndex,
                'contractProductItemId',
                id
              )
            )

            dispatch(
              setContractViewStateByKey(
                'subType',
                campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED
                  ? subType
                  : campaignType ===
                    CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
                  ? BizBoardSubTypeEnum.Type.CUSTOM
                  : BizBoardSubTypeEnum.Type.NORMAL
              )
            )
            if (
              campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
            ) {
              const {
                campaignV2: {
                  campaignViewState: { contractInfo },
                },
              } = getState()

              const { beginDateTime, endDateTime, name } = contractInfo
              const [item] = contractProductItems
              const { totalAmount } = item

              dispatch(
                changeAdGroupFormAtIndexByKey(
                  adGroupIndex,
                  'totalBudget',
                  totalAmount
                )
              )

              dispatch(
                updateAdGroupViewStateAtIndexByKey(
                  adGroupIndex,
                  'totalBudgetVAT',
                  Number(getVat(totalAmount))
                )
              )

              dispatch(
                setContractViewStateByKey(
                  'selectedDate',
                  moment(beginDateTime).format(moment.HTML5_FMT.DATE)
                )
              )
              dispatch(
                setContractViewStateByKey(
                  'beginDateTime',
                  moment(beginDateTime).format(moment.HTML5_FMT.TIME_SECONDS)
                )
              )
              dispatch(
                setContractViewStateByKey(
                  'endDateTime',
                  moment(endDateTime).format(moment.HTML5_FMT.TIME)
                )
              )
              dispatch(setContractName(name))
            }
          } else {
            const [
              {
                priceConstraints: [{ contractProductKey: productKey }],
              },
            ] = pricingTypeConstraints

            const {
              data: productInfo,
              data: { id },
            } = await ContractApi.fetchProductInfo(adAccountId, productKey)

            const { data } = await ContractApi.fetchAvailableDays(
              adAccountId,
              id
            )
            dispatch(setContractProductInfo(productInfo))
            dispatch(setContractAvailableDays(data))
            if (
              [
                CampaignTypeEnum.Type.PC_TALK_BOTTOM,
                CampaignTypeEnum.Type.PC_TALK_RICH_POP,
                CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
                CampaignTypeEnum.Type.PROFILE_FULL_VIEW,
              ].includes(campaignType)
            ) {
              dispatch(
                setContractViewStateByKey(
                  'subType',
                  BizBoardSubTypeEnum.Type.NORMAL
                )
              )
            }
          }
        }

        if (campaignType === CampaignTypeEnum.Type.ELECTION_2024_04) {
          const { contractInfo } = campaignViewState
          const { contractProductItems } = contractInfo
          const [item] = contractProductItems
          const { id, totalAmount } = item

          dispatch(
            changeAdGroupFormAtIndexByKey(
              adGroupIndex,
              'totalBudget',
              totalAmount
            )
          )

          dispatch(
            updateAdGroupViewStateAtIndexByKey(
              adGroupIndex,
              'totalBudgetVAT',
              Number(getVat(totalAmount))
            )
          )

          dispatch(
            changeAdGroupFormAtIndexByKey(
              adGroupIndex,
              'contractProductItemId',
              id
            )
          )
        }

        /**
         * Initial pricing type
         */
        const pricingTypes = memoAdGroupPricingTypes(pricingTypeConstraints)
        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'pricingType',
            pricingTypes.first()
          )
        )

        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'pricingTypes',
            pricingTypes
          )
        )

        /**
         * Initial daily budget view state (Unavailable use if goal type is reach)
         */
        const { max, min } = adGroupDailyBudgetConstraint
        const isAvailableDailyBudget = checkNoneEmpty(max, min) // 사용불가일때 null 로 들어옴

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'isAvailableDailyBudget',
            isAvailableDailyBudget
          )
        )

        /**
         * Initial bid strategy view state (AUTOBID, MANUAL), initial value
         */
        const bidStrategyTypes = memoAdGroupBidStrategyTypes(bidConstraints)
        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'bidStrategyTypes',
            bidStrategyTypes
          )
        )

        const bidStrategyType =
          AdGroupConstraints.BidStrategy.getAvailableDefaultBidStrategyType({
            campaignType,
            goal,
            objectiveType,
          })

        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'bidStrategy',
            bidStrategyType
          )
        )

        /**
         *  AutoBidPurpose ViewState, initial value
         */
        const availableAutoBidPurposeTypes =
          AdGroupConstraints.BidStrategy.getAvailableAutoBidPurposeType({
            campaignType,
            goal,
            objectiveDetailType,
          })
        const autoBidPurpose = availableAutoBidPurposeTypes.length
          ? availableAutoBidPurposeTypes[0]
          : null

        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'availableAutoBidPurposeTypes',
            availableAutoBidPurposeTypes
          )
        )
        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'autoBidPurpose',
            autoBidPurpose
          )
        )

        /**
         * Initial bidAmount (다음쇼핑이 아닌경우에만 초기화)
         */
        if (campaignType !== CampaignTypeEnum.Type.DAUM_SHOPPING) {
          /**
           * 카카오톡 채널 * 도달 && 공식 카카오 채널인 경우
           */
          if (campaignType === CampaignTypeEnum.Type.TALK_CHANNEL) {
            dispatch(
              changeAdGroupFormAtIndexByKey(
                adGroupIndex,
                'bidAmount',
                isKakaoOfficialChannelProfileId(Number(objectiveValue))
                  ? AdGroupConstraints.KakaoOfficialChannel.BID_AMOUNT_MIN
                  : 0
              )
            )
          } else {
            const { guideBidAmount } = memoAdGroupBidAmountConstraint(
              pricingTypeConstraints,
              pricingTypes.first()
            )

            dispatch(
              changeAdGroupFormAtIndexByKey(
                adGroupIndex,
                'bidAmount',
                guideBidAmount
              )
            )
          }
        }

        /**
         * Initial pacing type
         */
        const pacing = AdGroupConstraints.Pacing.getAvailableDefaultPacingType({
          campaignType,
          goal,
          objectiveType,
        })

        dispatch(changeAdGroupFormAtIndexByKey(adGroupIndex, 'pacing', pacing))

        const pacingTypes = memoAdGroupPacingTypes(bidConstraints, bidStrategy)

        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'pacingTypes',
            pacingTypes
          )
        )

        /**
         * Initial retargetingFilters
         */
        if (campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG) {
          const retargetingFilters = retargetingFilterConstraints?.reduce(
            (prev, { eventCode, unit, inclusionType, term: { guide } }) =>
              prev.concat({
                trackId,
                eventCode,
                unit,
                term: guide,
                inclusionType,
              }),
            []
          )

          dispatch(
            changeAdGroupFormAtIndexByKey(
              adGroupIndex,
              'retargetingFilters',
              retargetingFilters
            )
          )
        }

        /**
         * Election 2022 deal default setting
         */
        const deal = pricingTypeConstraints
          .first()
          .get('priceConstraints')
          .first()
          .get('deal')

        if (CampaignTypeEnum.isElectionCampaignType(campaignType)) {
          dispatch(changeAdGroupFormAtIndexByKey(adGroupIndex, 'deal', deal))
        }

        /**
         * Initial schedule
         */
        const newSchedule = schedule.withMutations(scheduleState => {
          AdGroupScheduleEnum.Day.values().forEach(dayTime =>
            scheduleState.set(
              dayTime,
              Range()
                .take(24)
                .map(() => '1')
            )
          )

          const { contractInfo } = campaignViewState

          return updateAdGroupScheduleByCampaignType({
            campaignType,
            scheduleState,
            contractInfo,
            deal,
            isNewContract,
          })
        })

        dispatch(
          changeAdGroupFormAtIndexByKey(adGroupIndex, 'schedule', newSchedule)
        )

        /**
         * 캘린더 종료일자 미설정
         */
        const isEndDateUnlimitedRequired =
          AdGroupConstraints.Schedule.isEndDateUnlimitedRequired({
            campaignType,
            goal,
          })

        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            adGroupIndex,
            'isEndDateUnlimited',
            isEndDateUnlimitedRequired
          )
        )

        /**
         * Initial AdGroup Name
         */
        dispatch(
          changeAdGroupFormAtIndexByKey(
            adGroupIndex,
            'name',
            `${CampaignTypeEnum.getName(campaignType)}_${GoalEnum.getName(
              goal
            )}_${moment().format('YYYYMMDDHHmm')}`
          )
        )

        /**
         * Update PlusFriendTargetings.
         */
        if (
          goal === GoalEnum.Type.CONVERSION &&
          objectiveType === ObjectiveTypeEnum.Type.TALK_CHANNEL
        ) {
          const {
            targeting: { plusFriendTargetings },
          } = adGroupForm

          const addExcludedPlusFriendTargetByObjective = () => {
            dispatch(
              changeAdGroupFormAtIndexByKey(
                adGroupIndex,
                ['targeting', 'plusFriendTargetings'],
                plusFriendTargetings.unshift(
                  Map({
                    profileId: objective.get('value'),
                    profileName: objective.get('description'),
                    name: objective.get('description'),
                    inclusionType: TargetingInclusionEnum.Type.EXCLUDE,
                  })
                )
              )
            )
          }

          if (campaignId > 0) {
            /**
             * 새 광고그룹 만들기로 시작시 캠페인에 저장된 플친 정보와 새로 조회한 플친 목록이 다를수 있으므로 단건 조회하여 세팅.
             */
            try {
              const response =
                await api.campaign.fetchPlusFriendProfilesInCampaign(
                  adAccountId,
                  campaignId
                )

              const plusFriendInfo = Map(response?.data || {})

              dispatch(
                changeAdGroupFormAtIndexByKey(
                  adGroupIndex,
                  ['targeting', 'plusFriendTargetings'],
                  plusFriendTargetings.unshift(
                    Map({
                      profileId: plusFriendInfo.get('id'),
                      profileName: plusFriendInfo.get('name'),
                      name: plusFriendInfo.get('name'),
                      inclusionType: TargetingInclusionEnum.Type.EXCLUDE,
                    })
                  )
                )
              )
            } catch (e) {
              /**
               * 플친 단건조회 실패시 캠페인 objective 값으로 임의 세팅.
               */
              addExcludedPlusFriendTargetByObjective()
            }
          } else {
            /**
             * 캠페인 만들기부터 시작할 경우 campaignId 값이 없으므로 단건 조회 하지 않고 objective 값으로 세팅.
             * 이 경우는 캠페인부터 새로 만들고 들어왔기 때문에 굳이 단건 조회 필요 없음.
             */
            addExcludedPlusFriendTargetByObjective()
          }
        }

        /**
         * 상세 목표 대상 : 구매, 앱설치 일때
         * 픽셀 & SDK' 타게팅 > 앱설치 이벤트 '제외' 고정
         */
        if (
          goal === GoalEnum.Type.CONVERSION &&
          objectiveType === ObjectiveTypeEnum.Type.PIXEL_AND_SDK &&
          objectiveDetailType === ObjectiveDetailTypeEnum.Type.APP_INSTALL
        ) {
          try {
            const trackNameResponse = await api.bconV2.fetchDetailBasicInfo(
              adAccountId,
              objectiveValue
            )
            const trackName = trackNameResponse?.data?.body?.name || ''
            const nextTrackerTargetings = [
              {
                eventCode: PixelAndSDKEventCodeEnum.Type.AppInstall,
                inclusionType: TargetingInclusionEnum.Type.EXCLUDE,
                trackId: objectiveValue,
                trackRuleId: '*',
                trackRuleName: `${trackName} > ${PixelAndSDKEventCodeEnum.Name.AppInstall}`,
                term: 180,
              },
            ]

            dispatch(
              changeAdGroupFormAtIndexByKey(
                adGroupIndex,
                ['targeting', 'trackerTargetings'],
                nextTrackerTargetings
              )
            )
          } catch (e) {
            console.error(e.message)
          }
        }

        dispatch(hideLoading(LOADING_KEY.SETUP_AD_GROUP))
        dispatch(updatePopulation(adGroupIndex))
      } catch (e) {
        console.error(e.message)
        dispatch(hideLoading(LOADING_KEY.SETUP_AD_GROUP))
      }
    } else {
      try {
        const response = await api.adGroup.fetchAdGroupInfoById(
          adAccountId,
          adGroupId
        )

        const adGroup = preProcessingModifyAdGroupData(response?.data || {})
        const { useEventFreeCash } = adGroup || {}

        if (!useEventFreeCash) {
          dispatch(
            changeCampaignViewStateByKeyV2(
              ['freeCash', 'useEventFreeCash'],
              false
            )
          )
        } else {
          dispatch(
            changeCampaignViewStateByKeyV2(
              ['freeCash', 'useEventFreeCash'],
              true
            )
          )
        }

        // hierarchy validation
        if (Number(campaignId) !== Number(adGroup.campaign.id)) {
          showErrorMessage('비정상적인 접근입니다.')
          dispatch(
            RouterV2.replace(DashboardRouter.Path.AdGroup({ adAccountId }))
          )
        } else if (adGroup.userConfig === UserConfigEnum.Type.DEL) {
          showErrorMessage('삭제되었거나 지원 종료된 광고입니다.')
          dispatch(
            RouterV2.replace(DashboardRouter.Path.AdGroup({ adAccountId }))
          )
        } else {
          /**
           *  광고그룹 수정 진입시, 트랙 상태 체크하여 업데이트
           */
          const { trackerTargetings } = adGroup.targeting
          const hasTrackerTargetings = trackerTargetings.length > 0

          if (
            hasTrackerTargetings &&
            campaignType !== CampaignTypeEnum.Type.PRODUCT_CATALOG
          ) {
            try {
              const trackInfoRequests = trackerTargetings.map(v =>
                bconApiV2.fetchDetailBasicInfo(adAccountId, v.trackId)
              )

              const responses = await axios.all(trackInfoRequests)

              dispatch(showAdGroupPixelNotAvailableWarning(responses))
              updateAdGroupTrackStatus({ responses, trackerTargetings })
            } catch (e) {
              console.error(e.message)
            }
          }

          await dispatch(
            getCategoryTargetingData(fromJS(adGroup.targeting), adAccountId)
          )

          /**
           * SkAdNework일 경우 앱ID 설정
           */
          const hasSkanType = checkNotEmpty(adGroup.skanProtocolInfo)

          if (isSkanType && hasSkanType) {
            const { appId: bconAppId, sourceIdentifierCampaignDigits } =
              adGroup.skanProtocolInfo

            dispatch(
              updateAdGroupViewStateV2(
                'isSkanNotSet',
                !checkNotEmpty(bconAppId)
              )
            )
            dispatch(
              updateAdGroupViewStateV2(
                'isSkanActive',
                !checkNotEmpty(bconAppId)
              )
            )

            const appIdResponse = await SkanApi.fetchLinkedAppIdInfo(
              adAccountId,
              bconAppId,
              sourceIdentifierCampaignDigits
            )

            const { appName, trackerId, trackerName } = appIdResponse.data.body

            adGroup.skanProtocolInfo.appName = appName
            adGroup.skanProtocolInfo.bconTrackerId = trackerId
            adGroup.skanProtocolInfo.bconTrackerName = trackerName
          }

          const bidStrategyTypes = memoAdGroupBidStrategyTypes(bidConstraints)
          const { max, min } = adGroupDailyBudgetConstraint
          const pricingTypes = memoAdGroupPricingTypes(pricingTypeConstraints)

          const pacingTypes = memoAdGroupPacingTypes(
            bidConstraints,
            adGroup.bidStrategy
          )

          const newAvailablePlacements =
            memoAdGroupPlacementsBySelectedDeviceTypes(
              adGroup.deviceTypes,
              devicePlacementConstraints
            )

          dispatch(updateAdGroupViewState('pacingTypes', pacingTypes))
          dispatch(updateAdGroupViewState('pricingTypes', pricingTypes))
          dispatch(
            updateAdGroupViewState(
              'availablePlacements',
              hasSkanType
                ? PlacementEnum.SkanPlacementValues()
                : newAvailablePlacements
            )
          )
          dispatch(updateAdGroupViewState('bidStrategyTypes', bidStrategyTypes))
          dispatch(
            updateAdGroupViewState(
              'isDailyBudgetUnlimited',
              !checkNotEmpty(adGroup.dailyBudget)
            )
          )

          // 쇼핑 고지사항 동의 여부
          dispatch(
            updateAdGroupViewState('depositCancellationFeeAgreement', true)
          )

          // 내 데이터
          dispatch(
            updateAdGroupViewState(
              'isExpandedMyDataTargeting',
              hasMyDataTargeting(adGroup.targeting)
            )
          )

          // 관심사, 업종 타겟
          dispatch(
            updateAdGroupViewState(
              'isExpandedCategoryTargeting',
              [
                adGroup.targeting.ufoInterests,
                adGroup.targeting.ufoBusinessTypes,
              ].some(v => v?.length > 0)
            )
          )

          // 삭제된 카테고리 제외 처리
          const {
            targeting: {
              categoryTargeting: { idLabelMap },
            },
          } = getState()

          adGroup.targeting.ufoBusinessTypes =
            adGroup.targeting.ufoBusinessTypes.filter(v =>
              idLabelMap.get(CATEGORY_TARGET_KEY.business).get(v)
            )

          adGroup.targeting.ufoInterests =
            adGroup.targeting.ufoInterests.filter(v =>
              idLabelMap.get(CATEGORY_TARGET_KEY.interests).get(v)
            )

          dispatch(
            updateAdGroupViewState(
              'isExpandedChatTabOnlyPlacement',
              sectionCategories.some(({ code }) =>
                adGroup.sectionCategories?.includes(code)
              )
            )
          )

          // 카카오 데이터 타겟 설정 정보 view 에 반영하기.
          dispatch(updateCategorySelectedItems(adGroup.targeting))

          // 키워드 타겟
          dispatch(
            updateAdGroupViewState(
              'isExpandedKeywordTargeting',
              adGroup.targeting.keywords?.length > 0
            )
          )

          // 게재지면에 network 포함 여부
          dispatch(updatePlacementNetworkView(adGroup))

          // 심야 타겟
          if (adGroup.schedule) {
            dispatch(
              updateAdGroupViewState(
                'isExpandedLateNightTargeting',
                adGroup.schedule.lateNight
              )
            )
            // 기존 심야 타겟 설정되지 않았을 경우에만 수정 가능함
            dispatch(
              updateAdGroupViewState(
                'modifiableLateNight',
                !adGroup.schedule.lateNight
              )
            )

            // 톡채널+도달 수정시 필요
            dispatch(
              updateAdGroupViewState(
                'talkChannelBeginDate',
                adGroup.schedule.beginDate
              )
            )
            dispatch(
              updateAdGroupViewState(
                'talkChannelBeginTime',
                adGroup.schedule.beginTime
              )
            )
          }

          if (goal === GoalEnum.Type.REACH) {
            if (campaignType === CampaignTypeEnum.Type.TALK_CHANNEL) {
              const contractCount =
                adGroup.messageSendingInfo?.contractCount || 0

              if (contractCount && adGroup.bidAmount) {
                const { priceConstraints } =
                  pricingTypeConstraints?.first() || {}

                const bidAmount =
                  isUndefinedOrNull(adGroup.smartMessage) &&
                  (adGroup.creativeOptimization || adGroup.dynamicTarget)
                    ? priceConstraints?.size > 0
                      ? priceConstraints // creativeOptimization, dynamicTarget이 선택된 기존 광고인 경우 스마트메시지로 넘어가며 가격 20원으로 변경되어야함
                          .find(v => !v.get('nonTarget'))
                          .get('price')
                      : 20
                    : adGroup.bidAmount

                try {
                  const response = await api.adGroup.fetchCpmsEstimateAmount(
                    // 메시지에서만 사용되는 빌링 api
                    adAccountId,
                    bidAmount,
                    contractCount,
                    adAccountType
                  )
                  const { amount: amountTo } = response.data || {}
                  const { amount = 0 } = amountTo

                  dispatch(updateAdGroupViewState('totalBudgetVAT', amount))
                } catch (e) {
                  console.error(e.message)
                }
              } else {
                dispatch(updateAdGroupViewState('totalBudgetVAT', 0))
              }
            } else if (
              campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
              campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
              campaignType === CampaignTypeEnum.Type.KAKAO_TV
            ) {
              if (
                campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED
              ) {
                const { contractInfo } = campaignViewState
                const { contractProductItems } = contractInfo
                const [item] = contractProductItems
                const { subType } = item
                dispatch(setContractViewStateByKey('subType', subType))
              }
              dispatch(
                updateAdGroupViewState(
                  'totalBudgetVAT',
                  Number(adGroup.totalBudgetWithVAT)
                )
              )
            }
          }

          if (CampaignTypeEnum.isContractCampaignType(campaignType)) {
            // 비즈보드 CPT, 리치팝, 포커스보드
            dispatch(
              updateAdGroupViewState(
                'isTalkBizBoardReservedBudgetChecked',
                true // 수정시 해제 불가
              )
            )
          }

          /**
           * 비즈보드 채팅탭 CPT
           */
          if (
            campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
          ) {
            const {
              campaignV2: {
                campaignViewState: {
                  contractInfo: { beginDateTime, endDateTime },
                },
              },
            } = getState()

            dispatch(
              setContractViewStateByKey(
                'selectedDate',
                moment(beginDateTime).format(moment.HTML5_FMT.DATE)
              )
            )
            dispatch(
              setContractViewStateByKey(
                'subType',
                BizBoardSubTypeEnum.Type.CUSTOM
              )
            )
            dispatch(
              setContractViewStateByKey(
                'beginDateTime',
                moment(beginDateTime).format(moment.HTML5_FMT.TIME_SECONDS)
              )
            )
            dispatch(
              setContractViewStateByKey(
                'endDateTime',
                moment(endDateTime).format(moment.HTML5_FMT.TIME_SECONDS)
              )
            )
          }

          if (
            campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
            campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
            campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
          ) {
            dispatch(
              updateAdGroupViewState(
                'isFreeCashReservedBudgetChecked',
                true // 수정시 해제 불가
              )
            )
          }

          if (campaignType === CampaignTypeEnum.Type.ELECTION_2024_04) {
            // 2024 총선
            dispatch(
              updateAdGroupViewState(
                'isElectionBudgetChecked',
                true // 수정시 해제 불가
              )
            )
          }

          // 성인 타겟
          dispatch(
            updateAdGroupViewState('isExpandedAdultTargeting', adGroup.adult)
          )

          // 연령 인증 메시지
          dispatch(
            updateAdGroupViewState(
              'isExpandedAgeVerification',
              adGroup.messageSendingInfo?.ageVerification ?? false
            )
          )

          // 디바이스 - wifi에서만 노출
          dispatch(
            updateAdGroupViewState(
              'isExpandedUseWifiOnlyTargeting',
              adGroup.useWifiOnly
            )
          )

          // 기존 성인 타겟 설정되지 않았을 경우에만 수정 가능함
          dispatch(updateAdGroupViewState('modifiableAdult', !adGroup.adult))

          // 소재 최적화
          dispatch(
            updateAdGroupViewState(
              'isExpandedCreativeOptimization',
              adGroup.creativeOptimization
            )
          )

          dispatch(
            updateAdGroupViewState(
              'isConfirmedTotalBudget',
              adGroup.totalBudget > 0
            )
          )

          // 맞춤타겟
          AdGroupTargetEnum.values().forEach(k => {
            const isAvailableTypes = targetingTypes?.includes(k) || false

            dispatch(
              updateAdGroupViewState(
                ['availableTargetingTypes', k],
                isAvailableTypes
              )
            )
          })

          if (
            isUndefinedOrNull(adGroup.smartMessage) &&
            (adGroup.creativeOptimization || adGroup.dynamicTarget)
          ) {
            showSuccessMessage(
              '실시간 타겟과 소재최적화 기능은 스마트메시지로 통합되었습니다. 집행전략설정 > 집행방식의 스마트메시지 옵션에서 확인할 수 있습니다.'
            )
          }

          const availableAutoBidPurposeTypes =
            AdGroupConstraints.BidStrategy.getAvailableAutoBidPurposeType({
              campaignType,
              goal,
              objectiveDetailType,
            })

          dispatch(
            updateAdGroupViewState(
              'availableAutoBidPurposeTypes',
              availableAutoBidPurposeTypes
            )
          )

          // 입찰 전략
          const { bidStrategyTarget } = adGroup
          if (checkNoneEmpty(bidStrategyTarget?.type)) {
            const autoBidPurpose =
              AdGroupConstraints.BidStrategy.getAutoBidPurposeTypeByBidStrategyTarget(
                { bidStrategyTarget: bidStrategyTarget.type }
              )
            dispatch(updateAdGroupViewState('autoBidPurpose', autoBidPurpose))
          } else {
            dispatch(
              updateAdGroupViewState(
                'autoBidPurpose',
                availableAutoBidPurposeTypes[0]
              )
            )
          }

          /**
           *  Set adGroupForm
           */
          const newAdGroupForm = fromJS(adGroup).withMutations(s =>
            s
              .set('campaign', {
                campaignTypeGoal,
                objective,
              })
              .set('isAvailableDailyBudget', checkNoneEmpty(max, min))
              .update('bidAmount', bidAmount =>
                isTalkChannelReach(campaignType, goal) &&
                isKakaoOfficialChannelProfileId(Number(objectiveValue))
                  ? adGroup.messageSendingInfo?.price
                  : bidAmount
              )

              /**
               * 마이그레이션 이전 광고그룹일 경우 디폴트 초기값으로 세팅해줌.
               *  - https://jira.daumkakao.com/browse/KAMOQA-9226
               */
              .update('messageSendingInfo', messageSendingInfo =>
                checkEmpty(messageSendingInfo)
                  ? initialMessageSendingInfo
                  : messageSendingInfo
              )
              /**
               * 이전 광고그룹(소재최적화 또는 실시간 타겟)인 경우
               */
              .update(
                'smartMessage',
                smartMessage =>
                  isTalkChannelReach(campaignType, goal) &&
                  (smartMessage ??
                    (adGroup.creativeOptimization || adGroup.dynamicTarget))
              )
              .set('audienceType', audienceType)
              .setIn(
                ['targeting', 'audienceTarget'],
                s.getIn(['targeting', 'audienceType']) ===
                  AudienceTypeEnum.Type.NORMAL
                  ? AUDIENCE_TARGET.AD_GROUP
                  : AUDIENCE_TARGET.AUDIENCE
              )
              .updateIn(['targeting', 'cohortTargetings'], cohortTargetings =>
                cohortTargetings.filter(
                  item => item.get('audienceType') === audienceType
                )
              )
              .updateIn(['targeting', 'trackerTargetings'], trackerTargetings =>
                campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
                  ? List()
                  : trackerTargetings
              )
              .updateIn(
                ['targeting', 'customerFileTargetings'],
                customerFileTargetings =>
                  campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
                    ? List()
                    : customerFileTargetings
              )
          )

          dispatch(receiveAdGroupInfoV2(newAdGroupForm))
          dispatch(
            setModifyAdGroupForm(
              newAdGroupForm.update('bidStrategyTarget', bidStrategyTarget =>
                bidStrategyTarget?.get('type') ===
                BidStrategyTargetEnum.Type.MAXIMIZE_CONVERSION_VALUE
                  ? null
                  : bidStrategyTarget
              )
            )
          )

          /**
           * 마이그레이션 이후 메시지 광고그룹에 디스플레이 오디언스가 적용된 경우 오디언스 초기화 (KAMOQA-16769)
           */
          if (
            audienceType === AudienceTypeEnum.Type.MESSAGE &&
            newAdGroupForm.getIn(['targeting', 'audienceType']) ===
              AudienceTypeEnum.Type.DISPLAY
          ) {
            dispatch(initAdGroupTargetingForm())
          }

          dispatch(hideLoading(LOADING_KEY.SETUP_AD_GROUP))
          dispatch(updatePopulation())

          const pfGroupValueSet =
            adGroup.targeting.plusFriendGroupTargetings.map(
              ({ plusFriendProfileId, groupKey }) => {
                return {
                  plusFriendProfileId,
                  targetingValue: groupKey,
                  inclusionType: TargetingInclusionEnum.Type.INCLUDE,
                  targetingCategory:
                    TARGETING_POPULATION_CATEGORY_TYPE.PLUS_FRIEND_GROUP_FILE,
                }
              }
            )

          const isPopulationRequired = AdGroupConstraints.isPopulationRequired({
            campaignType,
            goal,
          })

          if (isPopulationRequired) {
            dispatch(
              getMultipleCustomTargetPopulationV2(
                adAccountId,
                pfGroupValueSet,
                _customTargetPopulations => {
                  const filteredPfGroupPopulations =
                    _customTargetPopulations.filter(
                      ({ targetingCategory }) =>
                        targetingCategory ===
                        TARGETING_POPULATION_CATEGORY_TYPE.PLUS_FRIEND_GROUP_FILE
                    )

                  if (filteredPfGroupPopulations.length > 0) {
                    const isReadyTargetExists = filteredPfGroupPopulations.some(
                      ({ populationScore }) => populationScore === 0
                    )

                    if (isReadyTargetExists) {
                      const unavailablePfGroupPopulations =
                        filteredPfGroupPopulations.filter(
                          ({ populationScore }) => populationScore === 0
                        )

                      const unavailablePfGroupTargetings =
                        unavailablePfGroupPopulations.map(v =>
                          adGroup.targeting.plusFriendGroupTargetings.find(
                            ({ groupKey }) => groupKey === v.targetingValue
                          )
                        )

                      dispatch(
                        openPopupByProxy(
                          POPUP_KEY.SIMPLE_POPUP,
                          releasePlusFriendGroupTargetDialog(() => {
                            const availablePfGroupPopulations =
                              filteredPfGroupPopulations.filter(
                                ({ populationScore }) => populationScore > 0
                              )

                            const availablePfGroupTargetings =
                              availablePfGroupPopulations.map(v =>
                                adGroup.targeting.plusFriendGroupTargetings.find(
                                  ({ groupKey }) =>
                                    groupKey === v.targetingValue
                                )
                              )

                            dispatch(
                              changeAdGroupForm(
                                ['targeting', 'plusFriendGroupTargetings'],
                                availablePfGroupTargetings
                              )
                            )
                            dispatch(closeAllPopup())
                          }, unavailablePfGroupTargetings)
                        )
                      )
                    }
                  }
                }
              )
            )
          }
        }
      } catch (e) {
        dispatch(handleAdGroupFormExceptionV2(e))
        dispatch(hideLoading(LOADING_KEY.SETUP_AD_GROUP))

        const { errorCode } = e?.response?.data || {}
        if (Number(errorCode) === DELETED_AD_GROUP_ERROR_CODE) {
          dispatch(
            RouterV2.replace(
              DashboardRouter.Path.AdGroup({
                adAccountId,
                summaryId: adGroupId,
              })
            )
          )
        }
      }
    }
  }
}

function batchAdGroupOnUnmountV2() {
  return dispatch => {
    dispatch(initAdGroups())
    dispatch(initAllAdGroupForm())
    dispatch(clearAllPopulation())
    dispatch(setOpenAdGroupIndex(-1))
    dispatch(initAdGroupValidationErrorKeys())
    dispatch(clearDynamicCatalog())
    dispatch(clearAdGroupRecommendedBidAmount())
  }
}

export { batchAdGroupOnMountV2, batchAdGroupOnUnmountV2 }
