import {
  CREATIVE_CREATE_PROFILE_KEY,
  CREATIVE_IMAGE_TYPE,
} from './creativeImage'
import CreativeCategoryEnum from '../../enums/CreativeCategoryEnum'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import BizBoardSubTypeEnum from '../../enums/BizBoardSubTypeEnum'
import { TalkChannelMessageHelper } from '../helper/helper-talkChannelMessage'
import {
  checkEmpty,
  checkUrlHasWhiteSpace,
  checkUrlWithDomain,
  hasNotAllowedSpecialCharacter,
} from '../regexUtils'
import PostMediaTypeEnum from '../../enums/PostMediaTypeEnum'

/**
 * 소재 미리보기, 설정 완료 리스트, 소재 불러오기,
 * - 소재 이미지, 프로필 이미지
 */
const getRepresentativeImageUrl = ({
  creative,
  imageType = CREATIVE_IMAGE_TYPE.PROMOTIONAL,
  campaignType,
}) => {
  if (!creative) {
    return null
  }

  const { id, creativeFormat, boardType } = creative
  const isMotionBoard = BizBoardSubTypeEnum.isMotionBoard(boardType)
  const isModify = id > 0
  const creativeCategory =
    CreativeCategoryEnum.getValueByCreativeFormat(creativeFormat)

  switch (creativeCategory) {
    case CreativeCategoryEnum.Type.IMAGE: {
      if (creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL) {
        if (isModify) {
          const image =
            imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL
              ? creative.get('assetGroups')?.first()?.get('image')
              : creative.get('profileImage')
          return image?.get('url')
        } else {
          if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
            return creative
              .get('assetGroups')
              ?.first()
              ?.get('image')
              ?.get('url')
          } else {
            return (
              creative
                .get('uploadedImages')
                ?.get(CREATIVE_CREATE_PROFILE_KEY)
                ?.get('url') || ''
            )
          }
        }
      } else {
        if (isModify) {
          // 모션 보드의 경우 배너 이미지가 아닌 모션요소의 썸네일 이미지가 나타나야함
          if (isMotionBoard) {
            const {
              boardAssetGroup: { autoThumbnail, uploadThumbnail },
            } = creative
            return uploadThumbnail?.get('url') ?? autoThumbnail?.get('url')
          }
          const image =
            imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL
              ? creative.get('image')
              : creative.get('profileImage')
          return image?.get('url')
        } else {
          if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
            const isBizBoardBanner =
              campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
              campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
              campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT

            if (isMotionBoard) {
              const {
                boardAssetGroup: { autoThumbnail, uploadThumbnail },
              } = creative
              return uploadThumbnail?.get('url') ?? autoThumbnail?.get('url')
            }

            const { uploadedMultiImages, bizBoardBundles } = creative

            return isBizBoardBanner
              ? bizBoardBundles?.first()?.getIn(['image', 'url'])
              : uploadedMultiImages?.first()?.first()?.get('url') // 첫번째 그룹의 첫번째 하위 항목
          } else {
            const { uploadedImages } = creative
            return uploadedImages?.get(imageType)?.get('url')
          }
        }
      }
    }

    case CreativeCategoryEnum.Type.VIDEO: {
      if (isModify) {
        if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
          const { image, image2 } = creative
          return image2?.get('url') || image?.get('url')
        } else {
          const { profileImage } = creative
          return profileImage?.get('url')
        }
      } else {
        const { uploadedImages, autoThumbnail, uploadThumbnail } = creative

        if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
          return uploadThumbnail?.get('url') ?? autoThumbnail?.get('url')
        } else {
          return uploadedImages
            ?.find((v, k) => k.startsWith(imageType))
            ?.get('url')
        }
      }
    }

    case CreativeCategoryEnum.Type.MESSAGE: {
      const { messageElement } = creative
      /**
       * KAMOQA-24916 (https://jira.daumkakao.com/browse/KAMOQA-24916)
       * backend에서 thumbnailUrl 추출하는 로직과 동일한 로직을 프론트에서 사용하게 되어 backend에서 주는 thumbnailUrl 사용하지 않느것으로 수정
       */
      return TalkChannelMessageHelper.getThumbnailUrl(messageElement) || ''
    }

    case CreativeCategoryEnum.Type.DYNAMIC: {
      if (isModify) {
        return creative.get('profileImage')?.get('url') || ''
      } else {
        return (
          creative
            .get('uploadedImages')
            ?.get(CREATIVE_CREATE_PROFILE_KEY)
            ?.get('url') || ''
        )
      }
    }

    case CreativeCategoryEnum.Type.RICH: {
      if (campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM) {
        if (isModify) {
          return creative?.get('backgroundImage')?.get('url')
        } else {
          const { pcTalkBottomBundles } = creative
          return pcTalkBottomBundles?.first()?.getIn(['backgroundImage', 'url'])
        }
      } else if (campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW) {
        if (isModify) {
          if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
            const { image, image2, image1 } = creative
            return image2?.get('url') || image?.get('url') || image1?.get('url')
          } else {
            const { profileImage } = creative
            return profileImage?.get('url')
          }
        } else {
          const { uploadedImages, autoThumbnail, uploadThumbnail } = creative

          if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
            return uploadThumbnail?.get('url') ?? autoThumbnail?.get('url')
          } else {
            return uploadedImages
              ?.find((v, k) => k.startsWith(imageType))
              ?.get('url')
          }
        }
      } else if (campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) {
        if (isModify) {
          if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
            const { image, image2, image1 } = creative
            return image?.get('url') || image2?.get('url') || image1?.get('url')
          } else {
            const { profileImage } = creative
            return profileImage?.get('url')
          }
        } else {
          const {
            uploadedMultiImages,
            uploadedImages,
            autoThumbnail,
            uploadThumbnail,
          } = creative

          if (imageType === CREATIVE_IMAGE_TYPE.PROMOTIONAL) {
            return (
              uploadedMultiImages?.first()?.first()?.get('url') ||
              uploadThumbnail?.get('url') ||
              autoThumbnail?.get('url')
            )
          } else {
            return uploadedImages
              ?.find((v, k) => k.startsWith(imageType))
              ?.get('url')
          }
        }
      } else {
        return creative?.get('bannerImage')?.get('url')
      }
    }
    default: {
      return null
    }
  }
}

const getRepresentativeImageSizeArray = ({ creative, campaignType }) => {
  if (!creative) {
    return []
  }

  const {
    creativeFormat,
    image,
    assetGroups,
    boardType,
    image1,
    backgroundImage,
  } = creative || {}

  const sizeArray = []

  if (creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL) {
    const { imageWidth, imageHeight } = assetGroups.first()?.get('image') || {}
    sizeArray[0] = imageWidth
    sizeArray[1] = imageHeight
  } else if (CreativeFormatEnum.isMessage(creativeFormat)) {
    const { messageElement } = creative
    if (creativeFormat === CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE) {
      const { image, thumbnailImage } = messageElement || {}
      const { imageWidth: messageImageWidth, imageHeight: messageImageHeight } =
        image || {}
      const { messageThumbnailImageWidth, messageThumbnailImageHeight } =
        thumbnailImage || {}
      sizeArray[0] = messageImageWidth || messageThumbnailImageWidth
      sizeArray[1] = messageImageHeight || messageThumbnailImageHeight
    } else if (
      creativeFormat === CreativeFormatEnum.Type.WIDE_MESSAGE ||
      creativeFormat === CreativeFormatEnum.Type.WIDE_LIST_MESSAGE
    ) {
      const { itemAssetGroups } = messageElement
      const { image, thumbnail } = itemAssetGroups?.first() || {}
      const { imageWidth: messageImageWidth, imageHeight: messageImageHeight } =
        image || {}
      const {
        imageWidth: messageThumbnailImageWidth,
        imageHeight: messageThumbnailImageHeight,
      } = thumbnail || {}
      sizeArray[0] = messageImageWidth || messageThumbnailImageWidth
      sizeArray[1] = messageImageHeight || messageThumbnailImageHeight
    }
  } else if (boardType) {
    const {
      boardAssetGroup: {
        backgroundImage: { imageWidth, imageHeight },
      },
    } = creative || {}
    sizeArray[0] = imageWidth
    sizeArray[1] = imageHeight
  } else if (creativeFormat === CreativeFormatEnum.Type.VIDEO_NATIVE) {
    const { imageWidth, imageHeight } = image1 || {}
    sizeArray[0] = imageWidth
    sizeArray[1] = imageHeight
  } else if (campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM) {
    const { imageWidth, imageHeight } = backgroundImage || {}
    sizeArray[0] = imageWidth
    sizeArray[1] = imageHeight
  } else if (campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW) {
    const { imageWidth, imageHeight } = image1 || {}
    sizeArray[0] = imageWidth
    sizeArray[1] = imageHeight
  } else if (campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) {
    const targetImage = image || image1
    const { imageWidth, imageHeight } = targetImage || {}
    sizeArray[0] = imageWidth
    sizeArray[1] = imageHeight
  } else {
    const { imageWidth, imageHeight } = image || {}
    sizeArray[0] = imageWidth
    sizeArray[1] = imageHeight
  }

  return sizeArray
}

const getMessageCreativeLimit = (creativeOptimization, smartMessage) => {
  if (smartMessage || creativeOptimization) {
    return 10
  } else {
    return 1
  }
}

const isActiveAddParamButton = url => {
  if (checkEmpty(url)) {
    return false
  }
  return (
    checkUrlWithDomain(url) &&
    !checkUrlHasWhiteSpace(url) &&
    !hasNotAllowedSpecialCharacter(url)
  )
}

const getPostThumbnailURL = item => {
  const { media, type } = item || {}
  const { thumbnail, url, images } = media?.first() || {}
  const { url: linkThumbnailUrl } = images?.first() || {}

  switch (type) {
    case PostMediaTypeEnum.Type.link:
      return linkThumbnailUrl
    case PostMediaTypeEnum.Type.card:
    case PostMediaTypeEnum.Type.video:
    case PostMediaTypeEnum.Type.image:
    case PostMediaTypeEnum.Type.text:
    default:
      return thumbnail || url
  }
}

const getPostTitleText = ({ title, content, type, media }) => {
  const { url } = media?.first() || {}
  const isLink = type === PostMediaTypeEnum.Type.link
  return title || content || (isLink ? url : '') || '(등록된 내용이 없습니다.)'
}

const isVideoCampaignTypeCreative = ({
  campaignType,
  creativeFormat,
  isVideoThumbnail = false,
}) => {
  return (
    CampaignTypeEnum.isVideoType(campaignType) ||
    CreativeFormatEnum.isVideo(creativeFormat) ||
    isVideoThumbnail
  )
}

export {
  getRepresentativeImageUrl,
  getRepresentativeImageSizeArray,
  getMessageCreativeLimit,
  isActiveAddParamButton,
  getPostThumbnailURL,
  getPostTitleText,
  isVideoCampaignTypeCreative,
}
