import PopupProxy from '../../Popup/Common/PopupProxy'
import React from 'react'
import { convertToCommaSeparatedString } from '../../../utils/utils'

const campaignResumeWarningForTalkChannelReachDialog = ({ onOK, onCancel }) => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onOK}
      onDismiss={onCancel}>
      <strong className="tit_layer">광고 재개</strong>
      <p className="txt_layer">
        하위에 집행 가능한 광고그룹이 있습니다.
        <br />
        광고 집행을 재개 하시겠습니까?
      </p>
      <div className="adbox_scroll">
        <div className="inner_adbox_scroll">
          <strong className="tit_adbox">[카카오톡 채널 유형]</strong>
          <p className="txt_adbox">재개 시 메시지 발송이 가능 합니다.</p>
        </div>
      </div>
    </PopupProxy>
  )
}

const campaignPauseWarningDialog = ({
  onOK,
  onCancel,
  hasTalkChannelReach,
  hasDaumShoppingReach,
  hasTalkBizBoardReserved,
  hasTalkBizBoardChatTabCpt,
  hasPersonalMessageReach,
  hasElection2024,
  hasPcTalkBottom,
  hasPcTalkRichPop,
  hasFocusFullView,
  hasProfileFullView,
}) => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onOK}
      onDismiss={onCancel}>
      <strong className="tit_layer">광고 일시 중지</strong>
      <p className="txt_layer">
        하위에 집행예정 또는 집행중인 광고그룹이 있습니다.
        <br />
        광고 집행을 일시 중지하시겠습니까?
      </p>
      <div className="adbox_scroll">
        <div className="inner_adbox_scroll">
          {hasTalkChannelReach && (
            <>
              <strong className="tit_adbox">[카카오톡 채널 유형]</strong>
              <p className="txt_adbox">
                중지 후 집행 기간 내 발송 재개 가능합니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasDaumShoppingReach && (
            <>
              <strong className="tit_adbox">[다음쇼핑 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 일 단위로 계속 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasTalkBizBoardReserved && (
            <>
              <strong className="tit_adbox">[카카오 비즈보드 CPT 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasTalkBizBoardChatTabCpt && (
            <>
              <strong className="tit_adbox">
                [카카오 비즈보드 채팅탭 CPT 유형]
              </strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasPersonalMessageReach && (
            <>
              <strong className="tit_adbox">[개인화 메시지 유형]</strong>
              <p className="txt_adbox">
                발송 중이거나 발송 예정인 메시지가 있는 경우 발송이 완전히
                취소됩니다. (발송 재개 불가)
              </p>
            </>
          )}
          {hasElection2024 && (
            <>
              <strong className="tit_adbox">[제22대 국회의원 선거 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasPcTalkBottom && (
            <>
              <strong className="tit_adbox">[포커스 보드 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasPcTalkRichPop && (
            <>
              <strong className="tit_adbox">[리치팝 올데이 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasFocusFullView && (
            <>
              <strong className="tit_adbox">[포커스 풀뷰 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasProfileFullView && (
            <>
              <strong className="tit_adbox">[프로필 풀뷰 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
        </div>
      </div>
    </PopupProxy>
  )
}

const campaignDailyBudgetModifyWarningDialog = ({ onOK }) => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onOK}>
      <strong className="tit_layer">일 예산 수정에 따른 재설정</strong>
      <p className="txt_layer">
        캠페인 일 예산보다 큰 광고그룹 일 예산 및<br />
        수정되는 일 예산의 50%를 넘는 광고그룹 입찰금액이
        <br />
        입력값 기준으로 수정됩니다.
        <br />
        계속하시겠습니까?
      </p>
    </PopupProxy>
  )
}

const campaignDailyBudgetModifyReconfirmDialog = ({ dailyBudget, onOK }) => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">캠페인 일 예산 수정</strong>
      <p className="txt_layer">
        일 예산이 아래와 같이 수정됩니다.
        <br />
        계속하시겠습니까?
        <br />
        <br />
        <em className="fw_bold fc_emph2">
          {dailyBudget > 0
            ? `${convertToCommaSeparatedString(dailyBudget)}원`
            : '미설정'}
        </em>
      </p>
    </PopupProxy>
  )
}

const campaignUnavailableCampaignTypeGoalDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">캠페인 저장 불가</strong>
      <p className="txt_layer">사용가능한 캠페인 유형과 목표가 아닙니다.</p>
    </PopupProxy>
  )
}

const notValidContractDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">광고 상품 계약 오류</strong>
      <p className="txt_layer">
        광고 상품 계약이 유효하지 않습니다.
        <br />
        계약 상태를 확인해주세요.
      </p>
    </PopupProxy>
  )
}

const alreadyUsedContractDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">광고 상품 계약 중복</strong>
      <p className="txt_layer">이미 사용 중인 계약입니다.</p>
    </PopupProxy>
  )
}

const campaignPersonalMessageConversionChangedDialog = onAccept => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onAccept}
      autoClose={false}>
      <strong className="tit_layer">수정사항 저장</strong>
      <p className="txt_layer">
        해당 캠페인 하위에 발송중인 메시지 소재가 포함되어 있는 경우 발송이
        중단되고 잔여 메시지는 모두 발송 취소됩니다.
        <br />
        계속하시겠습니까? (수정 후 재발송 요청 필요)
      </p>
    </PopupProxy>
  )
}

const campaignDailyBudgetConfirmDialog = onAccept => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      secondaryButtonText={'취소'}
      primaryButtonFunc={onAccept}>
      <strong className="tit_layer">캠페인 일 예산 확인</strong>
      <p className="txt_layer">
        캠페인 하위 광고그룹들의 일 예산을 총합한 금액보다
        <br /> 캠페인 일 예산이 작을 경우,
        <br /> 캠페인 일 예산을 크게 상회하여 과금될 수 있으며
        <br /> 이는 시스템 오류가 아니며 보상 대상이 아닙니다. <br />
        그래도 계속하시겠습니까?
      </p>
    </PopupProxy>
  )
}

const campaignObjectiveTypeChangeDialog = onAccept => {
  return (
    <PopupProxy primaryButtonFunc={onAccept} hasSecondaryButton={true}>
      <strong className="tit_layer">픽셀&SDK 변경됨</strong>
      <p className="txt_layer">
        픽셀&SDK 변경 시 비즈니스폼&애드뷰(비즈니스폼
        <br />
        버튼 사용)를 랜딩으로 사용 중인 소재가 있다면
        <br />
        전환 집계가 정상적으로 동작하지 않을 수 있습니다.
        <br />
        원활한 전환 추적을 위해 소재를 <br />
        신규로 등록해 주시기 바랍니다.
      </p>
    </PopupProxy>
  )
}

export {
  campaignResumeWarningForTalkChannelReachDialog,
  campaignPauseWarningDialog,
  campaignDailyBudgetModifyWarningDialog,
  campaignDailyBudgetModifyReconfirmDialog,
  campaignUnavailableCampaignTypeGoalDialog,
  notValidContractDialog,
  campaignPersonalMessageConversionChangedDialog,
  campaignDailyBudgetConfirmDialog,
  alreadyUsedContractDialog,
  campaignObjectiveTypeChangeDialog,
}
