import cx from 'classnames'
import React from 'react'
import CreativeLibraryImageView from './CreativeLibraryImageView'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { deleteCreativeLibraryUploadItem } from '../../../../../modules/advertise/mCreativeLibrary'
import PropTypes from 'prop-types'
import { Set } from 'immutable'
import { filterCreativeLibraryUploadAssets } from '../creativeLibraryUtils'
import { showErrorMessage } from '../../../../../utils/alertUtils'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import CmpDropArea, { UPLOAD_TYPE } from '../../Cmp/CmpInput/CmpDropArea'

const selector = ({
  creativeV2: {
    creativeLibrary: {
      upload: { uploadItems },
      selectedItems,
      viewState: { isUploading },
    },
  },
}) => {
  return {
    uploadItems,
    selectedItems,
    isUploading,
  }
}

const ERROR_MESSAGE_BUSY = '다른 작업을 처리하고 있습니다.'

const CreativeLibraryUpload = ({
  multiSelect,
  campaignType,
  creativeFormat,
  creativeUploadAssetPropertyType,
  imageSizes,
  renderGuideView = () => {},
  handleImageCropperOpen,
  // `useMultipleImageUpload` props
  onImageDrop,
  onImageDragOver,
  onImageDragEnter,
  onImageDragLeave,
  onImageDragEnd,
  onImageChange,
}) => {
  const dispatch = useDispatch()

  const [isVisibleAnim, setIsVisibleAnim] = React.useState(false)
  const { uploadItems, selectedItems, isUploading } = useSelector(
    selector,
    shallowEqual
  )

  const filteredUploadItems = filterCreativeLibraryUploadAssets({
    assetList: uploadItems,
    creativeUploadAssetPropertyType,
    sizeConstraintArray: imageSizes,
    creativeFormat,
  })

  const [loadedImageUUIDs, setLoadedImageUUIDs] = React.useState(
    Set(filteredUploadItems.map(item => item.get('imageUUID')))
  )

  const [isOverDropTarget, setIsOverDropTarget] = React.useState(false)
  const dropTargetCounter = React.useRef(0)

  const isBizBoardBanner =
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT

  /**
   * override `useMultipleImageUpload` drag handler
   * https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element*
   */
  const onDragEnter = React.useCallback(
    e => {
      onImageDragEnter(e)
      dropTargetCounter.current++
      setIsOverDropTarget(true)
    },
    [onImageDragEnter]
  )

  const onDragLeave = React.useCallback(
    e => {
      onImageDragLeave(e)
      dropTargetCounter.current--
      if (dropTargetCounter.current === 0) {
        setIsOverDropTarget(false)
      }
    },
    [onImageDragLeave]
  )

  const onDrop = React.useCallback(
    e => {
      if (isUploading) {
        e.preventDefault()

        showErrorMessage(ERROR_MESSAGE_BUSY)
      } else {
        onImageDrop(e)
      }

      setIsOverDropTarget(false)
      dropTargetCounter.current = 0
    },
    [isUploading, onImageDrop]
  )

  const handleDelete = React.useCallback(
    (image, index) => {
      dispatch(deleteCreativeLibraryUploadItem(image, index))

      setLoadedImageUUIDs(prev => prev.delete(image.get('imageUUID')))
    },
    [dispatch]
  )

  const onImageLoaded = React.useCallback(
    imageUUID =>
      setLoadedImageUUIDs(prev =>
        !prev.has(imageUUID) ? prev.add(imageUUID) : prev
      ),
    []
  )

  const onUpload = React.useCallback(
    e => {
      if (isUploading) {
        e.preventDefault()

        showErrorMessage(ERROR_MESSAGE_BUSY)
      } else {
        setIsVisibleAnim(true)

        onImageChange(e)
      }
    },
    [isUploading, onImageChange]
  )

  return (
    <div className="wrap_material">
      <div className="group_info">
        {renderGuideView()}
        <span className="box_inpfile">
          <label
            htmlFor="inputFile|creative|library|upload1"
            className={cx('btn_gs lab_file', { in_active: isUploading })}>
            <span className="inner_g">
              <span className="img_comm ico_add" />
              파일 업로드
            </span>
          </label>
          <input
            id="inputFile|creative|library|upload1"
            type="file"
            className="inp_file"
            accept="image/*"
            onChange={e => {
              if (isUploading) {
                e.preventDefault()

                showErrorMessage(ERROR_MESSAGE_BUSY)
              } else {
                setIsVisibleAnim(true)

                onImageChange(e)
              }
            }}
            multiple={true}
          />
        </span>
      </div>
      <div
        className={cx('group_material', {
          drop_area_all: isOverDropTarget,
          on_drop: filteredUploadItems.count() === 0 || isOverDropTarget,
        })}
        onDragOver={onImageDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragEnd={onImageDragEnd}
        onDrop={e => {
          setIsVisibleAnim(true)

          onDrop(e)
        }}>
        <CmpDropArea
          allowedMimeTypeString={'image/*'}
          uploadType={UPLOAD_TYPE.IMAGE}
          multiple={true}
          onUpload={onUpload}
        />
        <div className="inner_material">
          <ul className="list_material">
            {filteredUploadItems.map((item, i) => {
              const { imageUUID } = item
              const selectedOrder =
                selectedItems.findIndex(v => v.get('imageUUID') === imageUUID) +
                1

              const isAlreadyLoaded = loadedImageUUIDs.has(imageUUID)

              return (
                <CreativeLibraryImageView
                  key={imageUUID}
                  index={i}
                  selectedOrder={selectedOrder}
                  item={item}
                  isVisibleAnim={!isAlreadyLoaded && isVisibleAnim}
                  isBizBoardBanner={isBizBoardBanner}
                  deletable={true}
                  multiSelect={multiSelect}
                  handleDelete={handleDelete}
                  onImageLoaded={onImageLoaded}
                  creativeUploadAssetPropertyType={
                    creativeUploadAssetPropertyType
                  }
                  creativeFormat={creativeFormat}
                  campaignType={campaignType}
                  handleImageCropperOpen={handleImageCropperOpen}
                />
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

CreativeLibraryUpload.propTypes = {
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()),
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  multiSelect: PropTypes.bool,
  imageSizes: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      minWidth: PropTypes.number,
      ratio: PropTypes.number,
    })
  ),
  renderGuideView: PropTypes.func,
  handleImageCropperOpen: PropTypes.func,
  // `useMultipleImageUpload` props
  onImageDrop: PropTypes.func,
  onImageDragOver: PropTypes.func,
  onImageDragEnter: PropTypes.func,
  onImageDragLeave: PropTypes.func,
  onImageDragEnd: PropTypes.func,
  onImageChange: PropTypes.func,
}

export default CreativeLibraryUpload
