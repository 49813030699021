import axios from 'axios'
import { v4 as uuid } from 'uuid'
import { createReducer } from 'redux-immutablejs'
import { fromJS, List, Map } from 'immutable'
import { showErrorMessage } from '../../utils/alertUtils'
import { hideLoading, showLoading } from '../common/mLoading'
import {
  IS_NOT_VALID,
  scrollByValidationKeys,
  Validation,
} from '../../validators/validation'
import { Trim } from '../../utils/formTrim'
import {
  coerceAtLeast,
  dateToString,
  keyMirror,
  localDateTimeToString,
} from '../../utils/utils'
import {
  checkEmpty,
  checkNotEmpty,
  isUndefinedOrNull,
} from '../../utils/regexUtils'
import { coerceToArray } from '../../utils/stringUtils'
import LandingUrlEnum from '../../enums/LandingUrlEnum'
import LandingSchemeEnum from '../../enums/LandingSchemeEnum'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { CreativeHelper } from '../../utils/helper/helper-creative'
import {
  clearNativeImageCreate,
  setNativeImagesFromCompletedList,
} from './mNativeCreateV2'
import {
  handleCreativeFormExceptionV2,
  invalidCreativeByAdGroupStatusAndSchedule,
} from './creativeActions/aCreativeCommonV2'
import {
  getCreativeValidationKeyByFormat,
  getCreativeValidationKeyPathByFormat,
  getCreativeValidatorByFormat,
} from '../../validators/advertise/creativeV2/creativeFormValidatorV2'
import {
  clearProfileFullViewTab,
  CREATIVE_TRIM_KEY_PATH,
  disableDefaultProfileImageCheck,
  initCreativeLandingURLViewState,
  initCreativeValidation,
  setCreativeMessageSpamValidation,
  setIsValidCreativeByKey,
  updateCreativeLandingURLDefaultSettings,
  uploadCreativeImageWithRatioByURL,
} from './mCreativeCommonV2'
import moment from 'moment'
import { replaceCurrentTimeToBaseTime } from '../../components/Common/HourMinuteSelector/HourMinuteSelectorHOC'
import { CREATIVE_FORM_VALIDATION_KEY } from '../../validators/advertise/creativeV2/creativeFormValidationKey'
import {
  changeCreativeFormViewMode,
  CREATIVE_VIEW_MODE,
} from './mCreativeViewV2'
import { setMultiBannerImagesFromCompleteList } from './mBannerCreateV2'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import CreativeConstraints from '../../utils/constraints/constraints-creative'
import ActionButtonEnum from '../../enums/ActionButtonEnum'
import { closeAllPopup, openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import React from 'react'
import AdStatusEnum from '../../enums/AdStatusEnum'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from '../../validators/advertise/creativeV2/creativeFormValidationMessage'
import { clearOpinionProof, setOpinionProof } from './mOpinionProof'
import GoalEnum from '../../enums/GoalEnum'
import {
  getMessageCreativeLimit,
  isVideoCampaignTypeCreative,
} from '../../utils/advertise/adCreativeV2'
import {
  clearPromotionalVideo,
  setPromotionalVideo,
  setPromotionalVideoAutoThumbnail,
  setPromotionalVideoSkippableType,
  setPromotionalVideoUploadThumbnail,
} from './mPromotionalVideo'
import { setCatalogAssetGroups } from './mCatalog'
import { BizBoardLandingInfo, FrequencyCap } from '../../models/model-creative'
import {
  selectBizBoardCreateBundle,
  setBizBoardCreateBundles,
} from './mBizBoardCreateBundle'
import { TalkChannelMessageHelper } from '../../utils/helper/helper-talkChannelMessage'
import {
  initCreativeMessageForm,
  initializeCreativeMessage,
} from './mMessageCreative'
import AdSideBarHandle from '../../components/AdvertiseV2/Common/AdSideBarHandle'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import {
  selectBizBoardExpandableType,
  setBizBoardExpandableAssetGroups,
} from './mBizBoardExpandable'
import { showSpamFilterPopUp } from '../message/mMessageCommon'
import { setDashboardTableSortBySystemTemporary } from '../dashboardV3/mDashboardTable'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import { COLUMN_SPEC } from '../../components/DashboardV3/DashboardTable/dashboardTableUtils'
import SortEnum from '../../enums/SortEnum'
import UserConfigEnum from '../../enums/UserConfigEnum'
import SystemConfigEnum from '../../enums/SystemConfigEnum'
import { MESSAGE_FORM_VALIDATION_KEY } from '../../validators/message/messageFormValidationKey'
import ExpandableEnum from '../../enums/ExpandableEnum'
import { clearDynamicCatalog } from './mDynamicCatalog'
import { setDiscountedPricePercentage } from '../../utils/priceUtils'
import PricingTypeEnum from '../../enums/PricingTypeEnum'
import {
  notice9To16CreativeGoalTypeConstraintDialog,
  notice9To16CreativePricingTypeConstraintDialog,
} from '../../components/AdvertiseV2/Common/AdvertiseDialog'
import {
  selectPcTalkBottomCreateBundle,
  setPcTalkBottomCreateBundles,
} from './mPcTalkBottomCreateBundle'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

const CreativeCreate = keyMirror(
  {
    // form
    CHANGE_FORM_BY_KEY_PATH: null,
    INIT_FORM_BY_KEY_PATH: null,
    INIT_FORM: null,

    // completed
    SET_FORM_TO_COMPLETED: null,
    UPDATE_BATCH_FORM_TO_COMPLETED: null,
    INIT_SELECTED_COMPLETED_ITEM: null,
    SELECT_COMPLETED_ITEM: null,
    DELETE_COMPLETED_ITEM: null,
    DELETE_ALL_COMPLETED_ITEM: null,

    SET_COMPLETED_VALIDATION_ERRORS: null,
    DELETE_COMPLETED_VALIDATION_ERROR_BY_UUID: null,

    // viewable
    SET_IMAGE_BY_KEY: null,
    DELETE_IMAGE_BY_KEY: null,

    // shopping
    SET_SHOPPING_EXECUTIVE_TIME: null,
    CHANGE_SHOPPING_EXECUTIVE_TIME: null,

    CLEAR: null,
  },
  'CREATIVE_CREATE'
)

const initialState = fromJS({
  selectedCompletedIndex: -1,
  completedItems: List(),
  completedValidationErrors: [],

  // 실제 API post 용 form 형태가 아닌 view 용 form.
  // 집행처리를 했을 때 API based 로 변환한다.
  creativeForm: {
    formUUID: null, // 프론트 식별자

    id: -1,
    adGroupId: -1,
    campaignId: -1,
    name: '', // 소재 이름
    creativeFormat: null,
    status: [],
    userConfig: UserConfigEnum.Type.ON,
    systemConfig: SystemConfigEnum.Type.ON,
    checkSum: '',
    frequencyCap: FrequencyCap,
    plusFriendButton: false, // fixed
    landingUrl: '',
    rspvLandingUrl: null,
    pcLandingUrl: null,
    mobileLandingUrl: null,
    landingUrlType: '',
    altText: '', // 소재 대체 텍스트 (accessibility)
    profileId: -1, // 카카오톡 채널 프로필 ID
    profileName: '',
    // profileImage: null,
    actionButton: ActionButtonEnum.Type.NONE,
    actionButtonText: null,
    actionButtonFontColor: '#000000',
    actionButtonBackgroundColor: '',
    description: '', // 홍보문구, 이미지박스 하단 홍보문구
    title: '', // 타이틀 | 이미지박스 상단 홍보문구

    // 쇼핑박스 only?
    beginDateTime: '',
    endDateTime: '',

    useDefaultLandingUrl: false,
    useDefaultProfileImage: false,

    // 쇼핑박스
    creativeScheduleStatus: '',
    noSchedule: false,

    // 비즈보드 랜딩
    landingInfo: BizBoardLandingInfo,

    // 메시지 에디터 이전
    messageType: null,
    messageId: -1,
    textMessage: '',
    messageComponents: [],
    shareFlag: false,

    // 메시지 에디터
    messageElement: {},
    saveMessageTemplate: false,

    // 등록된 소재 이미지 지원 여부(response).  nullable
    isSupportedImageSize: true,

    // 인스트림 동영상
    videoSkippableType: undefined, // {VideoSkippableTypeEnum.Type | undefined}: enum 외의 value 가 들어갈 경우 error

    // SKAN
    skanCustomProductPageId: null,

    // 의견 및 증빙
    opinionProof: {
      opinion: '',
      opinionProofFileList: [],
    },

    // 카탈로그 & 비즈보드 익스펜더블 멀티형
    hasPrice: false,

    // 카탈로그
    assetGroups: undefined, // CatalogAssetGroup[]

    // 비즈보드 익스펜더블
    expandableType: undefined,
    expandableAssetGroups: undefined, // BizBoardExpandableAssetGroup[]

    // 서비스 컨텐츠(스폰서드 보드)
    serviceContent: undefined, // ServiceContent

    // VIEW 전용
    uploadedImages: {},
    uploadedMultiImages: {},
    uploadedVideo: {},

    autoThumbnail: {},
    uploadThumbnail: {},

    // 상품 카탈로그
    catalogProductSetId: null,
    trackingUrl: null,

    // 비즈보드 CPT 모션보드
    boardType: null,
    boardAssetGroup: {
      video: undefined,
      uploadThumbnail: undefined,
      autoThumbnail: undefined,
      backgroundImage: undefined,
      textImage: undefined,
      objectImage1: undefined,
      objectImage2: undefined,
      objectImage3: undefined,
    },

    // NAS 서빙 옵션
    bizBoardNativeServing: false,
    bizBoardNativeImage: {},

    // 리치팝 올데이
    bannerImage: undefined,
    richPopImage: undefined,
    compatibleTitle: '',
    compatibleImage: undefined,
    compatiblePcLandingUrl: '',

    compatibleProfileName: '',

    // 프로필 풀뷰
    textImage: null,

    // 추적 Url
    eventTrackerUrl: null,
    clickTrackerUrl: null,
  },

  shopping: {
    executiveTime: {
      beginDate: undefined,
      beginHour: undefined,
      beginMinute: undefined,
      endDate: undefined,
      endHour: undefined,
      endMinute: undefined,
    },
  },
})

export default createReducer(initialState, {
  // '설정 완료 소재' 선택 해제 (init)
  [CreativeCreate.INIT_SELECTED_COMPLETED_ITEM]: state =>
    state.set(
      'selectedCompletedIndex',
      initialState.get('selectedCompletedIndex')
    ),

  // view form 데이터를 '설정 완료 소재' 리스트에 추가
  [CreativeCreate.SET_FORM_TO_COMPLETED]: (state, { data }) => {
    const { formUUID: selectedFormUUID } = data.first().toJS()
    const { completedItems } = state
    const selectedCompletedIndex = completedItems.findIndex(
      ({ formUUID }) => formUUID === selectedFormUUID
    )
    if (selectedCompletedIndex >= 0 && data.count() === 1) {
      // 기존 항목 갱신
      return state.update('completedItems', completedItems =>
        completedItems.set(selectedCompletedIndex, data.first())
      )
    } else {
      // 신규 항목을 앞으로 추가.
      return state.update('completedItems', completedItems =>
        data.concat(completedItems)
      )
    }
  },

  [CreativeCreate.UPDATE_BATCH_FORM_TO_COMPLETED]: (state, { key, value }) => {
    return state.update('completedItems', completedItems =>
      completedItems.map(v => v.setIn([...key], value))
    )
  },

  /**
   * '설정 완료 소재' 모두 제거
   * - 설정 완료된 소재를 수정 중인 경우 전체 초기화, 아닌 경우 form 은 초기화하지 않는다.
   */
  [CreativeCreate.DELETE_ALL_COMPLETED_ITEM]: state => {
    const { selectedCompletedIndex } = state
    const modifying = selectedCompletedIndex >= 0

    return state.withMutations(s =>
      s
        .set('completedItems', initialState.get('completedItems'))
        .set(
          'selectedCompletedIndex',
          initialState.get('selectedCompletedIndex')
        )
        .update('creativeForm', v =>
          modifying ? initialState.get('creativeForm') : v
        )
        .set(
          'completedValidationErrors',
          initialState.get('completedValidationErrors')
        )
    )
  },

  // '설정 완료 소재' 의 특정 아이템 제거
  [CreativeCreate.DELETE_COMPLETED_ITEM]: (state, { index }) => {
    const { selectedCompletedIndex, completedItems } = state

    const isSelected = index === selectedCompletedIndex
    const { formUUID } = completedItems.get(index)

    return state.withMutations(s =>
      s
        .update('completedItems', completedItems =>
          completedItems.delete(index)
        )
        .update('selectedCompletedIndex', prevIndex =>
          isSelected
            ? initialState.get('selectedCompletedIndex')
            : prevIndex > index
            ? coerceAtLeast(prevIndex - 1, 0)
            : prevIndex
        )
        .update('completedValidationErrors', prev =>
          prev.filter(v => v.get('formUUID') !== formUUID)
        )
    )
  },

  /**
   * '설정 완료 소재' 의 특정 아이템 선택 (수정)
   * - 선택된 설정 완료 소재 아이템 key 세팅
   * - 현재 소재 form 에 선택한 소재 set
   * - dirty
   */
  [CreativeCreate.SELECT_COMPLETED_ITEM]: (state, { index }) => {
    const { completedItems } = state
    return state.withMutations(s =>
      s
        .set('selectedCompletedIndex', index)
        .set('creativeForm', completedItems.get(index))
    )
  },

  /**
   *
   * @param data [{ index, name, description, errorCode }]
   */
  [CreativeCreate.SET_COMPLETED_VALIDATION_ERRORS]: (state, { data }) => {
    const { completedItems } = state

    // 에러 list 에 formUUID SET
    const completedValidationErrors = fromJS(data).map(v =>
      v.set('formUUID', completedItems.get(v.get('index'))?.get('formUUID'))
    )

    // 에러 아이템을 앞으로 이동
    const nextCompletedItems = completedItems.reduceRight(
      (prev, completedItem) =>
        completedValidationErrors.find(
          errorItem =>
            errorItem.get('formUUID') === completedItem.get('formUUID')
        ) !== undefined
          ? prev.unshift(completedItem)
          : prev.push(completedItem),
      List()
    )

    return state.withMutations(s =>
      s
        .set('completedItems', nextCompletedItems)
        .set('completedValidationErrors', completedValidationErrors)
    )
  },

  [CreativeCreate.DELETE_COMPLETED_VALIDATION_ERROR_BY_UUID]: (
    state,
    { formUUID }
  ) =>
    state.update('completedValidationErrors', prev =>
      prev.filter(v => v.get('formUUID') !== formUUID)
    ),

  [CreativeCreate.CHANGE_FORM_BY_KEY_PATH]: (state, { key, value }) =>
    state.withMutations(s =>
      s.setIn(['creativeForm', ...coerceToArray(key)], fromJS(value))
    ),

  [CreativeCreate.INIT_FORM_BY_KEY_PATH]: (state, { key }) =>
    state.withMutations(s =>
      s.setIn(
        ['creativeForm', ...coerceToArray(key)],
        initialState.getIn(['creativeForm', ...coerceToArray(key)])
      )
    ),

  [CreativeCreate.INIT_FORM]: state =>
    state.withMutations(s =>
      s.set('creativeForm', initialState.get('creativeForm'))
    ),

  [CreativeCreate.SET_IMAGE_BY_KEY]: (state, { key, image }) =>
    state.withMutations(s =>
      s.updateIn(['creativeForm', 'uploadedImages'], uploadedImages =>
        uploadedImages.set(key, fromJS(image))
      )
    ),

  [CreativeCreate.DELETE_IMAGE_BY_KEY]: (state, { key }) =>
    state.withMutations(s =>
      s.updateIn(['creativeForm', 'uploadedImages'], uploadedImages =>
        uploadedImages.remove(key)
      )
    ),

  [CreativeCreate.CLEAR]: state => {
    return state.merge(initialState)
  },

  [CreativeCreate.CHANGE_SHOPPING_EXECUTIVE_TIME]: (state, { key, value }) => {
    return state.setIn(
      ['shopping', 'executiveTime', ...coerceToArray(key)],
      fromJS(value)
    )
  },

  [CreativeCreate.SET_SHOPPING_EXECUTIVE_TIME]: (
    state,
    { adGroupSchedule, adGroupStatus }
  ) => {
    const { beginDate, endDate } = adGroupSchedule || {}
    const isAdGroupFinishedOrCanceled =
      adGroupStatus?.some(
        v =>
          v === AdStatusEnum.Type.FINISHED || v === AdStatusEnum.Type.CANCELED
      ) || moment().isAfter(moment(endDate), 'd')

    const isAdGroupOperating = moment().isBetween(
      moment(beginDate),
      moment(endDate),
      null,
      '[]'
    )

    const {
      creativeForm: { beginDateTime, endDateTime, noSchedule },
    } = state

    const initialBeginDate = beginDateTime
      ? moment(beginDateTime)
      : isAdGroupFinishedOrCanceled || noSchedule
      ? null
      : isAdGroupOperating
      ? replaceCurrentTimeToBaseTime(5)
      : moment(beginDate).startOf('d')

    const initialEndDate = endDateTime
      ? moment(endDateTime)
      : isAdGroupFinishedOrCanceled || noSchedule
      ? null
      : moment(endDate).endOf('d')

    const arrangeValue = (value, result) =>
      isUndefinedOrNull(value)
        ? null
        : isUndefinedOrNull(result)
        ? value
        : result

    return state.withMutations(s =>
      s
        .setIn(
          ['creativeForm', 'beginDateTime'],
          arrangeValue(
            initialBeginDate,
            localDateTimeToString(initialBeginDate)
          )
        )
        .setIn(
          ['creativeForm', 'endDateTime'],
          arrangeValue(initialEndDate, localDateTimeToString(initialEndDate))
        )
        .setIn(
          ['shopping', 'executiveTime', 'beginDate'],
          arrangeValue(initialBeginDate, dateToString(initialBeginDate))
        )
        .setIn(
          ['shopping', 'executiveTime', 'beginHour'],
          arrangeValue(initialBeginDate, initialBeginDate?.hour())
        )
        .setIn(
          ['shopping', 'executiveTime', 'beginMinute'],
          arrangeValue(initialBeginDate, initialBeginDate?.minute())
        )
        .setIn(
          ['shopping', 'executiveTime', 'endDate'],
          arrangeValue(initialEndDate, dateToString(initialEndDate))
        )
        .setIn(
          ['shopping', 'executiveTime', 'endHour'],
          arrangeValue(initialEndDate, initialEndDate?.hour())
        )
        .setIn(
          ['shopping', 'executiveTime', 'endMinute'],
          arrangeValue(initialEndDate, initialEndDate?.minute())
        )
    )
  },
})

/**
 * '설정 완료 소재' 에서 '수정' 을 클릭했을 시. (선택된 data 를 form 으로 옮긴다.)
 * - '기존 프로필 이미지' checkbox view state 를 초기화 한다.
 */
export function selectCreativeCompletedItem(index) {
  return (dispatch, getState) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
          adGroup: { schedule: adGroupSchedule, status: adGroupStatus },
        },
        create: { completedItems },
      },
    } = getState()

    // 반드시 form 먼저 set 한 후 하위 action 들 수행
    dispatch({
      type: CreativeCreate.SELECT_COMPLETED_ITEM,
      index,
    })

    const creative = completedItems.get(index)

    const {
      creativeFormat,
      uploadedMultiImages,
      opinionProof,
      uploadedVideo,
      autoThumbnail,
      uploadThumbnail,
      videoSkippableType,
      assetGroups,
      expandableAssetGroups,
      bizBoardBundles,
      messageElement,
      expandableType,
      pcTalkBottomBundles,
    } = creative

    switch (creativeFormat) {
      case CreativeFormatEnum.Type.IMAGE_BANNER: {
        if (
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
        ) {
          // set representative expandable
          if (expandableAssetGroups?.count() > 0) {
            dispatch(
              setBizBoardExpandableAssetGroups({ expandableAssetGroups })
            )
          } else {
            dispatch(selectBizBoardExpandableType({ value: expandableType }))
          }

          // set bundles
          dispatch(setBizBoardCreateBundles(bizBoardBundles))

          // select first bundle
          dispatch(
            selectBizBoardCreateBundle(
              bizBoardBundles.first().get('bundleUUID')
            )
          )
        } else {
          dispatch(setMultiBannerImagesFromCompleteList(uploadedMultiImages))
        }
        break
      }

      case CreativeFormatEnum.Type.IMAGE_NATIVE:
        dispatch(setNativeImagesFromCompletedList(uploadedMultiImages))
        break

      case CreativeFormatEnum.Type.IMAGE_BOX: {
        dispatch(setNativeImagesFromCompletedList(uploadedMultiImages))
        break
      }

      case CreativeFormatEnum.Type.CATALOG_MANUAL: {
        dispatch(setCatalogAssetGroups(assetGroups))
        break
      }

      case CampaignTypeEnum.Type.DAUM_SHOPPING: {
        dispatch(
          setCreativeCreateShoppingExecutiveTime(adGroupSchedule, adGroupStatus)
        )
        break
      }

      case CreativeFormatEnum.Type.VIDEO_NATIVE:
      case CreativeFormatEnum.Type.VIDEO_INSTREAM: {
        dispatch(clearPromotionalVideo())
        dispatch(setPromotionalVideo({ video: uploadedVideo }))
        dispatch(setPromotionalVideoAutoThumbnail({ autoThumbnail }))
        dispatch(
          setPromotionalVideoUploadThumbnail({
            uploadThumbnail,
          })
        )
        dispatch(setPromotionalVideoSkippableType({ videoSkippableType }))
        break
      }

      case CreativeFormatEnum.Type.RICH_NATIVE: {
        dispatch(clearPromotionalVideo())
        if (campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM) {
          dispatch(setPromotionalVideo({ video: uploadedVideo || {} }))
          dispatch(setPromotionalVideoAutoThumbnail({ autoThumbnail }))
          dispatch(
            setPromotionalVideoUploadThumbnail({
              uploadThumbnail,
            })
          )
          dispatch(setPcTalkBottomCreateBundles(pcTalkBottomBundles))

          // select first bundle
          dispatch(
            selectPcTalkBottomCreateBundle(
              pcTalkBottomBundles.first().get('bundleUUID')
            )
          )
        } else if (campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW) {
          dispatch(setPromotionalVideo({ video: uploadedVideo || {} }))
          dispatch(setPromotionalVideoAutoThumbnail({ autoThumbnail }))
          dispatch(
            setPromotionalVideoUploadThumbnail({
              uploadThumbnail,
            })
          )
        } else if (campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) {
          const { url: videoUrl } = uploadedVideo || {}

          dispatch(clearPromotionalVideo())
          dispatch(clearNativeImageCreate())

          if (checkNotEmpty(videoUrl)) {
            dispatch(setPromotionalVideo({ video: uploadedVideo || {} }))
            dispatch(setPromotionalVideoAutoThumbnail({ autoThumbnail }))
            dispatch(
              setPromotionalVideoUploadThumbnail({
                uploadThumbnail,
              })
            )
          } else {
            dispatch(setNativeImagesFromCompletedList(uploadedMultiImages))
          }
        }
        break
      }

      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        dispatch(
          initializeCreativeMessage(
            TalkChannelMessageHelper.creativeCompletedToForm({
              messageForm: messageElement,
            }),
            campaignType
          )
        )

        break
      }

      default: {
        break
      }
    }

    if (CreativeFormatEnum.needReview(creativeFormat)) {
      dispatch(clearOpinionProof())
      dispatch(setOpinionProof(opinionProof))
    }

    dispatch(disableDefaultProfileImageCheck(false))

    // 랜딩 url의 디폴트 값 및 뷰 업데이트
    dispatch(updateCreativeLandingURLDefaultSettings(creative))
  }
}

/**
 * '설정 완료 소재' 의 현재 selection 정보 초기화.
 */
export function initSelectedCreativeCompletedItem() {
  return {
    type: CreativeCreate.INIT_SELECTED_COMPLETED_ITEM,
  }
}

/**
 * '설정 완료 소재' 전체를 삭제한다.
 */
export function deleteAllCreativeCompletedItem() {
  return {
    type: CreativeCreate.DELETE_ALL_COMPLETED_ITEM,
  }
}

/**
 * '설정 완료 소재' 의 특정 데이터를 삭제한다.
 */
export function deleteCreativeCompletedItem(index) {
  return {
    type: CreativeCreate.DELETE_COMPLETED_ITEM,
    index,
  }
}

/**
 *
 * @param data [{ index, name, description, errorCode }]
 * @return {{data: *, type: null}}
 */
export function setCreativeCompletedValidationErrors(data) {
  return {
    type: CreativeCreate.SET_COMPLETED_VALIDATION_ERRORS,
    data,
  }
}

export function deleteCreativeCompletedValidationErrorByFormUUID(formUUID) {
  return {
    type: CreativeCreate.DELETE_COMPLETED_VALIDATION_ERROR_BY_UUID,
    formUUID,
  }
}

/**
 * form data 의 특정 key 수정.
 * @param key {string | string[]}
 * @param value {*}
 */
export function changeCreativeCreateFormByKey(key, value) {
  return {
    type: CreativeCreate.CHANGE_FORM_BY_KEY_PATH,
    key,
    value,
  }
}

/**
 * form data 의 특정 key 초기화.
 */
export function initCreativeCreateFormByKey(key) {
  return {
    type: CreativeCreate.INIT_FORM_BY_KEY_PATH,
    key,
  }
}

/**
 * form data 전체 초기화.
 */
export function initAllCreativeCreateForm() {
  return {
    type: CreativeCreate.INIT_FORM,
  }
}

/**
 * form data list 를 Complete items 로 저장한다.
 * @param data {List} - creativeForm list
 */
export function setCreativeCreateFormToCompleted(data) {
  return dispatch => {
    dispatch({
      type: CreativeCreate.SET_FORM_TO_COMPLETED,
      data,
    })

    dispatch(changeCreativeFormViewMode(CREATIVE_VIEW_MODE.NEW))

    AdSideBarHandle.open()
  }
}

export function updateBatchCreativeCreateFormToCompleted({ key, value }) {
  return {
    type: CreativeCreate.UPDATE_BATCH_FORM_TO_COMPLETED,
    key,
    value,
  }
}

/**
 * 업로드 한 image file 객체의 metadata 를 set.
 */
export function setCreativeCreateImageByKey(key, image) {
  return {
    type: CreativeCreate.SET_IMAGE_BY_KEY,
    key,
    image,
  }
}

/**
 * 카카오톡 채널 URL 을 통한 소재 프로필 이미지 업로드
 */
export function uploadCreativeCreateProfileImageByPlusFriendURL(
  adAccountId,
  url,
  key,
  minWidth,
  requiredRatio
) {
  const onSuccess = (dispatch, response) => {
    dispatch(setCreativeCreateImageByKey(key, response.data))
    dispatch(changeCreativeCreateFormByKey('useDefaultProfileImage', true))
    dispatch(disableDefaultProfileImageCheck(false))
  }

  const onFail = dispatch => {
    dispatch(changeCreativeCreateFormByKey('useDefaultProfileImage', false))
    dispatch(disableDefaultProfileImageCheck(true))
    showErrorMessage(
      '기존 등록되어 있는 카카오톡 채널의 프로필 이미지가 광고 소재로 사용하기 적절하지 않습니다.'
    )
  }

  return uploadCreativeImageWithRatioByURL(
    adAccountId,
    url,
    minWidth,
    requiredRatio,
    onSuccess,
    onFail
  )
}

/**
 * 업로드 한 이미지 데이터를 제거한다.
 * 이 단계에서는 form 에 쓰지 않는다. 저장을 했을 때 (complete list 로 갈 때) form 에 데이터를 채울 것.
 * @param key - ex) `{CREATIVE_IMAGE_TYPE}_SIZE_320x100`
 */
export function deleteCreativeCreateImageByKey(key) {
  return {
    type: CreativeCreate.DELETE_IMAGE_BY_KEY,
    key,
  }
}

const validateBizBoardBundleLandingUrl = ({ adAccountId, bizBoardBundle }) => {
  return async (dispatch, getState, api) => {
    const {
      bundleUUID,
      landingInfo: { landingType, url },
    } = bizBoardBundle

    if (landingType === LandingSchemeEnum.Type.URL) {
      dispatch(showLoading())

      try {
        await api.common.validateUrl(adAccountId, url)

        dispatch(
          setIsValidCreativeByKey(
            CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE,
            true
          )
        )
      } catch (e) {
        dispatch(
          setIsValidCreativeByKey(
            CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE,
            false,
            '',
            {
              bizBoardBundleValidationResults: [
                {
                  bundleUUID,
                  landingInfo: IS_NOT_VALID(
                    CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL
                  ),
                },
              ],
            }
          )
        )
      } finally {
        dispatch(hideLoading())
      }
    }
  }
}

/**
 * 소재 form -> 설정 완료 리스트
 */
export function saveCreativeCreateFormToCompleted(
  adAccountId,
  campaignId,
  adGroupId
) {
  //
  return async (dispatch, getState, api) => {
    const {
      creativeV2: {
        create: {
          creativeForm,
          creativeForm: { creativeFormat, expandableType },
          shopping: { executiveTime },
        },
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
          adGroup: { status: adGroupStatus, skanProtocolInfo },
          creativeViewState: { isSameUrlChecked, isEachChecked },
        },
        // 이미지 배너
        bannerCreate: { images: bannerImages },
        // 이미지 네이티브
        nativeCreate: { images: nativeImages },
        // 비즈보드 배너 번들(이미지 + 랜딩 + 익스펜더블)
        bizBoardCreateBundle: { bizBoardBundles },
        pcTalkBottomCreateBundle: { pcTalkBottomBundles },
        bizBoardExpandable: { expandableAssetGroups: repExpandableAssetGroups },
        // 의견 증빙
        opinionProof,
        // 동영상
        promotionalVideo: {
          video,
          autoThumbnail,
          uploadThumbnail,
          videoSkippableType,
        },
        // 카탈로그
        catalog: { assetGroups },
        dynamicCatalog: {
          productSet: { isSegment, originalCatalogId },
        },
        creativeMessage,
      },
    } = getState()

    const hasSkanProtocolInfo = checkNotEmpty(skanProtocolInfo)
    const isBizBoard =
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT

    const isRequiredLandingInfo = [
      CampaignTypeEnum.Type.TALK_BIZ_BOARD,
      CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
      CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT,
      CampaignTypeEnum.Type.PC_TALK_RICH_POP,
      CampaignTypeEnum.Type.PC_TALK_BOTTOM,
      CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
      CampaignTypeEnum.Type.PROFILE_FULL_VIEW,
    ].includes(campaignType)

    const hasVideoAsset =
      isVideoCampaignTypeCreative({ campaignType, creativeFormat }) ||
      campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM ||
      campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW
    /**
     * pre-processing
     * !!validation 을 위하여 반드시 모든 내용은 Immutable 형태여야 함에 주의!!
     */
    const formData = creativeForm.withMutations(s =>
      s
        .update('formUUID', v => v || uuid()) // 설정완료 항목 식별자
        .set('adGroupId', adGroupId)
        .set(
          'uploadedMultiImages',
          creativeFormat === CreativeFormatEnum.Type.IMAGE_BANNER && !isBizBoard
            ? bannerImages
            : creativeFormat === CreativeFormatEnum.Type.IMAGE_NATIVE ||
              creativeFormat === CreativeFormatEnum.Type.IMAGE_BOX ||
              campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW
            ? nativeImages
            : Map()
        )
        .set(
          'bizBoardBundles',
          isBizBoard
            ? bizBoardBundles.map(bizBoardBundle =>
                bizBoardBundle
                  .update('expandableAssetGroups', expandableAssetGroups =>
                    expandableAssetGroups.map(expandableAssetGroup =>
                      expandableAssetGroup
                        .update('title', title =>
                          bizBoardBundle.get('expandableType') ===
                          ExpandableEnum.Type.MULTI
                            ? String(title).trim()
                            : undefined
                        )
                        .update('mobileLandingUrl', mobileLandingUrl =>
                          bizBoardBundle.get('expandableType') ===
                          ExpandableEnum.Type.MULTI
                            ? String(mobileLandingUrl).trim()
                            : undefined
                        )
                    )
                  )
                  .update('landingInfo', landingInfo =>
                    landingInfo.update('url', url => url.trim())
                  )
                  .update('clickTrackerUrl', clickTrackerUrl =>
                    clickTrackerUrl.trim()
                  )
                  .update('eventTrackerUrl', eventTrackerUrl =>
                    eventTrackerUrl.trim()
                  )
              )
            : undefined
        )
        .set(
          'pcTalkBottomBundles',
          campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM
            ? pcTalkBottomBundles
            : undefined
        )
        .set(
          'expandableAssetGroups',
          isBizBoard
            ? repExpandableAssetGroups
                .map(repExpandableAssetGroup =>
                  repExpandableAssetGroup
                    .update('title', title =>
                      expandableType === ExpandableEnum.Type.MULTI
                        ? String(title).trim()
                        : undefined
                    )
                    .update('mobileLandingUrl', mobileLandingUrl =>
                      expandableType === ExpandableEnum.Type.MULTI
                        ? String(mobileLandingUrl).trim()
                        : undefined
                    )
                )
                .map(expandableAssetGroup =>
                  setDiscountedPricePercentage(expandableAssetGroup)
                )
            : undefined
        )
        .set(
          'assetGroups',
          creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL
            ? assetGroups
                .map(assetGroup =>
                  assetGroup
                    .update('title', title => String(title).trim())
                    .update('description', description =>
                      checkEmpty(description)
                        ? description
                        : String(description).trim() === ''
                        ? null
                        : String(description).trim()
                    )
                    .update('rspvLandingUrl', rspvLandingUrl => {
                      const defaultUrl =
                        assetGroup.get('mobileLandingUrl') ||
                        assetGroup.get('pcLandingUrl') ||
                        rspvLandingUrl

                      const isAssetGroupSameUrlChecked =
                        assetGroup.get('isSameUrlChecked')
                      const isAssetEachChecked = assetGroup.get('isEachChecked')

                      if (isAssetGroupSameUrlChecked) {
                        return defaultUrl?.trim()
                      } else if (
                        checkEmpty(rspvLandingUrl) ||
                        isAssetEachChecked
                      ) {
                        return null
                      } else {
                        return rspvLandingUrl?.trim()
                      }
                    })
                    .update('pcLandingUrl', pcLandingUrl =>
                      checkEmpty(pcLandingUrl) ||
                      assetGroup.get('isSameUrlChecked')
                        ? null
                        : pcLandingUrl?.trim()
                    )
                    .update('mobileLandingUrl', mobileLandingUrl =>
                      checkEmpty(mobileLandingUrl) ||
                      assetGroup.get('isSameUrlChecked')
                        ? null
                        : mobileLandingUrl?.trim()
                    )
                )
                .map(assetGroup => setDiscountedPricePercentage(assetGroup))
            : undefined
        )
        .set('uploadedVideo', hasVideoAsset ? video : Map())
        .set('autoThumbnail', hasVideoAsset ? autoThumbnail : Map())
        .set('uploadThumbnail', hasVideoAsset ? uploadThumbnail : Map())
        .set(
          'videoSkippableType',
          creativeFormat === CreativeFormatEnum.Type.VIDEO_INSTREAM
            ? videoSkippableType
            : undefined
        )
        /**
         * 상위 URL 과 동일 체크박스 체크시 (소재) pcLandingUrl, mobileLandingUrl을 반응형으로 저장하고 pc와 모바일 값은 각각 삭제
         */
        .update('rspvLandingUrl', v => {
          const defaultUrl = s.get('mobileLandingUrl') || s.get('pcLandingUrl')

          if (isSameUrlChecked) {
            return defaultUrl?.trim()
          } else if (checkEmpty(v) || isEachChecked) {
            return null
          } else {
            return v?.trim()
          }
        })
        /**
         * 랜딩 URL View 기준 처리
         */
        .update('pcLandingUrl', v =>
          checkEmpty(v) || isSameUrlChecked ? null : v?.trim()
        )
        .update('mobileLandingUrl', v =>
          checkEmpty(v) || isSameUrlChecked ? null : v?.trim()
        )
        .set('landingUrlType', LandingUrlEnum.getAdCreativeLandingUrlType(s))
        /**
         * 챗탭 노출 목적이 아닐 경우 landingInfo 를 제거한다.
         * 애드뷰 타입이 아닐 경우 adViewItem object 를 제거한다.
         */
        .update('landingInfo', v => {
          if (!isRequiredLandingInfo) return undefined
          if (hasSkanProtocolInfo) return undefined

          const { landingType, url } = v || {}

          if (landingType !== LandingSchemeEnum.Type.AD_VIEW) {
            if (landingType === LandingSchemeEnum.Type.URL) {
              return v?.set('url', url?.trim())
            }
            return v?.remove('adViewItem')
          }
          return v
        })
        .update('beginDateTime', () => {
          if (campaignType !== CampaignTypeEnum.Type.DAUM_SHOPPING)
            return undefined
          const { beginDate, beginHour, beginMinute } = executiveTime
          const { noSchedule } = creativeForm
          return noSchedule ||
            isUndefinedOrNull(beginDate) ||
            isUndefinedOrNull(beginHour) ||
            isUndefinedOrNull(beginMinute)
            ? null
            : localDateTimeToString(
                moment(beginDate).set({
                  hour: beginHour,
                  minute: beginMinute,
                })
              )
        })
        .update('endDateTime', () => {
          if (campaignType !== CampaignTypeEnum.Type.DAUM_SHOPPING)
            return undefined
          const { endDate, endHour, endMinute } = executiveTime
          const { noSchedule } = creativeForm
          return noSchedule ||
            isUndefinedOrNull(endDate) ||
            isUndefinedOrNull(endHour) ||
            isUndefinedOrNull(endMinute)
            ? null
            : localDateTimeToString(
                moment(endDate).set({
                  hour: endHour,
                  minute: endMinute,
                })
              )
        })
        .set(
          'opinionProof',
          fromJS(CreativeHelper.OpinionProof.toAPI(opinionProof))
        )
        .set(
          'description',
          CreativeFormatEnum.Type.IMAGE_BOX ? s.get('description') : null
        )
        .set(
          'messageElement',
          CreativeFormatEnum.isMessage(creativeFormat)
            ? fromJS(
                TalkChannelMessageHelper.toTrimForm(
                  creativeMessage
                    .set('isCompleted', true) // 새 소재 -> 설정완료의 경우에는 초기화 방지를 위해서 isCompleted 값을 세팅
                    .set(
                      'thumbnailUrl',
                      TalkChannelMessageHelper.getThumbnailUrl(
                        // 소재 생성시에 소재 form -> 설정완료 리스트로 이동 시에 thumbnailUrl 설정
                        creativeMessage
                      )
                    )
                )
              )
            : undefined
        )
        .update(creativeForm => Trim(creativeForm, CREATIVE_TRIM_KEY_PATH))
        .update('frequencyCap', prevFrequencyCap =>
          CampaignTypeEnum.isElectionCampaignType(campaignType)
            ? FrequencyCap
            : prevFrequencyCap
        )
        .set('trackingUrl', s.get('trackingUrl')?.trim() || null)
        .set('clickTrackerUrl', s.get('clickTrackerUrl')?.trim() || null)
        .set('eventTrackerUrl', s.get('eventTrackerUrl')?.trim() || null)
        .set(
          'compatiblePcLandingUrl',
          s.get('compatiblePcLandingUrl')?.trim() || null
        )
        .set(
          'skanCustomProductPageId',
          s.get('skanCustomProductPageId')?.trim() || null
        )
        .set('catalogSegment', isSegment || false)
        .set('parentCatalogId', isSegment ? originalCatalogId : null)
        .set('actionButtonText', s.get('actionButtonText')?.trim() || null)
    )

    dispatch(initCreativeValidation()) // validation 전에 기존의 validation 결과 초기화

    // validation
    const validationResult = Validation(
      formData,
      getCreativeValidationKeyByFormat({ creativeFormat }),
      getCreativeValidationKeyPathByFormat({ creativeFormat, campaignType }),
      getCreativeValidatorByFormat({ creativeFormat, campaignType }),
      getState,
      setIsValidCreativeByKey,
      dispatch
    )

    if (!validationResult) {
      const {
        creativeV2: {
          common: {
            validationErrorKeys,
            validationExtra: {
              itemAssetGroupsValidationResults,
              assetGroupValidationResults,
              bizBoardBundleValidationResults,
            },
          },
        },
      } = getState()

      const getExtra = validationKey => {
        const invalidItemAssetIndex =
          validationKey === MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS
            ? itemAssetGroupsValidationResults?.findIndex(
                ({ isValid }) => !isValid
              )
            : validationKey ===
              CREATIVE_FORM_VALIDATION_KEY.CATALOG_ASSET_GROUPS
            ? assetGroupValidationResults?.findIndex(({ isValid }) => !isValid)
            : validationKey ===
              CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE
            ? bizBoardBundleValidationResults?.findIndex(
                ({ isValid }) => !isValid
              )
            : undefined

        return invalidItemAssetIndex >= 0 ? invalidItemAssetIndex : undefined
      }

      scrollByValidationKeys(
        validationErrorKeys,
        getCreativeValidationKeyByFormat({ creativeFormat }),
        getExtra
      )

      return
    }

    // 쇼핑박스 소재 생성시 광고그룹이 종료/계약해지 시 팝업
    if (campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING) {
      const { endDate } = executiveTime

      const isAdGroupFinishedOrCanceled =
        adGroupStatus?.some(
          v =>
            v === AdStatusEnum.Type.FINISHED || v === AdStatusEnum.Type.CANCELED
        ) || moment().isAfter(moment(endDate), 'd')

      if (isAdGroupFinishedOrCanceled) {
        dispatch(invalidCreativeByAdGroupStatusAndSchedule())

        return
      }
    }

    const { landingInfo } = formData
    const { landingType, url } = landingInfo || {} // nullable

    try {
      if (isBizBoard) {
        // 비즈보드 각 assetGroup 의 landingUrl 검증.
        await axios.all(
          bizBoardBundles.map(bizBoardBundle =>
            dispatch(
              validateBizBoardBundleLandingUrl({ adAccountId, bizBoardBundle })
            )
          )
        )

        // 비즈보드 대표 landingUrl 검증.
        if (landingType === LandingSchemeEnum.Type.URL) {
          try {
            await api.common.validateUrl(adAccountId, url)

            dispatch(
              setIsValidCreativeByKey(
                CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME,
                true,
                ''
              )
            )
          } catch (e) {
            dispatch(
              setIsValidCreativeByKey(
                CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME,
                false,
                CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL
              )
            )
          } finally {
            dispatch(hideLoading())
          }
        }
      } else if (CreativeConstraints.isLandingURLRequired({ campaignType })) {
        /**
         * landing url 검증이 필요한 목적에 대해서만 url api 로 포맷 검증을 수행한다.
         * validation key 가 소재 타입마다 다르므로 'LANDING_URL' 텍스트로 찾아낸다.
         * - LANDING_URL, LANDING_URL... 등
         */
        dispatch(showLoading())

        const { pcLandingUrl, mobileLandingUrl, rspvLandingUrl } = formData

        try {
          await axios.all(
            [rspvLandingUrl, pcLandingUrl, mobileLandingUrl]
              .filter(Boolean)
              .map(url => api.common.validateUrl(adAccountId, url))
          )

          dispatch(
            setIsValidCreativeByKey(
              CREATIVE_FORM_VALIDATION_KEY.LANDING_URL,
              true,
              ''
            )
          )
        } catch (e) {
          dispatch(
            setIsValidCreativeByKey(
              CREATIVE_FORM_VALIDATION_KEY.LANDING_URL,
              false,
              CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL
            )
          )
        } finally {
          dispatch(hideLoading())
        }
      } else if (CreativeFormatEnum.isMessage(creativeFormat)) {
        // 메시지의 경우에 스팸필터 적용
        const { messageElement } = formData

        dispatch(showLoading())

        try {
          const response = await api.message.validateSpamMessage(
            adAccountId,
            TalkChannelMessageHelper.toAPI(messageElement)
          )

          const { isFiltered = false } = response?.data || {}

          if (isFiltered) {
            const spamValidationResult =
              TalkChannelMessageHelper.Spam.convertSpamResult({
                spamResult: response.data,
                creativeFormat,
                messageForm: creativeMessage,
              })
            dispatch(setCreativeMessageSpamValidation(spamValidationResult))
            dispatch(showSpamFilterPopUp())
            dispatch(hideLoading())

            return
          }

          dispatch(hideLoading())
        } catch (e) {
          console.log(e.message)
        }
      }

      dispatch(setCreativeCreateFormToCompleted(List.of(formData)))
      dispatch(initCreativeCreateForm())
      dispatch(initCreativeLandingURLViewState())

      const { formUUID } = formData

      if (formUUID) {
        dispatch(deleteCreativeCompletedValidationErrorByFormUUID(formUUID))
      }
    } catch (e) {
      console.log(e.message)
    }
  }
}

const checkIsFormDirty =
  ({ onOK }) =>
  (dispatch, getState) => {
    const {
      creativeV2: {
        create: {
          creativeForm: { creativeFormat },
        },
      },
    } = getState()

    if (checkNotEmpty(creativeFormat)) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy
            primaryButtonFunc={() => dispatch(onOK())}
            hasSecondaryButton={true}>
            <strong className="tit_layer">설정 미완료 소재 미저장</strong>
            <p className="txt_layer">
              설정이 완료되지 않은 소재는 등록되지 않습니다.
            </p>
          </PopupProxy>
        )
      )
    } else {
      dispatch(onOK())
    }
  }

const checkEmptyLandingTypeInNotSkanType =
  ({ onOK }) =>
  (dispatch, getState) => {
    const {
      creativeV2: {
        common: {
          hasSkanType,
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
        create: { completedItems },
      },
    } = getState()

    if ([CampaignTypeEnum.Type.TALK_BIZ_BOARD].includes(campaignType)) {
      /**
       * 조건
       *  - skan 미설정 그룹 하위 소재 불러오기 > landingInfo 값이 설정되지 않은 경우 validation
       *  - skan 설정 그룹 하위 소재 불러오기 > skan 설정/미설정 소재 불러오기 > landingInfo 값은 null 이므로 pass
       */
      const isValidLandingType = completedItems.every(({ landingInfo }) =>
        checkNotEmpty(landingInfo?.get('landingType'))
      )

      if (!hasSkanType && !isValidLandingType) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                소재의 랜딩 URL이 설정되지 않았습니다.
              </p>
            </PopupProxy>
          )
        )
      } else {
        dispatch(onOK())
      }
    } else {
      dispatch(onOK())
    }
  }

const checkIsCompletedItemEmpty =
  ({ onOK }) =>
  (dispatch, getState) => {
    const {
      creativeV2: {
        create: { completedItems },
      },
    } = getState()

    if (!completedItems || completedItems.isEmpty()) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">소재 저장 불가</strong>
            <p className="txt_layer">설정완료된 소재가 없습니다.</p>
          </PopupProxy>
        )
      )
    } else {
      dispatch(onOK())
    }
  }

const checkIsExceedCreativeLimit =
  ({ adAccountId, adGroupId, onOK }) =>
  async (dispatch, getState, api) => {
    const {
      creativeV2: {
        create: { completedItems },
        common: {
          campaign: {
            campaignTypeGoal: { campaignType, goal },
          },
          adGroup: { creativeOptimization, smartMessage },
        },
      },
    } = getState()

    const completedItemsSize = completedItems.count()

    if (
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
      goal === GoalEnum.Type.REACH
    ) {
      // TALK_CHANNEL + REACH 소재 생성 시의 제약
      try {
        dispatch(showLoading())

        const creativeCountResponse =
          await api.adGroup.fetchCreativeCountByAdGroupId(
            adAccountId,
            adGroupId
          )
        const creativeCount = creativeCountResponse.data.count || 0
        const totalCreativeCount = creativeCount + completedItemsSize
        const messageCreativeLimit = getMessageCreativeLimit(
          creativeOptimization,
          smartMessage
        )

        if (totalCreativeCount > messageCreativeLimit) {
          dispatch(
            openPopupByProxy(
              POPUP_KEY.SIMPLE_POPUP,
              <PopupProxy>
                <strong className="tit_layer">소재 제한 수 초과</strong>
                <p className="txt_layer">
                  스마트메시지를 설정
                  {creativeOptimization || smartMessage
                    ? '한'
                    : '하지 않은'}{' '}
                  광고그룹의
                  <br />
                  메시지 소재는 그룹 당{' '}
                  {creativeOptimization
                    ? `최대 ${messageCreativeLimit}개까지`
                    : `${messageCreativeLimit}개만`}{' '}
                  등록 가능합니다.
                </p>
              </PopupProxy>
            )
          )
          return
        }
      } catch (e) {
        console.log(e.message)
      } finally {
        dispatch(hideLoading())
      }
    }

    dispatch(onOK())
  }

const checkIsImmediateSending =
  ({ onOK }) =>
  (dispatch, getState) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType, goal },
          },
          adGroup: {
            schedule: { beginDate, beginTime },
          },
        },
      },
    } = getState()

    if (
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
      goal === GoalEnum.Type.REACH
    ) {
      const immediateSending = moment(`${beginDate}T${beginTime}`).isBefore(
        moment()
      )

      if (immediateSending) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy
              primaryButtonFunc={() => dispatch(onOK())}
              hasSecondaryButton={true}>
              <strong className="tit_layer">메시지 발송 확인</strong>
              <p className="txt_layer">
                이미 시작일이 지나 발송이 가능한 기간입니다.
                <br />
                광고그룹 상태에 따라 소재 저장 시<br />
                즉시 메시지가 발송될 수 있습니다.
                <br />
                계속하시겠습니까?
              </p>
            </PopupProxy>
          )
        )

        return
      }
    }

    dispatch(onOK())
  }

export function submitCreativeCreateForm(adAccountId, campaignId, adGroupId) {
  return async (dispatch, getState, api) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
          adGroup,
        },
        create: { completedItems },
      },
    } = getState()

    const onSubmit = () => async dispatch => {
      const creatives = completedItems
        .flatMap(creative =>
          CreativeHelper.toCreateRequestForm({
            creative,
            adGroup,
            campaignType,
          })
        )
        .filter(Boolean)
        .toJS()

      dispatch(closeAllPopup())
      dispatch(showLoading())

      try {
        await api.adCreative.createAdCreatives(adAccountId, {
          adGroupId,
          adGroupTO: adGroup.toJS(),
          creatives,
        })

        // 소재 테이블 정렬 변경(시스템).
        dispatch(
          setDashboardTableSortBySystemTemporary({
            dashboardType: DashboardEnumV2.Type.CREATIVE,
            sortKey: COLUMN_SPEC.SORT_KEY.ID,
            sortDirection: SortEnum.Type.DESC,
          })
        )

        dispatch(RouterV2.push(DashboardRouter.Path.Prev(adAccountId)))
      } catch (e) {
        const { errorCode, message, detail } = e?.response?.data || {}

        dispatch(
          handleCreativeFormExceptionV2({
            errorCode,
            message,
            detail,
            adAccountId,
            campaignId,
            adGroupId,
          })
        )
      } finally {
        dispatch(hideLoading())
      }
    }

    await dispatch(
      checkEmptyLandingTypeInNotSkanType({
        onOK: () =>
          checkIsFormDirty({
            onOK: () =>
              checkIsCompletedItemEmpty({
                onOK: () =>
                  checkIsExceedCreativeLimit({
                    adAccountId,
                    adGroupId,
                    onOK: () => checkIsImmediateSending({ onOK: onSubmit }),
                  }),
              }),
          }),
      })
    )
  }
}

/**
 * 불러온 각 소재의 form 을 '설정 완료 리스트' 에 맞도록 converting
 */
export function saveCreativeImportToCompleted(creatives) {
  return (dispatch, getState) => {
    if (creatives.size > 0) {
      const {
        creativeV2: {
          common,
          create: { completedItems },
        },
      } = getState()

      const {
        campaign: {
          campaignTypeGoal: { goal, campaignType },
        },
        campaign,
        adGroup: { pricingType },
        adGroup,
      } = common

      const completedCreatives = creatives
        .map(creative =>
          CreativeHelper.importToCompleted({
            creative,
            campaign,
            adGroup,
            common,
          })
        )
        .filter(Boolean)

      if (completedCreatives.count() > 0) {
        const confirm = () => {
          dispatch(setCreativeCreateFormToCompleted(completedCreatives))
        }

        const hasShortFormImage = completedCreatives.some(
          ({ creativeFormat, width, height }) =>
            creativeFormat === CreativeFormatEnum.Type.IMAGE_NATIVE &&
            CreativeFormatEnum.isRatio9To16Image({ width, height })
        )

        if (hasShortFormImage) {
          if (
            goal === GoalEnum.Type.VISITING &&
            pricingType !== PricingTypeEnum.Type.CPM
          ) {
            dispatch(
              openPopupByProxy(
                POPUP_KEY.SIMPLE_POPUP,
                notice9To16CreativePricingTypeConstraintDialog()
              )
            )
          } else if (goal === GoalEnum.Type.CONVERSION) {
            dispatch(
              openPopupByProxy(
                POPUP_KEY.SIMPLE_POPUP,
                notice9To16CreativeGoalTypeConstraintDialog()
              )
            )
          }
        }

        confirm()
      }
    } else {
      showErrorMessage('선택된 소재가 없습니다.')
    }
  }
}

/**
 * 불러온 각 메시지 템플릿을 '설정 완료 리스트' 에 맞도록 converting
 */
export function saveMessageTemplateImportToCompleted(messageTemplates) {
  return dispatch => {
    if (messageTemplates.size > 0) {
      const completedMessageCreatives = messageTemplates.map(messageTemplate =>
        TalkChannelMessageHelper.creativeImportToCompleted({
          messageForm: messageTemplate,
        })
      )

      if (completedMessageCreatives.count() > 0) {
        dispatch(setCreativeCreateFormToCompleted(completedMessageCreatives))
      }
    } else {
      showErrorMessage('선택된 메시지 소재가 없습니다.')
    }
  }
}

export function clearCreativeCreate() {
  return {
    type: CreativeCreate.CLEAR,
  }
}

export function changeCreativeCreateShoppingExecutiveTimeByKey(key, value) {
  return {
    type: CreativeCreate.CHANGE_SHOPPING_EXECUTIVE_TIME,
    key,
    value,
  }
}

export function setCreativeCreateShoppingExecutiveTime(
  adGroupSchedule,
  adGroupStatus
) {
  return {
    type: CreativeCreate.SET_SHOPPING_EXECUTIVE_TIME,
    adGroupSchedule,
    adGroupStatus,
  }
}

export function initCreativeCreateForm() {
  return dispatch => {
    dispatch(initAllCreativeCreateForm())
    dispatch(initSelectedCreativeCompletedItem())
    dispatch(initCreativeValidation())
    dispatch(initCreativeMessageForm())
    dispatch(clearOpinionProof())
    dispatch(clearDynamicCatalog()) // 다이나믹 카탈로그의 경우 모먼트 외 쇼케이스 정보를 별도로 들고있기 때문에 creativeForm 과 별개로 clear 해준다.
    dispatch(clearProfileFullViewTab()) // 프로필 풀뷰 탭 초기화
    dispatch(clearNativeImageCreate()) // 네이티브 이미지 초기화
  }
}
