import PopupProxy from '../../Popup/Common/PopupProxy'
import React from 'react'

const adAccountResumeWarningForTalkChannelReachDialog = ({
  onOK,
  onCancel,
}) => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onOK}
      onDismiss={onCancel}>
      <strong className="tit_layer">사용자 ON 영향 확인</strong>
      <p className="txt_layer">
        광고계정 ON 시 아래 광고 캠페인이 영향을 받습니다.
      </p>
      <div className="adbox_scroll">
        <div className="inner_adbox_scroll">
          <strong className="tit_adbox">[카카오톡 채널 유형]</strong>
          <p className="txt_adbox">일시 중지된 메시지가 발송 재개됩니다.</p>
        </div>
      </div>
      <p className="txt_layer">계속하시겠습니까?</p>
    </PopupProxy>
  )
}

const adAccountPauseWarningDialog = ({
  onOK,
  onCancel,
  hasTalkChannelReach,
  hasDaumShoppingReach,
  hasTalkBizBoardReserved,
  hasTalkBizBoardChatTabCpt,
  hasPersonalMessageReach,
  hasElection2024,
  hasPcTalkBottom,
  hasPcTalkRichPop,
  hasFocusFullView,
  hasProfileFullView,
}) => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onOK}
      onDismiss={onCancel}>
      <strong className="tit_layer">사용자 OFF 영향 확인</strong>
      <p className="txt_layer">
        광고계정 OFF 시 아래 광고 캠페인이 영향을 받습니다.
      </p>
      <div className="adbox_scroll">
        <div className="inner_adbox_scroll">
          {hasTalkChannelReach && (
            <>
              <strong className="tit_adbox">[카카오톡 채널 유형]</strong>
              <p className="txt_adbox">
                발송예정/발송중인 메시지가 일시 중지됩니다.
              </p>
            </>
          )}
          {hasDaumShoppingReach && (
            <>
              <strong className="tit_adbox">[다음쇼핑 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 일 단위로 계속 과금됩니다.
              </p>
            </>
          )}
          {hasTalkBizBoardReserved && (
            <>
              <strong className="tit_adbox">[카카오 비즈보드 CPT 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다.
              </p>
            </>
          )}
          {hasTalkBizBoardChatTabCpt && (
            <>
              <strong className="tit_adbox">
                [카카오 비즈보드 채팅탭 CPT 유형]
              </strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다.
              </p>
            </>
          )}
          {hasPersonalMessageReach && (
            <>
              <strong className="tit_adbox">[개인화 메시지 유형]</strong>
              <p className="txt_adbox">
                발송중인 메시지가 있는 경우 모두 발송 취소됩니다.
              </p>
            </>
          )}
          {hasElection2024 && (
            <>
              <strong className="tit_adbox">
                [제22대 국회의원 선거 광고 유형]
              </strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다.
              </p>
            </>
          )}
          {hasPcTalkBottom && (
            <>
              <strong className="tit_adbox">[포커스 보드 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다.
              </p>
            </>
          )}
          {hasPcTalkRichPop && (
            <>
              <strong className="tit_adbox">[리치팝 올데이 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다.
              </p>
            </>
          )}
          {hasFocusFullView && (
            <>
              <strong className="tit_adbox">[포커스 풀뷰 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다.
              </p>
            </>
          )}
          {hasProfileFullView && (
            <>
              <strong className="tit_adbox">[프로필 풀뷰 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다.
              </p>
            </>
          )}
        </div>
      </div>
      <p className="txt_layer">계속하시겠습니까?</p>
    </PopupProxy>
  )
}

export {
  adAccountResumeWarningForTalkChannelReachDialog,
  adAccountPauseWarningDialog,
}
