import React from 'react'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  DATE_PRESET_KEY,
  dateToString,
  keyMirror,
  selectedDatePickerValue,
} from '../../utils/utils'
import { getAdAccountInfoById } from '../adaccount/mAdAccount'
import {
  checkEmail,
  checkNotEmpty,
  checkStringLength,
} from '../../utils/regexUtils'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import { openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import CalendarEnum from '../../enums/CalendarEnum'
import moment from 'moment'
import Calendar from '../../utils/calendar'
import { DELEGATION_STATUS_TYPE } from '../../utils/setting/invoice'
import { hideLoading, showLoading } from '../common/mLoading'

const defaultDate = selectedDatePickerValue(DATE_PRESET_KEY.RECENT_ONE_MONTH)

const Invoice = keyMirror({
  SET_INVOICE_DOMESTIC_LIST: null,
  SET_INVOICE_OVERSEAS_LIST: null,
  SET_INVOICE_SEARCH_OPTION_BY_VALUES: null,
  SET_INVOICE_EMAIL: null,
  INIT_INVOICE_DOMESTIC_STATE: null,
  CHANGE_CALENDAR_INVOICE: null,
  UPDATE_CALENDAR_INVOICE_START_DATE_AT_LEAST: null,
  SET_INVOICE_HISTORY_LIST: null,
})

const initialCalendarState = {
  startDate: moment().subtract(6, 'days'),
  endDate: moment(),
  periodType: CalendarEnum.Preset.Type.LAST7DAYS,
  customDateDiff: null,
}

const initialState = fromJS({
  invoiceEmail: '',
  overseasInvoice: {},
  domesticInvoice: {
    list: {},
    searchOptions: {
      issueStartDt: defaultDate.from,
      issueEndDt: defaultDate.to,
      page: 0,
      size: 10,
      dspId: 'KAKAOMOMENT',
      issueStatuses: 'SUCCESS,FAIL',
    },
  },
  calendar: {
    domesticInvoice: initialCalendarState,
  },
  invoiceHistoryList: {
    content: [],
    number: 0,
    totalElements: 0,
  },
})

export default createReducer(initialState, {
  [Invoice.SET_INVOICE_DOMESTIC_LIST]: (state, { data }) =>
    state.setIn(['domesticInvoice', 'list'], fromJS(data || {})),

  [Invoice.SET_INVOICE_SEARCH_OPTION_BY_VALUES]: (state, { values }) =>
    state.mergeIn(['domesticInvoice', 'searchOptions'], fromJS(values)),

  [Invoice.INIT_INVOICE_DOMESTIC_STATE]: state => state.merge(initialState),

  [Invoice.SET_INVOICE_OVERSEAS_LIST]: (state, { list }) =>
    state.set('overseasList', fromJS(list || [])),

  [Invoice.SET_INVOICE_EMAIL]: (state, { email }) =>
    state.set('invoiceEmail', email),

  [Invoice.CHANGE_CALENDAR_INVOICE]: (
    state,
    { dataKey, startDate, endDate, periodType, updateCustomDateDiff = false }
  ) => {
    return state.withMutations(s => {
      s.setIn(['calendar', dataKey, 'startDate'], startDate)
        .setIn(['calendar', dataKey, 'endDate'], endDate)
        .setIn(['calendar', dataKey, 'periodType'], periodType)
        .updateIn(['calendar', dataKey, 'customDateDiff'], v =>
          updateCustomDateDiff && periodType === CalendarEnum.Preset.Type.CUSTOM
            ? Math.abs(moment(startDate).diff(moment(endDate), 'd'))
            : v
        )
    })
  },

  [Invoice.UPDATE_CALENDAR_INVOICE_START_DATE_AT_LEAST]: (state, { least }) =>
    state.withMutations(s =>
      s.updateIn(['calendar', 'domesticInvoice', 'startDate'], prev =>
        Calendar.coerceAtLeast(moment(prev), moment(least))
      )
    ),

  [Invoice.SET_INVOICE_HISTORY_LIST]: (state, { result }) =>
    state.set('invoiceHistoryList', fromJS(result)),
})

export const initDomesticInvoice = () => ({
  type: Invoice.INIT_INVOICE_DOMESTIC_STATE,
})

const setDomesticInvoiceList = data => ({
  type: Invoice.SET_INVOICE_DOMESTIC_LIST,
  data,
})

const setDomesticInvoiceSearchOptionByValues = values => ({
  type: Invoice.SET_INVOICE_SEARCH_OPTION_BY_VALUES,
  values,
})

export function changeDomesticInvoiceSearchOption(adAccountId, values) {
  return dispatch => {
    dispatch(setDomesticInvoiceSearchOptionByValues(values))
    dispatch(fetchInvoiceList(adAccountId))
  }
}

export function fetchInvoiceList(adAccountId) {
  return (dispatch, getState, api) => {
    const {
      invoice: {
        domesticInvoice: {
          searchOptions: { page, size, dspId, issueStatuses },
        },
        calendar: {
          domesticInvoice: { startDate, endDate },
        },
      },
    } = getState()

    const searchOptions = {
      page,
      size,
      dspId,
      issueStatuses,
      issueStartDt: dateToString(startDate),
      issueEndDt: dateToString(endDate),
    }

    return api.invoice
      .fetchInvoiceList(adAccountId, searchOptions)
      .then(response => {
        dispatch(setDomesticInvoiceList(response.data))
      })
      .catch(e => console.log(e.message))
  }
}

export function setInvoiceEmail(email) {
  return {
    type: Invoice.SET_INVOICE_EMAIL,
    email,
  }
}

function setInvoiceHistoryList(result) {
  return {
    type: Invoice.SET_INVOICE_HISTORY_LIST,
    result,
  }
}

/**
 * 3.27 세금계산서 이메일 수정 요청
 * @param adAccountId
 * @param email
 * @param onSuccess
 * @returns {function(*, *, *)}
 */
export function changeTaxBillEmail(adAccountId, email, onSuccess) {
  return async (dispatch, getState, api) => {
    if (!checkEmail(email.trim())) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">이메일 수정</strong>
            <p className="txt_layer">올바른 이메일 형식을 입력하세요.</p>
          </PopupProxy>
        )
      )
    } else if (!checkStringLength(email.trim(), 5, 50)) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">이메일 수정</strong>
            <p className="txt_layer">
              이메일은 5자에서 50자까지 입력 가능합니다.
            </p>
          </PopupProxy>
        )
      )
    } else {
      try {
        dispatch(showLoading())

        await api.adAccount.modifyTaxBillEmail(adAccountId, { email })
        await dispatch(getAdAccountInfoById(adAccountId))

        dispatch(setInvoiceEmail(email))
        checkNotEmpty(onSuccess) && onSuccess()
      } catch (e) {
        console.log(e.message)
      } finally {
        dispatch(hideLoading())
      }
    }
  }
}

/**
 * 세금계산서 조회후 팝업
 * @param adAccountId
 * @param taxBillId
 * @param onSuccess
 * @returns {function(*, *, *): *}
 */
export function fetchInvoicePopup(adAccountId, taxBillId, onSuccess) {
  return (dispatch, getState, api) => {
    return api.invoice
      .fetchInvoicePopup(adAccountId, taxBillId)
      .then(response => {
        checkNotEmpty(onSuccess) && onSuccess(response.data)
      })
      .catch(e => console.log(e.message))
  }
}

/**
 * 광고 계정 정보 조회하여 세금계산서 이메일 세팅
 * @param adAccountId
 */
export function fetchDetailAdAccount(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.adAccount.fetchAdAccountInfoByAdAccountId(
        adAccountId
      )
      const { taxBillEmail } = response.data

      if (checkNotEmpty(taxBillEmail)) {
        dispatch(setInvoiceEmail(taxBillEmail))
      }
    } catch (e) {
      console.error(e.message)
    }
  }
}

/***
 * 세금계산서 발행 내역 달력변경
 * @param startDate {Object} - moment object
 * @param endDate {Object} - moment object
 * @param periodType
 * @param updateCustomDateDiff
 * @return
 */
export function changeCalendar(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId, createdDate },
      },
    } = getState()

    dispatch(setDomesticInvoiceSearchOptionByValues({ page: 0 }))

    dispatch(
      changeInvoiceCalendar(
        startDate,
        endDate,
        periodType,
        updateCustomDateDiff
      )
    )
    dispatch(fetchInvoiceList(adAccountId))
  }
}

export function goPrevCalendar() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { createdDate },
      },
      invoice: {
        calendar: {
          domesticInvoice: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )

    dispatch(changeCalendar(start, end, periodType))
  }
}

export function goNextCalendar() {
  return (dispatch, getState) => {
    const {
      invoice: {
        calendar: {
          domesticInvoice: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )

    dispatch(changeCalendar(start, end, periodType))
  }
}

function changeInvoiceCalendar(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff
) {
  return {
    type: Invoice.CHANGE_CALENDAR_INVOICE,
    dataKey: 'domesticInvoice',
    startDate,
    endDate,
    periodType,
    updateCustomDateDiff,
  }
}

function updateCalendarInvoiceStartDateAtLeast(least) {
  return {
    type: Invoice.UPDATE_CALENDAR_INVOICE_START_DATE_AT_LEAST,
    least,
  }
}

export function fetchInvoiceHistoryList(adAccountId, page = 0, size = 10) {
  return (dispatch, getState, api) => {
    return api.adAccount
      .fetchDelegationHistory(adAccountId, { page, size })
      .then(response => {
        dispatch(setInvoiceHistoryList(response.data || {}))
      })
      .catch(e => console.log(e.message))
  }
}

export const getInvoiceHistoryRowText = ({
  delegationStatus: status,
  agencyCompany,
  transactor,
}) => {
  const { name } = agencyCompany || {}

  switch (status) {
    case DELEGATION_STATUS_TYPE.REQUESTED: {
      return `${name}의 위임발행 요청이 있습니다.`
    }

    case DELEGATION_STATUS_TYPE.COMPLETED: {
      return `${name}의 위임발행 요청을 수락하였습니다.`
    }

    case DELEGATION_STATUS_TYPE.REJECTED: {
      return `${name}의 위임발행 요청을 거부하였습니다.`
    }

    case DELEGATION_STATUS_TYPE.RELEASED: {
      if (transactor) {
        return `${name}의 위임발행 상태를 해제하였습니다.`
      } else {
        return `${name}의 위임발행 상태가 영업권 해제로 자동 해제되었습니다.`
      }
    }

    case DELEGATION_STATUS_TYPE.RELEASED_BY_SYSTEM: {
      return `${name}의 위임발행 상태가 에이전시 마케터 장기 휴면에 의한 영업권 해제로 자동 해제되었습니다.`
    }

    case DELEGATION_STATUS_TYPE.FAILED: {
      return '위임발행 요청이 있었으나 시스템 이유로 실패하였습니다.'
    }

    default: {
      return ''
    }
  }
}

export function updateCalendarInvoiceStartDate(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const adAccountResponse =
        await api.adAccount.fetchAdAccountInfoByAdAccountId(adAccountId)

      const adAccount = adAccountResponse?.data || {}

      dispatch(updateCalendarInvoiceStartDateAtLeast(adAccount.createdDate))
    } catch (e) {
      console.error(e.message)
    }
  }
}
