import CreativeTemplateEnum from '../../enums/CreativeTemplateEnum'
import { fromJS, List, Map } from 'immutable'
import CreativeConstraints from '../constraints/constraints-creative'
import { v4 as uuid } from 'uuid'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import {
  BizBoardBundleModel,
  BizBoardLandingInfo,
  PcTalkBottomBundleModel,
} from '../../models/model-creative'
import {
  createCreativeImageGroupId,
  CREATIVE_CREATE_PROFILE_KEY,
} from '../advertise/creativeImage'
import moment from 'moment'
import AdStatusEnum from '../../enums/AdStatusEnum'
import { replaceCurrentTimeToBaseTime } from '../../components/Common/HourMinuteSelector/HourMinuteSelectorHOC'
import { checkNotEmpty, isUndefinedOrNull } from '../regexUtils'
import { localDateTimeToString } from '../utils'
import { TalkChannelMessageHelper } from './helper-talkChannelMessage'
import { validateBizBoardExpandableAsset } from '../../validators/advertise/creativeV2/bizBoardBannerFormValidatorV2'
import BizBoardSubTypeEnum from '../../enums/BizBoardSubTypeEnum'
import ActionButtonEnum from '../../enums/ActionButtonEnum'
import GoalEnum from '../../enums/GoalEnum'

export const CreativeHelper = {
  Image: {
    /**
     * tenth response | view model --> core creative model(API payload)
     * - mimeType, fileSize, downloadUrl, md5sum, width, height, originalFileName
     */
    toAPI(image) {
      if (!image) return image

      const imageJS = Map.isMap(image) ? image.toJS() : image
      const { downloadUrl, md5sum, width, height, fileName, originalFileName } =
        imageJS || {}

      /**
       * adAccountId, fileSize, hash, id, imageHash, imageWidth, imageHeight, mimeType, originalFileName, type, url, value
       *
       */
      return {
        url: downloadUrl,
        imageHash: md5sum,
        imageWidth: width,
        imageHeight: height,
        fileName: originalFileName,
        originalFileName: fileName,
        ...imageJS,
      }
    },

    /**
     * - mimeType, fileSize, url, imageHash, imageWidth, imageHeight, originalFileName
     */
    toView(image) {
      if (!image) return image

      const imageJS = Map.isMap(image) ? image.toJS() : image

      const {
        url,
        imageHash,
        imageWidth,
        imageHeight,
        fileName,
        originalFileName,
      } = imageJS || {}

      return {
        downloadUrl: url,
        md5sum: imageHash,
        width: imageWidth,
        height: imageHeight,
        fileName: originalFileName,
        originalFileName: fileName,
        // for IMAGE_NATIVE
        templateType: CreativeTemplateEnum.getByImageSize(
          imageWidth,
          imageHeight
        ),
        ...imageJS,
      }
    },
  },

  Video: {
    /**
     * - mimeType, fileSize, downloadUrl, md5sum, transCodeIds, duration
     */
    toAPI(video) {
      if (!video) return video

      const videoJS = Map.isMap(video) ? video.toJS() : video
      const { downloadUrl, md5sum, transCodeIds, fileName, originalFileName } =
        videoJS || {}

      return {
        url: downloadUrl,
        videoHash: md5sum,
        presetIds: transCodeIds,
        fileName: originalFileName,
        originalFileName: fileName,
        ...videoJS,
      }
    },

    /**
     * - video: mimeType, fileSize, videoHash, preview, url, presetIds, duration
     */
    toView(video) {
      if (!video) return video

      const videoJS = Map.isMap(video) ? video.toJS() : video

      const { videoHash, url, presetIds, fileName, originalFileName } =
        videoJS || {}

      return {
        md5sum: videoHash,
        downloadUrl: url,
        transCodeIds: presetIds,
        encodingPercent: 100,
        fileName: originalFileName,
        originalFileName: fileName,
        ...videoJS,
      }
    },
  },

  OpinionProof: {
    toAPI(opinionProof, isModify, prevOpinionProof) {
      if (!opinionProof) return opinionProof

      const opinionProofJS = Map.isMap(opinionProof)
        ? opinionProof.toJS()
        : opinionProof

      if (!isModify) {
        opinionProofJS.opinionProofFileList.map(v => (v.isUploaded = true))
      }

      return opinionProofJS.opinion === '' &&
        opinionProofJS.opinionProofFileList.length === 0
        ? isModify && prevOpinionProof && prevOpinionProof?.opinion !== ''
          ? { ...opinionProofJS, opinion: opinionProofJS.opinion }
          : null
        : opinionProofJS
    },
  },

  Name: {
    toSizePostFix(name, image) {
      const { imageWidth, imageHeight } = image
      return `${name}_${imageWidth}x${imageHeight}`
    },
  },

  // 저장 요청 API 포맷으로 변경
  toCreateRequestForm({ creative, adGroup, campaignType }) {
    const {
      // bizBoard
      bizBoardBundles,
      landingInfo,
      expandableType: repExpandableType,
      expandableAssetGroups: repExpandableAssetGroups,
      boardAssetGroup,
      boardType,

      // catalog
      assetGroups,
      trackingUrl,

      // image banner, native, image box
      uploadedMultiImages,

      // profile image
      uploadedImages,

      // image box
      beginDateTime,
      endDateTime,

      pcTalkBottomBundles,

      // video
      uploadedVideo,
      autoThumbnail,
      uploadThumbnail,

      // message
      messageElement,
      saveMessageTemplate,

      // service content
      serviceContent,

      // dynamic catalog
      catalogProductSetId,

      // rich native
      actionButtonFontColor,
      actionButtonBackgroundColor,
      richPopImage,
      bannerImage,
      compatibleTitle,
      compatibleImage,
      compatiblePcLandingUrl,

      // SKAN
      skanCustomProductPageId,

      // profile full view
      textImage,

      // common
      title,
      profileName,
      description,
      actionButton,
      actionButtonText,
      rspvLandingUrl,
      pcLandingUrl,
      mobileLandingUrl,
      altText,
      frequencyCap,
      name,
      adGroupId,
      creativeFormat,
      plusFriendButton,
      useDefaultLandingUrl,
      useDefaultProfileImage,
      opinionProof,
      backgroundColor,
      bizBoardNativeServing,
      clickTrackerUrl,
      eventTrackerUrl,
      catalogSegment,
      parentCatalogId,
    } = creative

    switch (creativeFormat) {
      case CreativeFormatEnum.Type.IMAGE_BANNER: {
        if (
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
        ) {
          const { skanProtocolInfo } = adGroup || {}
          const { appId } = skanProtocolInfo || {}
          const hasSkanType = checkNotEmpty(appId)

          return bizBoardBundles
            .filter(({ image }) => !!image)
            .reduce(
              (
                creativeArr,
                {
                  image,
                  landingInfo: bundleLandingInfo,
                  useDefaultLandingInfo,

                  useDefaultTrackerUrl,
                  clickTrackerUrl: bundleClickTrackerUrl,
                  eventTrackerUrl: bundleEventTrackerUrl,

                  // expandable
                  useDefaultExpandable,
                  expandableType: bundleExpandableType,
                  expandableAssetGroups: bundleExpandableAssetGroups,
                  bizBoardNativeImage,
                }
              ) => {
                const { imageWidth, imageHeight, expressMetaInfo } = image

                const expandableType = useDefaultExpandable
                  ? repExpandableType
                  : bundleExpandableType

                const expandableAssetGroups = useDefaultExpandable
                  ? repExpandableAssetGroups
                  : bundleExpandableAssetGroups

                // import -> completed 로 온 케이스를 위해 validation 추가 수행
                const isExpandableAssetValid = validateBizBoardExpandableAsset({
                  expandableType,
                  expandableAssetGroups,
                })

                const isSpecialBoard =
                  BizBoardSubTypeEnum.isSpecialBizBoard(boardType)

                const { expressMetaInfoAsset } = expressMetaInfo || {}
                const {
                  title: expressMetaInfoAssetTitle,
                  description: expressMetaInfoAssetDescription,
                } = expressMetaInfoAsset || ''

                return creativeArr.concat({
                  image,
                  rspvLandingUrl,
                  pcLandingUrl,
                  mobileLandingUrl,
                  landingInfo: hasSkanType
                    ? null
                    : useDefaultLandingInfo
                    ? landingInfo // representative
                    : bundleLandingInfo, // bundle
                  clickTrackerUrl: useDefaultTrackerUrl
                    ? clickTrackerUrl
                    : bundleClickTrackerUrl,
                  eventTrackerUrl: useDefaultTrackerUrl
                    ? eventTrackerUrl
                    : bundleEventTrackerUrl,
                  altText,
                  frequencyCap,
                  name: `${name}_${
                    isSpecialBoard ? `1290x258` : `${imageWidth}x${imageHeight}`
                  }`,
                  adGroupId,
                  creativeFormat,
                  plusFriendButton,
                  useDefaultLandingUrl,
                  backgroundColor,
                  opinionProof,
                  /**
                   * 비즈보드 편집 데이터
                   * - 배너 생성 시 redux state 의 uploadedMultiImages 항목 내에 위치
                   * - 배너 수정 시에는 creativeForm 에 위치
                   */
                  expressMetaInfos: expressMetaInfo
                    ? [expressMetaInfo]
                    : undefined,
                  /**
                   * expandable 최종 유효성 검증 & 필터링.
                   */
                  expandableType: isExpandableAssetValid
                    ? expandableType
                    : undefined,
                  expandableAssetGroups: isExpandableAssetValid
                    ? expandableAssetGroups
                    : undefined,
                  boardAssetGroup: isSpecialBoard ? boardAssetGroup : null,
                  boardType,
                  bizBoardNativeServing:
                    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD
                      ? bizBoardNativeServing
                      : undefined,
                  bizBoardNativeImage: bizBoardNativeServing
                    ? bizBoardNativeImage
                    : undefined,
                  actionButton: bizBoardNativeServing
                    ? ActionButtonEnum.Type.LINK
                    : undefined,
                  title: bizBoardNativeServing
                    ? expressMetaInfoAssetTitle
                    : undefined,
                  description: bizBoardNativeServing
                    ? expressMetaInfoAssetDescription
                    : undefined,
                  skanCustomProductPageId,
                })
              },
              []
            )
        } else {
          /**
           * -- before --
           * { SIZE_250x250: [{ image1 }, { image2 }...], SIZE_300x150: [{ image1 }, { image2 }...],...}
           * -- after --
           * [{ form1 }, { form2 }, { form3 }...]
           */
          return uploadedMultiImages.reduce(
            (creativeArr, promotionalImages) => {
              return creativeArr.concat(
                promotionalImages.reduce((imageArr, promotionalImage) => {
                  const { imageWidth, imageHeight, backgroundColor } =
                    promotionalImage

                  return imageArr.concat({
                    image: promotionalImage,
                    rspvLandingUrl,
                    pcLandingUrl,
                    mobileLandingUrl,
                    landingInfo,
                    altText,
                    frequencyCap,
                    name: `${name}_${imageWidth}x${imageHeight}`,
                    adGroupId,
                    creativeFormat,
                    plusFriendButton,
                    useDefaultLandingUrl,
                    backgroundColor,
                    opinionProof,
                  })
                }, [])
              )
            },
            []
          )
        }
      }

      case CreativeFormatEnum.Type.IMAGE_NATIVE: {
        return uploadedMultiImages.reduce((creativeArr, promotionalImages) => {
          return creativeArr.concat(
            promotionalImages.reduce((imageArr, promotionalImage) => {
              const { imageWidth, imageHeight } = promotionalImage
              return imageArr.concat({
                image: promotionalImage,
                profileImage: uploadedImages.get(CREATIVE_CREATE_PROFILE_KEY),
                title,
                profileName,
                description,
                actionButton,
                rspvLandingUrl,
                pcLandingUrl,
                mobileLandingUrl,
                frequencyCap,
                name: `${name}_${imageWidth}x${imageHeight}`,
                adGroupId,
                creativeFormat,
                plusFriendButton,
                useDefaultLandingUrl,
                useDefaultProfileImage,
                opinionProof,
                altText,
                clickTrackerUrl,
                eventTrackerUrl,
                skanCustomProductPageId,
              })
            }, [])
          )
        }, [])
      }

      case CreativeFormatEnum.Type.IMAGE_BOX: {
        return uploadedMultiImages.reduce((creativeArr, promotionalImages) => {
          return creativeArr.concat(
            promotionalImages.reduce((imageArr, promotionalImage) => {
              const { imageWidth, imageHeight } = promotionalImage
              return imageArr.concat({
                image: promotionalImage,
                title,
                description,
                rspvLandingUrl,
                pcLandingUrl,
                mobileLandingUrl,
                beginDateTime,
                endDateTime,
                name: `${name}_${imageWidth}x${imageHeight}`,
                adGroupId,
                creativeFormat,
                opinionProof,
                clickTrackerUrl,
                eventTrackerUrl,
              })
            }, [])
          )
        }, [])
      }

      case CreativeFormatEnum.Type.CATALOG_MANUAL: {
        return [
          {
            profileImage: uploadedImages.get(CREATIVE_CREATE_PROFILE_KEY),
            profileName,
            actionButton,
            rspvLandingUrl,
            pcLandingUrl,
            mobileLandingUrl,
            assetGroups,
            frequencyCap,
            name,
            adGroupId,
            creativeFormat,
            useDefaultLandingUrl,
            useDefaultProfileImage,
            opinionProof,
            clickTrackerUrl,
            eventTrackerUrl,
          },
        ]
      }

      case CreativeFormatEnum.Type.VIDEO_NATIVE: {
        return [
          {
            video: CreativeHelper.Video.toAPI(uploadedVideo),
            image: CreativeHelper.Image.toAPI(
              uploadThumbnail?.count() > 0
                ? uploadThumbnail
                : autoThumbnail || {}
            ), // 맞춤 썸네일이 있을 경우 맞춤 썸네일로, 없을 경우 동영상 업로드시 생성된 프리뷰 이미지 정보로 넣어줘야 함.
            image1: CreativeHelper.Image.toAPI(autoThumbnail), // 동영상 업로드시 생성된 프리뷰 이미지 정보.
            image2: CreativeHelper.Image.toAPI(uploadThumbnail), // 직접 등록한 맞춤 썸네일 이미지.
            profileImage: uploadedImages.get(CREATIVE_CREATE_PROFILE_KEY),
            title,
            profileName,
            description,
            actionButton,
            rspvLandingUrl,
            pcLandingUrl,
            mobileLandingUrl,
            frequencyCap,
            name,
            adGroupId,
            creativeFormat,
            useDefaultLandingUrl,
            useDefaultProfileImage,
            opinionProof,
            clickTrackerUrl,
            eventTrackerUrl,
          },
        ]
      }

      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE:
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
        return [
          {
            messageElement: {
              ...TalkChannelMessageHelper.toAPI(
                messageElement.delete('isCompleted')
              ),
              name,
            },
            saveMessageTemplate,
            name,
            adGroupId,
            creativeFormat,
            opinionProof,
          },
        ]
      }

      case CreativeFormatEnum.Type.SERVICE_CONTENT: {
        return [
          {
            serviceContent,
            frequencyCap,
            name,
            adGroupId,
            creativeFormat,
            opinionProof,
          },
        ]
      }

      case CreativeFormatEnum.Type.CATALOG_DYNAMIC: {
        return [
          {
            catalogProductSetId,
            profileImage: uploadedImages.get(CREATIVE_CREATE_PROFILE_KEY),
            profileName,
            actionButton,
            rspvLandingUrl,
            pcLandingUrl,
            mobileLandingUrl,
            frequencyCap,
            name,
            adGroupId,
            creativeFormat,
            useDefaultLandingUrl,
            useDefaultProfileImage,
            opinionProof,
            trackingUrl,
            clickTrackerUrl,
            eventTrackerUrl,
            catalogSegment,
            parentCatalogId,
          },
        ]
      }

      case CreativeFormatEnum.Type.RICH_NATIVE: {
        switch (campaignType) {
          case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
            return pcTalkBottomBundles
              .filter(({ backgroundImage }) => !!backgroundImage)
              .reduce((creativeArr, { backgroundImage, objectImage }) => {
                return creativeArr.concat({
                  video: CreativeHelper.Video.toAPI(uploadedVideo),
                  image1: CreativeHelper.Image.toAPI(autoThumbnail),
                  image2: CreativeHelper.Image.toAPI(uploadThumbnail),
                  backgroundImage,
                  compatibleImage,
                  compatibleTitle,
                  rspvLandingUrl,
                  pcLandingUrl,
                  mobileLandingUrl,
                  landingInfo,
                  clickTrackerUrl,
                  eventTrackerUrl,
                  altText,
                  frequencyCap,
                  name: CreativeHelper.Name.toSizePostFix(name, objectImage),
                  adGroupId,
                  creativeFormat,
                  opinionProof,
                  objectImage,
                  profileName,
                  compatiblePcLandingUrl,
                })
              }, [])
          case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
            return [
              {
                altText,
                landingInfo,
                title,
                bannerImage,
                image: richPopImage,
                actionButtonBackgroundColor,
                actionButton,
                actionButtonFontColor,
                compatibleImage,
                compatibleTitle,
                profileName,
                frequencyCap,
                name: CreativeHelper.Name.toSizePostFix(name, richPopImage),
                adGroupId,
                creativeFormat,
                opinionProof,
                clickTrackerUrl,
                eventTrackerUrl,
                compatiblePcLandingUrl,
              },
            ]
          case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
            return [
              {
                profileImage: uploadedImages.get(CREATIVE_CREATE_PROFILE_KEY),
                profileName,
                altText,
                landingInfo,
                title,
                description,
                video: CreativeHelper.Video.toAPI(uploadedVideo),
                image: CreativeHelper.Image.toAPI(
                  uploadThumbnail?.count() > 0
                    ? uploadThumbnail
                    : autoThumbnail || {}
                ), // 맞춤 썸네일이 있을 경우 맞춤 썸네일로, 없을 경우 동영상 업로드시 생성된 프리뷰 이미지 정보로 넣어줘야 함.
                image1: CreativeHelper.Image.toAPI(autoThumbnail), // 동영상 업로드시 생성된 프리뷰 이미지 정보.
                image2: CreativeHelper.Image.toAPI(uploadThumbnail), // 직접 등록한 맞춤 썸네일 이미지.
                frequencyCap,
                adGroupId,
                creativeFormat,
                opinionProof,
                clickTrackerUrl,
                eventTrackerUrl,
                name: CreativeHelper.Name.toSizePostFix(name, autoThumbnail),
              },
            ]
          case CampaignTypeEnum.Type.PROFILE_FULL_VIEW: {
            const titleItems = checkNotEmpty(title)
              ? { title }
              : { description, textImage }
            if (uploadedMultiImages.size === 0) {
              return [
                {
                  profileImage: uploadedImages.get(CREATIVE_CREATE_PROFILE_KEY),
                  profileName,
                  altText,
                  landingInfo,
                  video: CreativeHelper.Video.toAPI(uploadedVideo),
                  image1: CreativeHelper.Image.toAPI(autoThumbnail), // 동영상 업로드시 생성된 프리뷰 이미지 정보.
                  image2: CreativeHelper.Image.toAPI(uploadThumbnail), // 직접 등록한 맞춤 썸네일 이미지.
                  frequencyCap,
                  adGroupId,
                  creativeFormat,
                  opinionProof,
                  clickTrackerUrl,
                  eventTrackerUrl,
                  actionButtonText,
                  actionButtonBackgroundColor,
                  actionButtonFontColor,
                  useDefaultProfileImage,
                  name: CreativeHelper.Name.toSizePostFix(name, autoThumbnail),
                  ...titleItems,
                },
              ]
            }

            return uploadedMultiImages.reduce(
              (creativeArr, promotionalImages) => {
                return creativeArr.concat(
                  promotionalImages.reduce((imageArr, promotionalImage) => {
                    const { imageWidth, imageHeight } = promotionalImage
                    return imageArr.concat({
                      profileImage: uploadedImages.get(
                        CREATIVE_CREATE_PROFILE_KEY
                      ),
                      profileName,
                      altText,
                      landingInfo,
                      image: promotionalImage,
                      frequencyCap,
                      adGroupId,
                      creativeFormat,
                      opinionProof,
                      clickTrackerUrl,
                      eventTrackerUrl,
                      actionButtonText,
                      actionButtonBackgroundColor,
                      actionButtonFontColor,
                      useDefaultProfileImage,
                      name: `${name}_${imageWidth}x${imageHeight}`,
                      ...titleItems,
                    })
                  }, [])
                )
              },
              []
            )
          }

          default:
            return undefined
        }
      }

      default: {
        return undefined
      }
    }
  },

  // validation 이후 데이터 필터링
  toModifyRequestForm({ creative, campaignType }) {
    const {
      creativeFormat,
      expandableType,
      expandableAssetGroups,
      bizBoardNativeServing,
      bizBoardNativeImage,
      expressMetaInfos,
    } = creative

    switch (creativeFormat) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        const originalMessageElement = creative.get('messageElement')

        return creative.withMutations(s =>
          s.set(
            'messageElement',
            TalkChannelMessageHelper.toAPI(originalMessageElement)
          )
        )
      }

      case CreativeFormatEnum.Type.IMAGE_BANNER: {
        if (
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
        ) {
          const isExpandableAssetValid = validateBizBoardExpandableAsset({
            expandableType,
            expandableAssetGroups,
          })

          return creative.withMutations(s =>
            s
              // api request 시 expandable 아닐 경우 데이터 필터링 반드시 필요
              .set(
                'expandableType',
                isExpandableAssetValid ? expandableType : undefined
              )
          )
        } else if (campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD) {
          const isExpandableAssetValid = validateBizBoardExpandableAsset({
            expandableType,
            expandableAssetGroups,
          })

          const { expressMetaInfoAsset } = expressMetaInfos?.first() || {}
          const { title = '', description = '' } = expressMetaInfoAsset || {}

          return creative.withMutations(s =>
            s
              // api request 시 expandable 아닐 경우 데이터 필터링 반드시 필요
              .set(
                'expandableType',
                isExpandableAssetValid ? expandableType : undefined
              )
              // NAS Serving actionButton, title, description 추가
              .set(
                'bizBoardNativeImage',
                bizBoardNativeServing ? bizBoardNativeImage : undefined
              )
              .set(
                'actionButton',
                bizBoardNativeServing ? ActionButtonEnum.Type.LINK : undefined
              )
              .set('title', bizBoardNativeServing ? title : undefined)
              .set(
                'description',
                bizBoardNativeServing ? description : undefined
              )
          )
        }
        break
      }

      default: {
        break
      }
    }

    return creative
  },

  // 소재 불러오기 -> 설정완료
  importToCompleted({ creative, campaign, adGroup, common }) {
    const {
      campaignTypeGoal: { campaignType, goal },
      contractInfo: { contractProductItems } = {},
    } = campaign

    const { subType } = contractProductItems?.first() || {}

    const {
      creativeFormat,
      backgroundColor,
      image,
      image1,
      image2,
      profileImage,
      video,
      expressMetaInfos,
      expandableType,
      expandableAssetGroups,
      boardAssetGroup,
      hasPrice,
      backgroundImage,
      objectImage,
      bizBoardNativeServing,
      bizBoardNativeImage,
      actionButtonText,
    } = creative

    const { hasSkanType } = common

    const boardAssetGroupFilter = (boardAssetGroup, boardType) => {
      const {
        video,
        autoThumbnail,
        uploadThumbnail,
        backgroundImage,
        textImage,
        objectImage1,
        objectImage2,
        objectImage3,
      } = boardAssetGroup || {}
      const filteredBoardAssetGroup = {}
      if (BizBoardSubTypeEnum.isSpecialBizBoard(boardType)) {
        filteredBoardAssetGroup.backgroundImage = backgroundImage
        filteredBoardAssetGroup.textImage = textImage
        filteredBoardAssetGroup.objectImage1 = objectImage1
        if (boardType === BizBoardSubTypeEnum.Type.MOTION) {
          filteredBoardAssetGroup.video = video
          filteredBoardAssetGroup.autoThumbnail = autoThumbnail
          filteredBoardAssetGroup.uploadThumbnail = uploadThumbnail
          filteredBoardAssetGroup.objectImage2 = objectImage2
          filteredBoardAssetGroup.objectImage3 = objectImage3
        }
      }
      return filteredBoardAssetGroup
    }

    creative = creative.withMutations(
      s =>
        s
          .update('name', name =>
            String(name).substr(
              0,
              CreativeConstraints.getNameMaxLength({
                creativeFormat: creative.get('creativeFormat'),
              })
            )
          )
          .set('formUUID', uuid()) // 설정완료 항목 식별자
          .set(
            'frequencyCap',
            fromJS({
              count: null,
              type: 'AUTO',
            })
          ) // 프리퀀시캡: 자동
          .set('isSupportedImageSize', true) // 지원하지 않는 사이즈는 load 에 노출되지 않는다.
          .delete('opinionProof') // 의견 증빙은 삭제한다.
    )

    switch (creativeFormat) {
      case CreativeFormatEnum.Type.IMAGE_BANNER: {
        if (
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT
        ) {
          const isExpandableAssetValid = validateBizBoardExpandableAsset({
            expandableType,
            expandableAssetGroups,
          })

          // 생성하려는 보드의 타입: subType, 불러온소재의 타입: boardType
          const isSpecialBoard = BizBoardSubTypeEnum.isSpecialBizBoard(subType)

          /**
           * 기존 소재 랜딩유형 -> 대표 랜딩에 SET && 소재 개별 요소 1번 > 대표 랜딩과 동일 true
           */
          return creative.withMutations(s =>
            s
              .set('id', -1)
              .set(
                'bizBoardBundles',
                List.of(
                  BizBoardBundleModel.withMutations(s =>
                    s
                      .set(
                        'image',
                        fromJS({
                          ...CreativeHelper.Image.toView(image),
                          backgroundColor,
                          expressMetaInfo: expressMetaInfos?.first(),
                        })
                      )
                      .set('bundleUUID', uuid())
                      .set('useDefaultExpandable', true) // 항상 true 로 처리 KAMOQA-17067
                      .set(
                        'bizBoardNativeImage',
                        bizBoardNativeServing ? bizBoardNativeImage : undefined
                      )
                  )
                )
              )
              .set(
                'expandableType',
                isExpandableAssetValid ? expandableType : undefined
              )
              .set(
                'expandableAssetGroups',
                isExpandableAssetValid
                  ? expandableAssetGroups.map(expandableAssetGroup =>
                      expandableAssetGroup.set('assetGroupUUID', uuid())
                    )
                  : undefined
              )
              .set('hasPrice', isExpandableAssetValid ? hasPrice : false)
              .set('boardType', isSpecialBoard ? subType : null)
              .set(
                'boardAssetGroup',
                fromJS(boardAssetGroupFilter(boardAssetGroup, subType))
              )
              /**
               * https://jira.daumkakao.com/browse/KAMOQA-32149
               * FIXME 관리자정지 처리 모두 끝나면 코드 제거 필요
               */
              // .set('bizBoardNativeServing', bizBoardNativeServing) // 원복 코드
              .update('bizBoardNativeServing', _bizBoardNativeServing =>
                goal === GoalEnum.Type.REACH ? false : _bizBoardNativeServing
              )
              .update('landingInfo', landingInfo =>
                hasSkanType
                  ? null
                  : checkNotEmpty(landingInfo)
                  ? landingInfo
                  : BizBoardLandingInfo
              )
              .set('backgroundColor', isSpecialBoard ? null : backgroundColor)
              .delete('campaign')
              .delete('adGroup')
              .delete('image')
          )
        } else {
          return creative.withMutations(s =>
            s
              .set('id', -1)
              .set(
                'uploadedMultiImages',
                fromJS({
                  [createCreativeImageGroupId(image)]: [
                    {
                      ...CreativeHelper.Image.toView(image),
                      backgroundColor,
                    },
                  ],
                })
              )
              .delete('campaign')
              .delete('adGroup')
              .delete('image')
          )
        }
      }

      case CreativeFormatEnum.Type.IMAGE_NATIVE: {
        return creative.withMutations(s =>
          s
            .set('id', -1)
            .set(
              'uploadedImages',
              fromJS({
                [CREATIVE_CREATE_PROFILE_KEY]:
                  CreativeHelper.Image.toView(profileImage),
              })
            )
            .set(
              'uploadedMultiImages',
              fromJS({
                [createCreativeImageGroupId(image)]: [
                  CreativeHelper.Image.toView(image),
                ],
              })
            )
            .update('landingInfo', landingInfo =>
              hasSkanType
                ? null
                : checkNotEmpty(landingInfo)
                ? landingInfo
                : BizBoardLandingInfo
            )
            .delete('campaign')
            .delete('adGroup')
            .delete('image')
            .delete('profileImage')
        )
      }

      case CreativeFormatEnum.Type.IMAGE_BOX: {
        const { schedule: adGroupSchedule, status: adGroupStatus } = adGroup

        const { beginDate, endDate } = adGroupSchedule || {}

        const isAdGroupFinished =
          moment(endDate).isBefore(moment(), 'd') ||
          adGroupStatus?.includes(AdStatusEnum.Type.CANCELED)

        const isAdGroupOperating = moment().isBetween(
          moment(beginDate),
          moment(endDate),
          null,
          '[]'
        )

        const beginDateTime = isAdGroupFinished
          ? null
          : isAdGroupOperating
          ? replaceCurrentTimeToBaseTime(5)
          : moment(beginDate).startOf('d')

        const endDateTime = isAdGroupFinished
          ? null
          : moment(endDate).endOf('d')

        return creative.withMutations(s =>
          s
            .set('id', -1)
            .set(
              'beginDateTime',
              isUndefinedOrNull(beginDateTime)
                ? null
                : localDateTimeToString(beginDateTime)
            )
            .set(
              'endDateTime',
              isUndefinedOrNull(endDateTime)
                ? null
                : localDateTimeToString(endDateTime)
            )
            .set('noSchedule', isAdGroupFinished)
            .set(
              'uploadedMultiImages',
              fromJS({
                [createCreativeImageGroupId(image)]: [
                  CreativeHelper.Image.toView(image),
                ],
              })
            )
            .delete('creativeScheduleStatus')
            .delete('campaign')
            .delete('adGroup')
            .delete('image')
        )
      }

      case CreativeFormatEnum.Type.CATALOG_MANUAL: {
        return creative.withMutations(s =>
          s
            .set('id', -1)
            .set(
              'uploadedImages',
              fromJS({
                [CREATIVE_CREATE_PROFILE_KEY]:
                  CreativeHelper.Image.toView(profileImage),
              })
            )
            .update('assetGroups', assetGroups =>
              assetGroups.map(assetGroup =>
                assetGroup.set('assetGroupUUID', uuid())
              )
            )
            .delete('campaign')
            .delete('adGroup')
            .delete('profileImage')
        )
      }

      case CreativeFormatEnum.Type.VIDEO_NATIVE: {
        return creative.withMutations(s =>
          s
            .set('id', -1)
            .set('uploadedVideo', fromJS(CreativeHelper.Video.toView(video)))
            .set('autoThumbnail', image1 || Map())
            .set('uploadThumbnail', image2 || Map())
            .set(
              'uploadedImages',
              fromJS({
                [CREATIVE_CREATE_PROFILE_KEY]:
                  CreativeHelper.Image.toView(profileImage),
              })
            )
            .set('modifyStatus', true)
            .delete('campaign')
            .delete('adGroup')
            .delete('image')
            .delete('image1')
            .delete('image2')
            .delete('video')
            .delete('profileImage')
        )
      }

      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        const messageElement =
          TalkChannelMessageHelper.convertMessageFormToMessageElement({
            messageForm: creative.get('messageElement'),
          })
            .set('id', -1)
            .set('isCompleted', true)

        return creative.withMutations(s =>
          s
            .set('id', -1)
            .set('messageElement', messageElement)
            .set('saveMessageTemplate', false)
            .delete('campaign')
            .delete('profileImage')
            .delete('adGroup')
            .delete('image')
            .delete('image1')
            .delete('image2')
            .delete('video')
        )
      }

      case CreativeFormatEnum.Type.SERVICE_CONTENT: {
        return creative.withMutations(s =>
          s.set('id', -1).delete('campaign').delete('adGroup')
        )
      }

      case CreativeFormatEnum.Type.CATALOG_DYNAMIC: {
        return creative.withMutations(s =>
          s
            .set('id', -1)
            .set(
              'uploadedImages',
              fromJS({
                [CREATIVE_CREATE_PROFILE_KEY]:
                  CreativeHelper.Image.toView(profileImage),
              })
            )
            .set('formUUID', uuid()) // 설정완료 항목 식별자
            .delete('campaign')
            .delete('adGroup')
            .delete('profileImage')
        )
      }

      case CreativeFormatEnum.Type.RICH_NATIVE: {
        if (campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM) {
          return creative.withMutations(s =>
            s
              .set('id', -1)
              .set(
                'uploadedImages',
                fromJS({
                  [CREATIVE_CREATE_PROFILE_KEY]:
                    CreativeHelper.Image.toView(profileImage),
                })
              )
              .set('uploadedVideo', fromJS(CreativeHelper.Video.toView(video)))
              .set('autoThumbnail', image1 || Map())
              .set(
                'pcTalkBottomBundles',
                List.of(
                  PcTalkBottomBundleModel.withMutations(s =>
                    s
                      .set(
                        'backgroundImage',
                        fromJS({
                          ...CreativeHelper.Image.toView(backgroundImage),
                        })
                      )
                      .set(
                        'objectImage',
                        fromJS({
                          ...CreativeHelper.Image.toView(objectImage),
                        })
                      )
                      .set('bundleUUID', uuid())
                  )
                )
              )
              .set('uploadThumbnail', image2 || Map())
              .delete('campaign')
              .delete('adGroup')
              .delete('profileImage')
          )
        } else if (campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW) {
          return creative.withMutations(s =>
            s
              .set('id', -1)
              .set(
                'uploadedImages',
                fromJS({
                  [CREATIVE_CREATE_PROFILE_KEY]:
                    CreativeHelper.Image.toView(profileImage),
                })
              )
              .set('uploadThumbnail', image2 || Map())
              .set('uploadedVideo', fromJS(CreativeHelper.Video.toView(video)))
              .set('autoThumbnail', image1 || Map())
              .delete('campaign')
              .delete('adGroup')
              .delete('profileImage')
          )
        } else if (campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) {
          return creative.withMutations(s =>
            s
              .set('id', -1)
              .set(
                'uploadedImages',
                fromJS({
                  [CREATIVE_CREATE_PROFILE_KEY]:
                    CreativeHelper.Image.toView(profileImage),
                })
              )
              .set(
                'uploadedMultiImages',
                image
                  ? fromJS({
                      [createCreativeImageGroupId(image)]: [
                        CreativeHelper.Image.toView(image),
                      ],
                    })
                  : Map()
              )
              .set('uploadThumbnail', image2 || Map())
              .set(
                'uploadedVideo',
                video ? fromJS(CreativeHelper.Video.toView(video)) : Map()
              )
              .set('autoThumbnail', image1 || Map())
              .set('actionButtonText', actionButtonText)
              .delete('campaign')
              .delete('adGroup')
              .delete('profileImage')
              .delete('image')
              .delete('video')
          )
        } else {
          return creative.withMutations(s =>
            s
              .set('id', -1)
              .set('richPopImage', image)
              .delete('campaign')
              .delete('adGroup')
              .delete('profileImage')
          )
        }
      }

      default: {
        return undefined
      }
    }
  },
}
